import { useCallback, useMemo, useState } from 'react';
import { Container } from './styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FormHyperlinkViewProps,
  HyperlinkViewProps,
  UBTFormHyperlinkViewProps,
} from './types';
import { Text } from 'UIComponents';
import { HyperlinkSizes } from './constants';
import { DescriptionAlign, DescriptionSize } from 'aid-form-service';
import { useFormFieldController } from 'Hooks';
import { FieldContainer } from '../FieldContainer';
import { getUrl } from 'UIUtils';

export function Hyperlink({
  label = 'Hyperlink',
  openInNewTab,
  copyToClipboard,
  size = DescriptionSize.BIGGER,
  align = DescriptionAlign.LEFT,
  url,
}: HyperlinkViewProps) {
  const [copySuccess, setCopySuccess] = useState(false);

  const href = useMemo(() => getUrl(url), [url]);

  const handlerCopy = useCallback(() => {
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 1500);
  }, [setCopySuccess]);

  return (
    <Container size={size} align={align}>
      {copyToClipboard ? (
        <CopyToClipboard onCopy={handlerCopy} text={href || ''}>
          <Text
            text={copySuccess ? 'Copied to clipboard' : label}
            size={HyperlinkSizes[size]}
            color="input-border"
          />
        </CopyToClipboard>
      ) : (
        <Text
          text={label}
          target={openInNewTab ? '_blank' : ''}
          href={href}
          size={HyperlinkSizes[size]}
          color="input-border"
          asLink
        />
      )}
    </Container>
  );
}

export function UBTFormHyperlink({
  field,
  isVisible,
}: UBTFormHyperlinkViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <Hyperlink
        label={field.options?.actions?.text || undefined}
        openInNewTab={field.options?.actions?.openInNewTab || undefined}
        copyToClipboard={field.options?.actions?.copyToClipboard || undefined}
        size={field.options?.actions?.size || undefined}
        align={field.options?.actions?.align || undefined}
        url={field.options?.actions?.url || undefined}
      />
    </FieldContainer>
  );
}

export function FormHyperlink({ field }: FormHyperlinkViewProps) {
  const { ref } = useFormFieldController(field);
  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <Hyperlink
        label={field.options?.actions?.text || undefined}
        openInNewTab={field.options?.actions?.openInNewTab || undefined}
        copyToClipboard={field.options?.actions?.copyToClipboard || undefined}
        size={field.options?.actions?.size || undefined}
        align={field.options?.actions?.align || undefined}
        url={field.options?.actions?.url || undefined}
      />
    </FieldContainer>
  );
}

import styled from '@emotion/styled';
import { getColor, getFont } from 'UIUtils';
import { Icon } from '../Icon';
import { EmotionStyledButtonViewProps, ButtonType } from './types';

export const StyledButton = styled.button<EmotionStyledButtonViewProps>`
  font-family: ${getFont('primary')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 41px;
  border-radius: 32px;
  padding: 12px 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  cursor: pointer;
  outline: none;

  border: ${({ buttonType }) =>
    buttonType === ButtonType.OUTLINE
      ? `1px solid ${getColor('input-border')}`
      : 'none'};

  color: ${({ buttonType, buttonColor }) =>
    getColor(
      buttonType === ButtonType.OUTLINE
        ? buttonColor || 'input-border'
        : 'white'
    )};

  background: ${({ buttonType, buttonColor }) =>
    getColor(
      buttonType === ButtonType.OUTLINE
        ? 'white'
        : buttonColor || 'input-focus-background'
    )};

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 4px 4px 4px ${getColor('disabled-input-background')};
  }

  &:active {
    box-shadow: ${({ buttonType }) =>
      `inset 2px 2px 2px rgba(0, 0, 0, ${
        buttonType === ButtonType.OUTLINE ? 0.2 : 0.3
      })`};
  }

  ${({ fullWidth }) => fullWidth && 'flex: 1;'}
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
`;

export const ButtonIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer;
`;

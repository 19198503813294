import styled from '@emotion/styled';
import { getColor } from 'UIUtils';
import { Text } from '../Text';
import { EmotionVerticalContentContainerViewProps } from './types';

export const StyledContainer = styled.div`
  margin-bottom: 20px;
`;

export const StyledContent = styled.div`
  position: relative;
  display: block;

  & input:focus {
    outline: ${getColor('input-outline')};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  padding-left: 15px;

  & > div:first-of-type {
    margin-right: 6px;
  }
`;

export const StyledErrorRow = styled(Text)`
  display: block;
`;

export const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 6px 0;
  padding-left: 14px;

  & > div:first-of-type {
    flex-shrink: 0;
  }
`;

export const StyledTitle = styled(Text)<{
  marginBottom?: string;
  paddingLeft?: string;
}>`
  display: block;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft};`}
`;

export const TitleText = styled(Text)`
  margin-right: 8px;
`;

export const VerticalContentContainer = styled.label<EmotionVerticalContentContainerViewProps>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 22px;
  user-select: none;
  padding: 5px 5px;
  border-radius: 4px;
  margin: 0 -5px 0;

  :hover {
    background: ${({ label, disabled }) =>
      !disabled && label
        ? getColor('disabled-input-background')
        : 'transparent'};
  }
`;

export function FaceIcon() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.3926 76.6668H12.8007C12.5872 76.6668 12.3836 76.5818 12.2318 76.4302C12.0817 76.2784 11.9983 76.0731 12 75.8594C12.1054 63.9404 19.5484 56.3197 34.125 53.2079C34.5573 53.1151 34.9823 53.3915 35.074 53.8238C35.1678 54.2558 34.8905 54.6813 34.4586 54.7738C21.0529 57.6354 14.0464 64.4605 13.6212 75.066L66.5718 75.0659C66.1467 64.4605 59.1401 57.6356 45.7343 54.7738C45.3024 54.6813 45.0272 54.2561 45.1189 53.8238C45.2108 53.3912 45.6359 53.1155 46.0696 53.2079C60.6447 56.3194 68.0878 63.9407 68.1929 75.8594C68.1948 76.0728 68.1112 76.2785 67.9611 76.4302C67.8113 76.5816 67.6059 76.667 67.3926 76.6668Z"
        fill="#DCE3F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3705 13.4083C14.9285 13.4083 14.5698 13.0498 14.5698 12.6077L14.5702 4.13386C14.5702 3.69183 14.9284 3.33364 15.3703 3.33364L23.8615 3.3335C24.3031 3.3335 24.6601 3.69207 24.6601 4.1342C24.6603 4.57633 24.3031 4.93481 23.8611 4.93481L16.171 4.93471V12.6075C16.171 13.0495 15.8124 13.4083 15.3705 13.4083ZM64.8226 13.4143C64.3805 13.4143 64.0219 13.0561 64.0219 12.614L64.0221 4.93471H56.3602C55.9181 4.93471 55.5595 4.57633 55.5595 4.1342C55.5598 3.69183 55.9181 3.33364 56.36 3.33364L64.8226 3.3335C65.2646 3.3335 65.623 3.69207 65.623 4.1342V12.6139C65.623 13.056 65.2644 13.4145 64.8226 13.4143ZM23.8413 54.3872H15.3707C14.9287 54.3872 14.57 54.0288 14.57 53.5868L14.5704 45.1203C14.5704 44.6783 14.9286 44.3199 15.3705 44.3199C15.8128 44.3199 16.1711 44.6784 16.1711 45.1205L16.1712 52.7861H23.8413C24.2834 52.7861 24.6419 53.1447 24.6419 53.5868C24.6422 54.0285 24.2833 54.3872 23.8413 54.3872ZM64.8226 54.3872H56.3853C55.9433 54.3872 55.5845 54.0288 55.5845 53.5868C55.5845 53.1444 55.9431 52.7863 56.385 52.7863L64.0221 52.7861V45.1207C64.0221 44.6787 64.3804 44.3201 64.8225 44.3201C65.2646 44.3199 65.623 44.6787 65.623 45.1208V53.5865C65.623 54.0285 65.2644 54.3872 64.8226 54.3872Z"
        fill="#DCE3F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9185 8.0074C37.2257 8.0074 35.6328 8.2329 34.2768 8.67057C32.6007 9.21085 29.7304 10.5879 29.3285 13.737C29.2838 14.0963 29.0016 14.3803 28.643 14.4291C26.5849 14.7087 25.2124 15.4898 24.4468 16.8166C23.1795 19.0148 23.5496 22.7649 25.5443 27.9733C29.8474 23.636 30.7628 21.7492 30.9162 20.9962C30.9732 20.7136 31.178 20.4841 31.4516 20.3945C31.7252 20.3041 32.027 20.3693 32.2388 20.5634C34.904 22.9891 37.896 22.9582 41.36 22.9234C45.5747 22.8803 50.3094 22.8323 54.6807 27.8059C57.1274 20.5859 54.8325 15.9905 52.3825 13.365C48.9637 9.70055 43.5581 8.0075 38.9185 8.0074ZM25.2491 30.197C25.1943 30.197 25.1406 30.1916 25.0873 30.1806C24.8273 30.1268 24.6086 29.9471 24.5085 29.7006C21.8935 23.3369 21.4198 18.861 23.0592 16.0169C23.995 14.3942 25.6026 13.3603 27.8424 12.9371C28.4196 10.2473 30.506 8.2043 33.7848 7.14672C39.4472 5.3202 48.3881 6.73875 53.5533 12.2726C56.462 15.3909 59.1954 20.9448 55.688 29.6943C55.5813 29.9617 55.3378 30.1513 55.0526 30.1898C54.7659 30.2281 54.4823 30.1101 54.3071 29.8801C50.178 24.4346 45.9014 24.4781 41.3749 24.5245C38.2212 24.5562 34.9706 24.5898 32.062 22.4806C31.1931 24.2406 29.13 26.7088 25.8094 29.9679C25.6577 30.1159 25.4558 30.197 25.2491 30.197Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9756 51.3339C36.2414 51.3339 34.5117 49.4997 32.6821 47.5579C32.1418 46.9836 31.5813 46.3902 30.9543 45.8301C27.7074 42.9364 25.5206 37.4437 24.4549 29.5033C24.3966 29.0651 24.7034 28.6623 25.142 28.6034C25.579 28.5447 25.9826 28.8521 26.0409 29.2902C27.0552 36.8404 29.0663 42.0033 32.02 44.6349C32.6991 45.2408 33.2825 45.8606 33.8479 46.4598C35.5709 48.2883 36.9316 49.7327 39.9754 49.7327C43.2696 49.7327 44.5251 48.3945 46.2647 46.5422C46.8435 45.9249 47.4438 45.2865 48.1743 44.6346C51.1263 42.0024 53.1377 36.8401 54.1517 29.2902C54.2101 28.852 54.6136 28.5447 55.0506 28.6034C55.4894 28.6621 55.7978 29.0652 55.7377 29.5033C54.6721 37.4431 52.4856 42.9361 49.24 45.8296C48.5614 46.4338 47.9875 47.0462 47.4321 47.6382C45.6476 49.5386 43.9614 51.334 39.9756 51.3339Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3942 37.5173C24.0767 37.5177 22.8673 36.912 21.9417 35.7533C20.5609 34.0244 20.2422 31.5488 21.2012 29.9944C21.6634 29.2429 22.8273 28.0653 25.4141 28.6135C25.8479 28.7052 26.1229 29.1302 26.0312 29.5628C25.9395 29.9949 25.5142 30.2721 25.0822 30.1799C24.2283 29.9987 23.0992 29.9657 22.5638 30.8351C21.9617 31.8093 22.2436 33.5673 23.1926 34.7538C23.6816 35.3656 24.7503 36.3075 26.4215 35.7347C26.8386 35.5911 27.2937 35.814 27.4371 36.2321C27.5808 36.6501 27.3587 37.1057 26.9401 37.2492C26.4168 37.4291 25.8977 37.5175 25.3942 37.5173Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.7992 37.5174C54.2971 37.5174 53.7784 37.4292 53.2531 37.2493C52.8344 37.1057 52.6126 36.6504 52.756 36.2322C52.8997 35.8137 53.3548 35.5915 53.7734 35.7347C55.4431 36.3079 56.5119 35.3658 57.0006 34.7539C57.9499 33.5671 58.2315 31.8096 57.631 30.835C57.0943 29.9657 55.9648 29.9991 55.1109 30.18C54.6791 30.2712 54.2537 29.9953 54.1619 29.5629C54.0705 29.1301 54.3471 28.7055 54.7791 28.6135C57.366 28.0651 58.5299 29.2433 58.9936 29.9945C59.9526 31.5486 59.634 34.0249 58.2514 35.7534C57.3258 36.9117 56.1166 37.5176 54.7992 37.5174Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0975 59.4699C36.3802 59.4699 33.3545 56.4458 33.3545 52.7282L33.3545 47.9357C33.3545 47.4937 33.7131 47.1353 34.1551 47.1353C34.597 47.1349 34.9556 47.4937 34.9556 47.9358L34.9558 52.7281C34.9558 55.5628 37.2622 57.8691 40.0975 57.8691C42.9311 57.8687 45.2376 55.5628 45.2376 52.7282L45.2378 47.9384C45.2378 47.4963 45.5962 47.1379 46.0381 47.1379C46.4802 47.1379 46.8387 47.4963 46.8387 47.9385L46.839 52.7281C46.839 56.4458 43.815 59.4702 40.0975 59.4699Z"
        fill="currentColor"
      />
    </svg>
  );
}

import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { FieldState, FieldViewProps } from '../types';
import { fieldStateMap } from '../constants';
import { Init, Intro, Camera, Check, ImageValue } from '../components';
import { useFieldContext } from '../../../context';

export function useFieldController(type: FieldViewProps['type']) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { value } = useFieldContext();
  const [currentState, setCurrentState] = useState<FieldState>(
    value ? 'result' : 'init'
  );
  const [rawImage, setRawImage] = useState<{ file: Blob; url: string }>();

  const ComponentByState = useMemo(() => {
    if (currentState === fieldStateMap.init) {
      return Init;
    }
    if (currentState === fieldStateMap.intro) {
      return Intro;
    }
    if (currentState === fieldStateMap.camera) {
      return Camera;
    }
    if (currentState === fieldStateMap.check) {
      return Check;
    }
    if (currentState === fieldStateMap.result) {
      return ImageValue;
    }
    return Init;
  }, [currentState]);

  const onChangeImage = useCallback(
    (event) => {
      event.preventDefault();
      const file = event?.target?.files[0];
      if (file) {
        try {
          setRawImage({
            file,
            url: (window.URL || window.webkitURL).createObjectURL(file),
          });
          setCurrentState('check');
        } catch (e) {}
      }
    },
    [setRawImage, setCurrentState]
  );

  const contextData = useMemo(
    () => ({
      isFace: type === 'face',
      isDocument: type === 'document',
      setCurrentState,
      setRawImage,
      rawImage,
      inputRef,
    }),
    [type, rawImage]
  );

  return { contextData, inputRef, onChangeImage, ComponentByState };
}

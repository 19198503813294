import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { getPrefixTag } from './utils';
import { useMenuController } from './MenuController';
import { useFormContext } from 'Context';
import { SelectOption } from '../../Select/types';
import { FieldControllerDelegate } from 'aid-form-service/lib/State/StateControllers/FieldControllers/FieldController/index.delegate';
import { isGroup, isRepeatableItem } from 'aid-form-service';
import slugify from 'slugify';

function parseValue(value: string, translate?: boolean | null) {
  if (translate) {
    return slugify(value, {
      replacement: ' ',
      locale: 'en',
    });
  }
  return value;
}

export function useInputController(
  field?: FieldControllerDelegate,
  value?: string,
  formOnChange?: (value?: string) => void
) {
  const tagPrefix = useMemo(
    () => getPrefixTag(field?.field?.protected_tags || []),
    [field?.field?.protected_tags]
  );
  const {
    opened,
    onFocusInput,
    loadByString,
    data,
    loading,
    getPlace,
    refMenu,
    refInput,
    setFocused,
    onBlurInput,
  } = useMenuController(value);
  const [isLoading, setIsLoading] = useState(false);
  const { stateDelegate } = useFormContext();

  const changeValues = useRef(
    (
      fields: FieldControllerDelegate[],
      value: [any, any | undefined],
      templates: [string[], string[] | undefined]
    ) => {
      if (field) {
        const [kindValue, codeValue] = value;
        const [kindTemplates, codeTemplates] = templates;
        let changed = false;
        if (isRepeatableItem(field.parentDelegate)) {
          const repeatableItem = field.parentDelegate;
          fields.forEach((protectedField) => {
            if (isRepeatableItem(protectedField.parentDelegate)) {
              const protectedRepeatableItem = protectedField.parentDelegate;
              if (
                protectedField.isVisible &&
                protectedRepeatableItem.parentDelegate.isVisible &&
                protectedRepeatableItem.parentDelegate.parentDelegate.isVisible
              ) {
                if (
                  repeatableItem.index === protectedRepeatableItem.index &&
                  repeatableItem.parentDelegate.id ===
                    protectedRepeatableItem.parentDelegate.id
                ) {
                  if (
                    kindTemplates.includes(protectedField.field_template.kind)
                  ) {
                    protectedField.value = kindValue;
                    changed = true;
                  } else if (
                    codeTemplates &&
                    codeTemplates.includes(protectedField.field_template.code)
                  ) {
                    protectedField.value = codeValue;
                    changed = true;
                  }
                }
              }
            }
          });
        } else {
          fields.forEach((protectedField) => {
            if (
              isGroup(protectedField.parentDelegate) &&
              isGroup(field.parentDelegate)
            ) {
              const protectedGroup = protectedField.parentDelegate;
              const fieldGroup = field.parentDelegate;
              if (
                protectedField.isVisible &&
                protectedGroup.isVisible &&
                protectedGroup.parentDelegate.isVisible
              ) {
                if (protectedGroup.id === fieldGroup.id) {
                  if (
                    kindTemplates.includes(protectedField.field_template.kind)
                  ) {
                    protectedField.value = kindValue;
                    changed = true;
                  } else if (
                    codeTemplates &&
                    codeTemplates.includes(protectedField.field_template.code)
                  ) {
                    protectedField.value = codeValue;
                    changed = true;
                  }
                }
              }
            }
          });
        }
        return changed;
      }
    }
  );

  const setValueToFields = useRef(
    (
      tag: string,
      value: [any, string | undefined],
      templates: [string[], string[] | undefined]
    ) => {
      const fields = stateDelegate?.protectedTags?.[tag];
      if (fields) {
        return changeValues.current(fields, value, templates);
      }
      return false;
    }
  );

  const parseString = useRef((...args: string[]) =>
    args.reduce(
      (acc, name) => `${acc}${name ? `${acc ? ', ' : ''}${name}` : ''}`,
      ''
    )
  );

  const onSelectInput = useRef(async (option: SelectOption) => {
    setFocused(false);
    const place = await getPlace.current(option.value);
    if (place?.address_components) {
      setIsLoading(true);
      let addressText = '';
      let stateText = '';
      let stateCode = '';
      let countryText = '';
      let countryCode = '';
      let cityText = '';
      let zipText = '';
      let numberText = '';
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            numberText = parseString.current(
              numberText,
              parseValue(component.long_name, field?.options?.translit)
            );
            break;
          }
          case 'postal_code': {
            zipText = `${parseValue(
              component.long_name,
              field?.options?.translit
            )}${zipText}`;
            break;
          }
          case 'postal_code_suffix': {
            zipText = `${zipText}-${parseValue(
              component.long_name,
              field?.options?.translit
            )}`;
            break;
          }
          case 'postal_code_prefix': {
            zipText = `${parseValue(
              component.long_name,
              field?.options?.translit
            )}-${zipText}`;
            break;
          }
          case 'administrative_area_level_1':
          case 'administrative_area_level_2':
          case 'administrative_area_level_3':
          case 'administrative_area_level_4':
          case 'administrative_area_level_5':
          case 'sublocality_level_2':
          case 'sublocality_level_1':
          case 'sublocality_level_3':
          case 'sublocality_level_4':
          case 'sublocality_level_5': {
            if (componentType === 'administrative_area_level_1') {
              stateCode = parseString.current(
                stateCode,
                parseValue(component.short_name, field?.options?.translit)
              );
            }
            stateText = parseString.current(
              stateText,
              parseValue(component.long_name, field?.options?.translit)
            );
            break;
          }
          case 'country':
            countryText = parseString.current(
              countryText,
              parseValue(component.long_name, field?.options?.translit)
            );
            countryCode = component.short_name;
            break;
          case 'postal_town':
          case 'locality': {
            cityText = parseString.current(
              cityText,
              parseValue(component.long_name, field?.options?.translit)
            );
            break;
          }
          case 'route':
            addressText = parseString.current(
              addressText,
              parseValue(component.long_name, field?.options?.translit)
            );
        }
      }
      if (tagPrefix) {
        const updatedFieldNumber = setValueToFields.current(
          `${tagPrefix}HOME_NUMBER`,
          [numberText, undefined],
          [['string'], undefined]
        );
        if (!updatedFieldNumber) {
          addressText = parseString.current(addressText, numberText);
        }
        const updatedFieldCity = setValueToFields.current(
          `${tagPrefix}CITY`,
          [cityText, undefined],
          [['string'], undefined]
        );
        if (!updatedFieldCity) {
          addressText = parseString.current(addressText, cityText);
        }
        const updatedFieldState = setValueToFields.current(
          `${tagPrefix}STATE`,
          [stateText, undefined],
          [['string'], undefined]
        );
        if (!updatedFieldState) {
          addressText = parseString.current(addressText, stateText);
        }
        setValueToFields.current(
          `${tagPrefix}STATE_CODE`,
          [stateCode, undefined],
          [['string'], undefined]
        );
        const updatedFieldCountry = setValueToFields.current(
          `${tagPrefix}COUNTRY`,
          [countryText, countryCode],
          [
            ['string'],
            [
              'select_region',
              'country_code',
              'country_code_for_doc',
              'list_of_countries',
            ],
          ]
        );
        if (!updatedFieldCountry) {
          addressText = parseString.current(addressText, countryText);
        }
        const updatedFieldZip = setValueToFields.current(
          `${tagPrefix}ZIP`,
          [zipText, undefined],
          [['string'], undefined]
        );
        if (!updatedFieldZip) {
          addressText = parseString.current(addressText, zipText);
        }
        formOnChange?.(addressText);
      } else {
        addressText = parseString.current(
          addressText,
          numberText,
          cityText,
          stateText,
          countryText,
          zipText
        );
        formOnChange?.(addressText);
      }
      setIsLoading(false);
    }
  });

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      formOnChange?.(e.target.value);
      loadByString.current(e.target.value);
    },
    [loadByString, formOnChange]
  );

  return {
    opened,
    refMenu,
    refInput,
    onChangeInput,
    onFocusInput,
    onBlurInput,
    data,
    loading,
    onSelectInput,
    isLoading,
  };
}

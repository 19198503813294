import styled from '@emotion/styled';
import { Icon } from '../Icon';
import {
  getBorderColorByParams,
  getColorByParams,
  getBoxShadowColorByParams,
} from '../TextInput/utils';
import { getColor, getFont } from 'UIUtils';
import { EmotionStyledTextAreaViewProps } from './types';

export const StyledTextArea = styled.textarea<EmotionStyledTextAreaViewProps>`
  position: relative;
  display: block;
  width: inherit;
  border: 1px solid ${({ error }) => getBorderColorByParams(error)};
  border-radius: 4px;
  color: ${({ error }) => getColorByParams(error)};
  outline: none;
  font-family: ${getFont('primary')};
  font-style: normal;
  font-weight: normal;
  line-height: 1.17;
  font-size: 14px;
  padding: 6px 40px 6px 12px;
  box-shadow: 0px 0px 5px 1px ${({ error }) => getBoxShadowColorByParams(error)};
  resize: vertical;
  min-height: 31px;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
  &:disabled {
    background-color: ${getColor('disabled-input-background')};
    opacity: 0.6;
    cursor: not-allowed;
  }

  ${({ error }) =>
    !error &&
    `
    &:focus {
        border-color: ${getColor('input-focus-border')};
        box-shadow: 0px 0px 5px 1px ${getColor('input-outline')};
        color: ${getColor('primary-text-color')};
    }
  `}
`;

export const StyledTextAreaIcon = styled(Icon)`
  position: absolute;
  width: 13px;
  height: 13px;
  top: 10px;
  right: 10px;
`;

export const StyledTextAreaWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  position: relative;
`;

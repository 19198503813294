import { CustomUI } from 'aid-form-service/lib/ApiClient/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';

export enum ThemeType {
  STANDARD = 'standard',
  BFM = 'bfm',
  ARIVAL = 'arival',
  AID = 'aid',
  SYSTEM = 'system',
  ONBOARDING = 'onboarding',
  EZ = 'ez',
}

export interface UIThemeProviderViewProps {
  type: ThemeType;
  customUI?: Maybe<CustomUI>;
}

export interface UIThemeProviderRenderViewProps extends UIThemeProviderViewProps {
 apiHost: string;
}

import { THEME_COLORS } from 'UIShared';

export enum ButtonType {
  FILLED = 'filled',
  OUTLINE = 'outline',
}

export interface EmotionStyledButtonViewProps {
  buttonType?: ButtonType;
  buttonColor?: keyof THEME_COLORS;
  fullWidth?: boolean;
}

export type ButtonViewProps = EmotionStyledButtonViewProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    disabled?: boolean;
  };

import { useCallback } from 'react';
import {
  CustomHeaderContainer,
  CustomHeaderText,
  CustomListWrapper,
  CustomValue,
  ChangeButtonGroup,
  ChangeButton,
} from '../shared.styles';
import { YearsViewProps } from './types';
import { useYearsController } from './controller';
import { preventDefault } from 'UIUtils';

export function Years({
  changeYear,
  setViewMode,
  minDate,
  maxDate,
  date,
}: YearsViewProps) {
  const {
    yearsByStep,
    prevButtonIsDisabled,
    nextButtonIsDisabled,
    handlerNextStep,
    handlerPrevStep,
    handleChangeYear,
    label,
  } = useYearsController(date, changeYear, setViewMode, minDate, maxDate);

  const renderYear = useCallback(
    (year: { year: number; isDisabled: boolean; isSelected: boolean }) => (
      <CustomValue
        key={year.year}
        isSelected={year.isSelected}
        disabled={year.isDisabled}
        onClick={handleChangeYear(year.year)}
      >
        {year.year}
      </CustomValue>
    ),
    [handleChangeYear]
  );

  return (
    <CustomHeaderContainer>
      <ChangeButtonGroup>
        <ChangeButton
          disabled={prevButtonIsDisabled}
          onClick={preventDefault(handlerPrevStep)}
        >
          {'<<'}
        </ChangeButton>
      </ChangeButtonGroup>
      <CustomHeaderText>{label}</CustomHeaderText>
      <ChangeButtonGroup>
        <ChangeButton
          disabled={nextButtonIsDisabled}
          onClick={preventDefault(handlerNextStep)}
        >
          {'>>'}
        </ChangeButton>
      </ChangeButtonGroup>
      <CustomListWrapper>{yearsByStep.map(renderYear)}</CustomListWrapper>
    </CustomHeaderContainer>
  );
}

import styled from '@emotion/styled';

export const ImagesContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, minmax(auto, 97px));
  gap: 12px;
`;

export const Image = styled.div<{ imageUrl?: string }>`
  height: 86px;
  width: 100%;
  border-radius: 4px;
  background: url('${({ imageUrl }) => imageUrl}') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

import styled from '@emotion/styled/macro';
import { Button } from 'UIComponents';
import { getColor } from 'UIUtils';
import {
  EmotionButtonContainerViewProps,
  EmotionButtonWrapperViewProps,
  EmotionStyledButtonViewProps,
  EmotionWrapperViewProps,
} from './types';

export const Wrapper = styled.div<EmotionWrapperViewProps>`
  & > * {
    margin-bottom: 5px;
  }

  ${({ row }) => `
  flex-direction: ${row ? 'row' : 'column'};
    display: flex;
    align-items:  ${row ? 'center' : 'left'};
    flex-wrap: wrap;

    & > label {
      margin: 0;
    }
  `}
`;

export const ButtonsWrapper = styled.div<EmotionButtonWrapperViewProps>`
  display: grid;
  grid-template-columns: repeat(${({ length }) => length || 0}, 1fr);
  grid-template-row: 1fr;
`;

export const ButtonsContainer = styled.div<EmotionButtonContainerViewProps>`
  border-radius: 32px;
  border: 1px solid
    ${({ error }) =>
      getColor(error ? 'error-text-color' : 'input-focus-border')};
  overflow: hidden;

  & > ${ButtonsWrapper}:first-of-type {
    & > button:first-of-type {
      border-top-left-radius: ${({ length }) =>
        (length || 0) > 1 ? 20 : 10}px;
    }
  }

  & > ${ButtonsWrapper}:last-of-type {
    & > button {
      border-bottom: none;
    }
  }
`;

export const StyledButton = styled(Button)<EmotionStyledButtonViewProps>`
  height: 100%;
  width: 100%;
  padding: 4px 24px;
  font-size: 12px;
  ine-break: anywhere;
  background: ${({ isActive }) =>
    isActive ? getColor('input-focus-background') : getColor('white')};
  border-radius: 0;
  ${({ isActive, error }) =>
    isActive
      ? `
        border-right: none;
        border-bottom: none;
    `
      : `
    border-right: 1px solid ${getColor(
      error ? 'error-text-color' : 'input-focus-border'
    )};
    border-bottom: 1px solid ${getColor(
      error ? 'error-text-color' : 'input-focus-border'
    )};
  `}
  border-top: none;
  border-left: none;

  color: ${({ isActive }) =>
    isActive ? getColor('white') : getColor('primary-text-color')};

  &:last-of-type {
    border-right: none;
  }
`;

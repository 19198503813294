import { useFormContext } from 'Context';
import { PropsWithChildren, useMemo } from 'react';
import { Text } from 'UIComponents';
import { Icon } from '../Icon';
import { Required } from './components';
import {
  NoteWrapper,
  StyledContainer,
  StyledContent,
  StyledErrorRow,
  StyledErrorWrapper,
  StyledTitle,
  TitleText,
  VerticalContentContainer,
} from './styles';
import { InputDecoratorViewProps } from './types';

export function InputDecorator({
  error,
  options,
  style,
  children,
  label,
  success,
  requiredInHeader,
}: PropsWithChildren<InputDecoratorViewProps>) {
  const { data, language } = useFormContext();

  const errorMessage = useMemo(() => {
    if (
      options?.validations?.required &&
      data?.options.marking_type === 'optional' &&
      error?.message === language?.getTranslate('errors.required')
    ) {
      return language?.getTranslate('ui.support.required');
    } else {
      return error?.message;
    }
  }, [
    error,
    language,
    data?.options.marking_type,
    options?.validations?.required,
  ]);

  return (
    <StyledContainer>
      {label && (
        <StyledTitle
          size={12}
          weight="Medium"
          marginBottom="7px"
          paddingLeft="14px"
        >
          <TitleText text={label.trim()} size={12} weight="Medium" />
          {requiredInHeader ? (
            <Required
              error={error}
              type="header"
              success={success}
              isRequired={options?.validations?.required}
              markingType={data?.options.marking_type}
            />
          ) : null}
        </StyledTitle>
      )}
      <StyledContent style={style}>
        {!requiredInHeader ? (
          <Required
            error={error}
            success={success}
            isRequired={options?.validations?.required}
            markingType={data?.options.marking_type}
          />
        ) : null}
        {children}
      </StyledContent>
      {options?.note && (
        <NoteWrapper>
          <Icon glyph="info" color="note-text-color" size={12} />
          <Text text={options.note} size={12} />
        </NoteWrapper>
      )}
      {options?.warning && (
        <NoteWrapper>
          <Icon glyph="triangleWarning" color="warning-text-color" size={12} />
          <Text text={options.warning} size={12} color="warning-text-color" />
        </NoteWrapper>
      )}
      {errorMessage && (
        <StyledErrorWrapper>
          <Icon size={12} glyph="alert" color="error-text-color" />
          <StyledErrorRow
            text={errorMessage}
            size={12}
            color="error-text-color"
          />
        </StyledErrorWrapper>
      )}
    </StyledContainer>
  );
}

export function VerticalInputDecorator({
  error,
  options,
  children,
  label,
  success,
  paddingLeft,
}: PropsWithChildren<InputDecoratorViewProps & { paddingLeft?: string }>) {
  const { data } = useFormContext();
  const { language } = useFormContext();
  const errorMessage = useMemo(() => {
    if (
      options?.validations?.required &&
      data?.options.marking_type === 'optional' &&
      error?.message === language?.getTranslate('errors.required')
    ) {
      return language?.getTranslate('ui.support.required');
    } else if (
      data?.options.marking_type !== 'optional' &&
      options?.validations?.required &&
      error?.message === language?.getTranslate('errors.required')
    ) {
      return undefined;
    } else {
      return error?.message;
    }
  }, [
    error,
    language,
    data?.options.marking_type,
    options?.validations?.required,
  ]);
  return (
    <StyledContainer>
      <VerticalContentContainer label={label}>
        {children}
        <StyledTitle size={12} weight="Medium" paddingLeft={paddingLeft}>
          <TitleText text={label?.trim()} size={12} weight="Medium" />
          <Required
            error={error}
            type="header"
            success={success}
            isRequired={options?.validations?.required}
            markingType={data?.options.marking_type}
          />
        </StyledTitle>
      </VerticalContentContainer>
      {options?.note && (
        <NoteWrapper>
          <Icon glyph="info" color="note-text-color" size={12} />
          <Text text={options.note} size={12} />
        </NoteWrapper>
      )}
      {options?.warning && (
        <NoteWrapper>
          <Icon glyph="triangleWarning" color="warning-text-color" size={12} />
          <Text text={options.warning} size={12} color="warning-text-color" />
        </NoteWrapper>
      )}
      {errorMessage && (
        <StyledErrorWrapper>
          <Icon size={12} glyph="alert" color="error-text-color" />
          <StyledErrorRow
            text={errorMessage}
            size={12}
            color="error-text-color"
          />
        </StyledErrorWrapper>
      )}
    </StyledContainer>
  );
}

import styled from '@emotion/styled';
import { Button } from 'UIComponents';
import { getColor } from 'UIUtils';

export const PrimaryButton = styled(Button)`
  margin-top: 20px;
  border: none;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  border-radius: 32px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  border: 1px solid ${getColor('secondary-button-border-color')};
  background: ${getColor('secondary-button-background-color')};
  color: ${getColor('input-focus-border')};
`;

export const SidebarButton = styled(Button)`
  width: 150px;
  box-shadow: 0px 10px 10px ${getColor('input-outline-secondary')};
  border-radius: 30px;
  padding: 13px 30px;
  font-weight: 500;
  border: none;
  color: ${getColor('submit-button-text-color')};
  font-weight: 400;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 30px;
  background: ${getColor('white')};

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.6;
    box-shadow: 0px 10px 10px ${getColor('input-outline-secondary')};
  }

  &:disabled {
    box-shadow: none;
    cursor: default;
  }

  &:active {
    box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
`;

import { DescriptionAlign, DescriptionSize } from 'aid-form-service';
import { useFormFieldController } from 'Hooks';
import { useMemo } from 'react';
import { FieldContainer } from '../FieldContainer';
import { NoteContainer } from './styled';
import {
  FormNoteViewProps,
  NoteViewProps,
  UBTFormNoteViewProps,
} from './types';

export function Note({
  size = DescriptionSize.SMALL,
  align = DescriptionAlign.LEFT,
  label,
}: NoteViewProps) {
  const textWithBr = useMemo(() => label?.replace(/\\n/g, '\n'), [label]);

  return (
    <NoteContainer align={align} size={size}>
      {textWithBr}
    </NoteContainer>
  );
}

export function UBTFormNote({ field, isVisible }: UBTFormNoteViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={!!field.options?.half_width}>
      <Note
        size={field.options?.description?.size || undefined}
        align={field.options?.description?.align || undefined}
        label={field.options?.description?.text}
      />
    </FieldContainer>
  );
}

export function FormNote({ field }: FormNoteViewProps) {
  const { ref } = useFormFieldController(field);
  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <Note
        size={field.options?.description?.size || undefined}
        align={field.options?.description?.align || undefined}
        label={field.options?.description?.text}
      />
    </FieldContainer>
  );
}

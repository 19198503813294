import { getColor } from 'UIUtils'

function getColorByState(hasError: boolean) {
  return hasError && getColor('error-text-color')
}

export function getColorByParams(hasError: boolean) {
  return getColorByState(hasError) || getColor('primary-text-color')
}
export function getBorderColorByParams(hasError: boolean) {
  return getColorByState(hasError) || getColor('input-border')
}
export function getBoxShadowColorByParams(hasError: boolean) {
  return getColorByState(hasError) || 'transparent'
}

import styled from '@emotion/styled/macro';
import { Button } from 'UIComponents';
import { parseAlign } from '../CollapseInfo/utils';
import { RedirectButtonStyles } from './constants';
import { RedirectButtonViewProps } from './types';

export const Container = styled.div<
  Required<Pick<RedirectButtonViewProps, 'align' | 'size'>>
>`
  text-align: ${({ align }) => parseAlign(align)};
  margin-bottom: 20px;

  a {
    display: inline-flex;
    text-decoration: none;

    ${({ size }) => {
      const { fontSize, height } =
        RedirectButtonStyles[size] || RedirectButtonStyles.Middle;

      return `
        font-size: ${fontSize}px;
        height: ${height}px;
      `;
    }}
  }
`;

export const StyledButton = styled(Button)``;

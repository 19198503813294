import styled from '@emotion/styled';
import { Text } from '../../../Text';
import { Button } from '../../../Button';
import { getColor, getFont } from 'UIUtils';
import { StyledIcon } from '../../styles';

export const PlaceholderText = styled(Text)`
  font-family: ${getFont('primary')};
  white-space: pre-wrap;
  text-align: center;
  line-height: 20px;
  letter-spacing: 0.01em;
`;

export const PlaceholderLabel = styled(PlaceholderText)`
  background: ${getColor('media-primary-text-color')};
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  background: ${getColor('input-focus-background')};
  color: white;
  min-height: 40px;
  margin-top: 20px;
`;

export const PlaceholderDescription = styled(PlaceholderText)`
  background: ${getColor('media-secondary-text-color')};
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const UploadIcon = styled(StyledIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  opacity: 0.18;
`;

export const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;

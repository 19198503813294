import { ErrorTemplate } from 'UIComponents';
import { ThemeType, UIThemeProvider } from 'UIShared';
import { StateCompletedVerificationViewProps } from './types';
import { PropsWithChildren } from 'react';

export function StateCompletedVerification({
  title,
  description,
  children,
}: PropsWithChildren<StateCompletedVerificationViewProps>) {
  return (
    <UIThemeProvider type={ThemeType.SYSTEM}>
      <ErrorTemplate
        title={title}
        description={description}
        customIcon={
          <svg
            width="81"
            height="80"
            viewBox="0 0 81 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1122_1335)">
              <path
                d="M49.5837 42.5201C49.5458 42.3168 49.497 42.0574 49.4732 41.8945C49.7961 41.1035 51.4556 39.0313 53.0883 37.3968L58.2745 32.21C59.201 31.2835 59.201 29.7814 58.2745 28.8549L51.646 22.2259C50.7195 21.2994 49.2168 21.2994 48.2903 22.2259L43.1042 27.412C41.4703 29.0441 39.3993 30.7031 38.6071 31.0272C38.4405 31.0034 38.1829 30.9545 37.9815 30.9167C35.5889 30.4656 30.6109 29.5275 24.9438 35.1946L1.88837 58.2501C1.83283 58.3062 1.77973 58.3642 1.72968 58.4252C1.56855 58.6224 -2.15277 63.3306 4.19428 69.6777L10.8233 76.3061C17.1691 82.6525 21.8774 78.9318 22.0745 78.7701C22.1356 78.72 22.1942 78.6675 22.2497 78.612L45.3057 55.5554C50.9722 49.8895 50.0341 44.912 49.5837 42.5201Z"
                fill="#1F3B8E"
              />
              <path
                d="M51.646 22.2259C50.7195 21.2994 49.2175 21.2994 48.2909 22.2259L43.1048 27.412C41.4703 29.0441 39.3993 30.7031 38.6071 31.0272C38.4405 31.0034 38.1829 30.9545 37.9815 30.9167C35.5889 30.4656 30.6109 29.5275 24.9444 35.194L1.88837 58.2501C1.83283 58.3056 1.77973 58.3642 1.72968 58.4252C1.56855 58.6224 -2.15277 63.3306 4.19428 69.6777L7.63361 73.117L55.0854 25.6652L51.646 22.2259Z"
                fill="#15265A"
              />
              <path
                d="M49.8003 47.4413C50.1177 45.357 49.7966 43.6498 49.5836 42.5201C49.5458 42.3168 49.4969 42.0574 49.4731 41.8945C49.796 41.1035 51.4556 39.0313 53.0883 37.3968L58.2744 32.21C59.2009 31.2835 59.2009 29.7814 58.2744 28.8549L51.646 22.2259C50.7195 21.2994 49.2168 21.2994 48.2903 22.2259L43.1041 27.412C41.4702 29.0441 39.3993 30.7031 38.6071 31.0272C38.4404 31.0034 38.1829 30.9545 37.9814 30.9167C36.8511 30.7037 35.1433 30.3826 33.059 30.7L32.1953 48.305L49.8003 47.4413Z"
                fill="#1F3B8E"
              />
              <path
                d="M51.646 22.2259C50.7195 21.2994 49.2174 21.2994 48.2909 22.2259L43.1047 27.412C41.4702 29.0441 39.3993 30.7031 38.6071 31.0272C38.4404 31.0034 38.1829 30.9545 37.9814 30.9167C36.8511 30.7037 35.1433 30.3826 33.059 30.7L32.1953 48.3044L32.459 48.2916L55.0853 25.6652L51.646 22.2259Z"
                fill="#DCE3F5"
              />
              <path
                d="M30.4832 56.7816L23.7187 50.0177C22.7922 49.0906 21.2901 49.0906 20.3636 50.0177L11.6636 58.7177C10.7371 59.6442 10.7371 61.1463 11.6636 62.0728L18.4276 68.8373C19.3547 69.7638 20.8568 69.7638 21.7833 68.8373L30.4832 60.1374C31.4097 59.2108 31.4097 57.7082 30.4832 56.7816Z"
                fill="#DCE3F5"
              />
              <path
                d="M23.7187 50.0177C22.7922 49.0906 21.2901 49.0906 20.3636 50.0177L11.6636 58.7177C10.7371 59.6442 10.7371 61.1463 11.6636 62.0728L15.1713 65.5805L27.2264 53.5248L23.7187 50.0177Z"
                fill="#F6F8FF"
              />
              <path
                d="M61.8199 29.5782C61.71 29.4684 61.6093 29.3463 61.5196 29.212C60.7945 28.1201 61.0917 26.6479 62.183 25.9228C62.3777 25.7928 67.0317 22.7868 73.1901 26.2128C74.3358 26.8493 74.7471 28.2947 74.1099 29.4397C73.4733 30.5847 72.028 30.9967 70.883 30.3595C67.2807 28.3557 64.8326 29.859 64.8088 29.8749C63.8524 30.5102 62.6017 30.3607 61.8199 29.5782Z"
                fill="#DCE3F5"
              />
              <path
                d="M51.1728 18.9308C51.2827 19.0412 51.4047 19.1413 51.539 19.231C52.6309 19.9561 54.1031 19.6595 54.8288 18.5676C54.9582 18.3729 57.9642 13.719 54.5383 7.56052C53.9017 6.41489 52.4564 6.00351 51.3114 6.64072C50.1663 7.27792 49.7544 8.72263 50.3916 9.86765C52.396 13.4699 50.8921 15.9181 50.8762 15.9419C50.2408 16.8983 50.3903 18.1489 51.1728 18.9308Z"
                fill="#F6F8FF"
              />
              <path
                d="M56.4966 24.2541C55.5701 23.3276 55.5701 21.8255 56.4966 20.899L62.0832 15.3124C63.0097 14.3859 64.5118 14.3859 65.4383 15.3124C66.3648 16.239 66.3648 17.7416 65.4383 18.6682L59.8517 24.2541C58.9252 25.1806 57.4232 25.1806 56.4966 24.2541Z"
                fill="#DCE3F5"
              />
              <path
                d="M62.0832 15.3124L56.4966 20.899C55.5701 21.8255 55.5701 23.3276 56.4966 24.2541L65.4383 15.3124C64.5118 14.3859 63.0091 14.3859 62.0832 15.3124Z"
                fill="#F6F8FF"
              />
              <path
                d="M79.8056 6.49722L74.003 0.695221C73.0765 -0.231903 71.5744 -0.231903 70.6479 0.695221L64.5181 6.82498C63.5916 7.7515 63.5916 9.25357 64.5181 10.1801L70.3201 15.9827C71.2466 16.9092 72.7493 16.9092 73.6758 15.9827L79.8056 9.85294C80.7321 8.92642 80.7321 7.42374 79.8056 6.49722Z"
                fill="#1F3B8E"
              />
              <path
                d="M74.003 0.695221C73.0765 -0.231903 71.5744 -0.231903 70.6479 0.695221L64.5181 6.82498C63.5916 7.7515 63.5916 9.25357 64.5181 10.1801L67.5449 13.2068L77.0297 3.72134L74.003 0.695221Z"
                fill="#DCE3F5"
              />
            </g>
            <defs>
              <clipPath id="clip0_1122_1335">
                <rect
                  width="80"
                  height="80"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        }
      >
        {children}
      </ErrorTemplate>
    </UIThemeProvider>
  );
}

import { useLanguageContext } from 'Context';
import { useDropFieldProps } from 'Hooks';
import { useCallback, useMemo } from 'react';
import { useMediaController } from '../../../controller/MediaController';
import { MAX_UPLOAD_SIZE } from '../constants';
import { VideoFieldViewProps } from '../types';

function checkMimeType(type: string) {
  if (/video\/.*/gm.test(type)) {
    return true;
  }
  return false;
}

export function useVideoController({
  options,
  onChange,
  value,
  typeUploads,
}: Pick<
  VideoFieldViewProps,
  'options' | 'onChange' | 'value' | 'typeUploads'
>) {
  const {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    actionRef,
    anotherUpload,
    handlerUseWebcam,
    showMobileButtons,
    liveOnly,
    inputFile,
    mobileOnly,
    onChangeWebcam,
    useWebCam,
    webCamRef,
    setUseWebCam,
  } = useMediaController({
    options,
    onChange,
    typeUploads,
  });
  const language = useLanguageContext();

  const stopRecord = async () => {
    webCamRef.current?.stopRecord();
  };

  const onDropAccepted = useCallback((files: File[]) => {
    uploadVideo(files[0]);
  }, []);

  const { onSetRejected, ...dropFieldProps } = useDropFieldProps(
    liveOnly || mobileOnly || false,
    {
      accept: 'video/*',
      maxSize: MAX_UPLOAD_SIZE,
      onDropAccepted,
    }
  );

  const handlerChange = useCallback((e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      if (file?.size > MAX_UPLOAD_SIZE) {
        if (inputFile.current) {
          inputFile.current.value = '';
        }
        return onSetRejected('file-too-large');
      }
      if (!checkMimeType(file.type)) {
        if (inputFile.current) {
          inputFile.current.value = '';
        }
        return onSetRejected('file-invalid-type');
      }
      uploadVideo(file);
    }
  }, []);

  const onComplete = useCallback((blob) => {
    uploadVideo(blob);
  }, []);

  const uploadVideo = async (videoBlob: File) => {
    if (videoBlob.size > MAX_UPLOAD_SIZE) {
      onSetRejected('file-too-large');
      return setUseWebCam(false);
    }
    try {
      await onChange?.(videoBlob);
      setUseWebCam(false);
    } catch (e) {}
  };

  const inputProps = liveOnly ? {} : dropFieldProps.getInputProps();

  const textOptions = useMemo(
    () => ({
      anotherLabel: language?.getTranslate('ui.media.another_device.label', {
        arg: 'video',
      }),
      label: language?.getTranslate('ui.media.attach.label', { arg: 'video' }),
      description: language?.getTranslate('ui.media.attach.description', {
        type: 'video',
        int: 20,
      }),
      actionLabel: language?.getTranslate('ui.media.browse_files'),
      liveOnlyLabel: 'Use your webcam to record video',
      anotherLiveOnlyComment: language?.getTranslate(
        'ui.media.only_mobile.label',
        { arg: 'video' }
      ),
      errorPermission: language?.getTranslate('ui.media.errors.access'),
      errorCameraUsed: language?.getTranslate('ui.media.errors.already_use'),
      errorDeviceLength: language?.getTranslate('ui.media.errors.empty'),
      errorResolution: language?.getTranslate('ui.media.errors.resolution'),
      errorAnotherComment: language?.getTranslate(
        'ui.media.another_device.or_label',
        { arg: 'video' }
      ),
      errorPermissionsComment: language?.getTranslate(
        'ui.media.errors.granted_access',
        { arg: 'video' }
      ),
      errorPermissionAction: language?.getTranslate('ui.media.liveOnly.video'),
      errorMediaRecorderLabel: language?.getTranslate(
        'ui.media.errors.browser'
      ),
      errorMediaRecorderComment: language?.getTranslate(
        'ui.media.errors.video_recorder'
      ),
    }),
    [language]
  );

  return {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    actionRef,
    anotherUpload,
    inputProps,
    handlerChange,
    handlerUseWebcam,
    showMobileButtons,
    liveOnly,
    dropFieldProps,
    inputFile,
    mobileOnly,
    onChangeWebcam,
    useWebCam,
    webCamRef,
    onComplete,
    stopRecord,
    textOptions,
  };
}

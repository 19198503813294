import { useMemo, useCallback } from 'react';
import { useVerificationFieldContext } from '../../../context';
import { FaceIcon, DocumentIcon } from '../components';

export function useInitController() {
  const { setCurrentState, isFace, isDocument } = useVerificationFieldContext();

  const changeView = useCallback((e) => {
    e.preventDefault();
    setCurrentState('intro');
  }, [setCurrentState]);

  const Icon = useMemo(() => {
    if (isFace) {
      return FaceIcon;
    }
    if (isDocument) {
      return DocumentIcon;
    }
    return FaceIcon;
  }, [isFace, isDocument]);

  const title = useMemo(() => {
    if (isFace) {
      return 'Verify your face identity';
    }
    if (isDocument) {
      return 'Verify your identity documents';
    }
  }, [isFace, isDocument]);

  const description = useMemo(() => {
    if (isFace) {
      return 'To verify your face identity you will be asked to take a selfie.';
    }
    if (isDocument) {
      return 'To verify your identity documents you will be asked to take photos of the front and back of the document.';
    }
  }, [isFace, isDocument]);

  return { changeView, title, description, Icon };
}

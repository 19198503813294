import { PropsWithChildren } from 'react';
import { SPRITES } from './constants';
import { SvgContainer } from './styles';

function Sprite() {
  return (
    <SvgContainer id="svg_container">{Object.values(SPRITES)}</SvgContainer>
  );
}

export function UISpriteProvider({ children }: PropsWithChildren<{}>) {
  return (
    <>
      {children}
      <Sprite />
    </>
  );
}

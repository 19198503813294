import styled from '@emotion/styled/macro';
import { Text } from 'UIComponents';
import { getColor } from 'UIUtils';
import { EmotionStyledInputViewProps, EmotionToggleViewProps } from './types';

export const Label = styled(Text)`
  padding-left: 38px;
  white-space: pre-line;
  line-break: auto;
  padding-top: 2px;
`;

export const Toggler = styled.span<EmotionToggleViewProps>`
  position: absolute;
  height: 16px;
  width: 28px;
  background: ${({ disabled, error }) =>
    disabled
      ? getColor('disabled-input-background')
      : error
      ? getColor('error-text-color')
      : getColor('input-outline')};
  box-sizing: border-box;
  box-shadow: inset 0px 0px 2px rgba(83, 90, 98, 0.25);
  border-radius: 8px;
  top: -8px;

  &:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    left: 2px;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${getColor('white')};
    box-shadow: 0.5px 0.5px 1px rgba(83, 90, 98, 0.25);
  }
`;

export const ContainerToggler = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<EmotionStyledInputViewProps>`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${ContainerToggler} {
    ${Toggler} {
      background: ${({ error }) =>
        getColor(error ? 'error-text-color' : 'input-focus-background')};
      &:after {
        left: auto;
        right: 2px;
      }
    }
  }
`;

import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { RadioViewProps } from './types';
import {
  Checkmark,
  ContainerCheckmark,
  Description,
  Label,
  LabelWraper,
  RadioWrapper,
  StyledInput,
} from './styles';

export function Radio({
  label,
  onChange,
  checked,
  disabled,
  error,
  value,
  options,
}: RadioViewProps) {
  const description = useMemo(() => {
    const descriptionByValues = options?.description?.byValues;
    if (descriptionByValues && descriptionByValues.length) {
      const findedDescription = descriptionByValues.find(
        (byValue) => byValue.value === value
      );
      if (findedDescription) {
        return findedDescription.description;
      }
    }
    return;
  }, [options, value]);
  const handlerChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(value);
    },
    [onChange, value]
  );

  return (
    <RadioWrapper label={label} disabled={disabled}>
      <LabelWraper>
        <StyledInput
          onChange={handlerChange}
          type="radio"
          error={error}
          disabled={disabled}
          checked={checked}
        />
        <ContainerCheckmark>
          <Checkmark disabled={disabled} error={error} />
        </ContainerCheckmark>
        <Label color={error ? 'error-text-color' : 'primary-text-color'}>
          {label}
        </Label>
      </LabelWraper>
      {description && (
        <Description size={12} color="note-text-color">
          {description}
        </Description>
      )}
    </RadioWrapper>
  );
}

import { useFormFieldController } from 'Hooks';
import { ChangeEvent, PropsWithChildren, useCallback } from 'react';
import { InputDecorator } from 'UIComponents';
import { FieldContainer } from '../FieldContainer';
import {
  StyledTextInput,
  StyledTextInputIcon,
  StyledTextInputIconWrapper,
  StyledTextInputWrapper,
} from './styles';
import {
  FormTextInputViewProps,
  TextInputViewProps,
  TextInputWithDecoratorViewProps,
  UBTFormTextInputViewProps,
} from './types';

export function TextInput({
  placeholder,
  disabled,
  value,
  error,
  loading,
  mask = '',
  type,
  onChange,
  options,
  children,
  onKeyPress,
}: PropsWithChildren<TextInputViewProps>) {
  const handlerChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.currentTarget.value);
    },
    [onChange]
  ) as any;

  const onClickCross = useCallback(() => {
    onChange?.('');
  }, [onChange]);

  return (
    <StyledTextInputWrapper>
      <StyledTextInput
        placeholder={placeholder}
        disabled={disabled}
        maxLength={options?.hasLimit && options?.maxLength}
        value={value || ''}
        onChange={handlerChange}
        error={error}
        loading={loading}
        mask={mask}
        type={type}
        onKeyDown={onKeyPress}
      />
      <StyledTextInputIconWrapper>
        {loading && <StyledTextInputIcon glyph="spinner" size={16} />}
        {error && (
          <StyledTextInputIcon
            glyph="alert"
            color="error-text-color"
            size={16}
          />
        )}
        {value && !disabled ? (
          <StyledTextInputIcon
            color={error ? 'error-text-color' : 'input-border'}
            onClick={onClickCross}
            glyph="cross"
            size={16}
            action
          />
        ) : null}
        {children}
      </StyledTextInputIconWrapper>
    </StyledTextInputWrapper>
  );
}

export function TextInputWithDecorator({
  label,
  error,
  ...props
}: TextInputWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <TextInput error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormTextInput({
  value,
  isVisible,
  field,
  error,
}: UBTFormTextInputViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={!!field.options?.half_width}>
      <TextInputWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || undefined}
      />
    </FieldContainer>
  );
}

export function FormTextInput({ field }: FormTextInputViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);
  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <TextInputWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder}
      />
    </FieldContainer>
  );
}

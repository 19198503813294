import styled from '@emotion/styled/macro';
import { getColor, getFont } from 'UIUtils';
import {
  getBorderColorByParams,
  getBoxShadowColorByParams,
  getColorByParams,
} from '../TextInput/utils';
import { IFieldStyleProps } from './types';

export const SuccessIcon = styled.div`
  position: absolute;
  right: 9px;
  top: 9px;
`;

export const PhoneInputContainer = styled.div<IFieldStyleProps>`
  position: relative;
  & > ${SuccessIcon} {
    display: none;
  }
  & > .react-tel-input {
    & > .form-control {
      width: inherit;
      border: 1px solid ${({ error }) => getBorderColorByParams(error)};
      padding: 6px 12px 6px 62px;
      font-family: ${getFont('primary')};
      line-height: 1.17;
      @media only screen and (max-width: 1024px) {
        font-size: 16px;
      }
      color: ${({ error }) => getColorByParams(!!error)}!important;
    }

    & .form-control {
      box-shadow: 0px 0px 5px 1px
        ${({ error }) => getBoxShadowColorByParams(error)};
    }

    & .flag-dropdown {
      box-shadow: 0px 0px 5px -3px ${({ error }) => getBoxShadowColorByParams(error)};
    }

    ${({ error }) =>
      !error &&
      `
      & .form-control:focus,
      .form-control:focus + .flag-dropdown {
        border-color: ${getColor('input-border')};
        box-shadow: 0px 0px 4px 1px ${getColor('input-outline')};
        color: ${getColor('note-text-color')};
      }
    `}

    & .form-control:focus + .flag-dropdown {
      border-color: ${(props) => {
        const color = getColor('input-border');
        const defaultColor = getBorderColorByParams(false);
        return `${color} ${defaultColor} ${color} ${color}`;
      }};
    }

    & > .flag-dropdown {
      width: 50px;
      background-color: white;
      border-color: ${({ error }) => {
        const color = getBorderColorByParams(error);
        const defaultColor = getBorderColorByParams(false);
        return `${color} ${defaultColor} ${color} ${color}`;
      }};

      & .selected-flag {
        padding-left: 9px;
        width: 48px;
      }

      & .flag {
        transform: scale(1.125);
      }

      & .arrow {
        left: 24px;
        border-color: #d1d1d1 transparent #d1d1d1 transparent;
      }
    }
  }
`;

export const ConfirmContainer = styled.div`
  max-width: 100%;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: minmax(80px, 126px) 1fr;
  height: 35px;

  & > div:first-of-type {
    & > input {
      max-width: 100px;
      padding: 6px 12px;
      letter-spacing: 5px;
      text-align: center;
    }

    & div {
      display: none;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  width: 100%;

  & > button {
    padding: 0 14px;
    margin-left: 9px;
    height: 35px;
  }

  & > button:first-of-type {
    width: 116px;
  }

  & > button:last-child {
    width: 126px;
  }
`;

export const ConfirmedContainer = styled.div`
  max-width: 100%;
  display: grid;
  gap: 12px;
`;

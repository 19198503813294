import { useFormContext } from 'Context';
import { useDropFieldProps } from 'Hooks';
import { useCallback, useMemo } from 'react';
import { MAX_UPLOAD_SIZE } from '../../../../Video/constants';
import { VideoFieldViewProps } from '../../../../Video/types';
import { useCaptureFieldController } from '../../../controller';

export function useVideoCaptureFieldController({
  options,
  onChange,
  value,
  typeUploads,
}: Omit<VideoFieldViewProps, 'error' | 'loading'>) {
  const {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    anotherUpload,
    liveOnly,
    inputFile,
    handlerCapture,
    captureRef,
  } = useCaptureFieldController({
    options,
    onChange,
    typeUploads,
  });
  const { language } = useFormContext();

  const onDropAccepted = (files: File[]) => {
    handlerUpload(files[0]);
  };

  let { onSetRejected, ...dropFieldProps } = useDropFieldProps(!!liveOnly, {
    accept: 'video/*',
    maxSize: MAX_UPLOAD_SIZE,
    onDropAccepted,
  });

  const handlerUpload = useCallback(
    async (file) => {
      try {
        onChange?.(file);
      } catch (e) {
        onSetRejected('low-internet-connection');
      }
    },
    [onChange, onSetRejected]
  );

  const handlerChange = useCallback(
    (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      if (file?.size > MAX_UPLOAD_SIZE) {
        if (inputFile.current) {
          inputFile.current.value = '';
        }
        return onSetRejected('file-too-large');
      }

      handlerUpload(file);
    },
    [handlerUpload, inputFile, onSetRejected]
  );

  let inputProps = useMemo(
    () => (liveOnly ? {} : dropFieldProps.getInputProps()),
    [liveOnly, dropFieldProps]
  );

  const textOptions = useMemo(
    () => ({
      anotherLabel: language?.getTranslate('ui.media.another_device.label', {
        arg: 'video',
      }),
      label: language?.getTranslate('ui.media.attach.label', { arg: 'video' }),
      description: language?.getTranslate('ui.media.attach.description', {
        type: 'video',
        int: 20,
      }),
      actionLabel: language?.getTranslate('ui.media.browse_files'),
      liveOnlyLabel: 'Use your webcam to record video',
      anotherLiveOnlyComment: language?.getTranslate(
        'ui.media.only_mobile.label',
        { arg: 'video' }
      ),
      errorPermission: language?.getTranslate('ui.media.errors.access'),
      errorCameraUsed: language?.getTranslate('ui.media.errors.already_use'),
      errorDeviceLength: language?.getTranslate('ui.media.errors.empty'),
      errorResolution: language?.getTranslate('ui.media.errors.resolution'),
      errorAnotherComment: language?.getTranslate(
        'ui.media.another_device.or_label',
        { arg: 'video' }
      ),
      errorPermissionsComment: language?.getTranslate(
        'ui.media.errors.granted_access',
        { arg: 'video' }
      ),
      errorPermissionAction: language?.getTranslate('ui.media.liveOnly.video'),
      errorMediaRecorderLabel: language?.getTranslate(
        'ui.media.errors.browser'
      ),
      errorMediaRecorderComment: language?.getTranslate(
        'ui.media.errors.video_recorder'
      ),
    }),
    [language]
  );

  return {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    anotherUpload,
    inputProps,
    handlerChange,
    handlerCapture,
    captureRef,
    liveOnly,
    dropFieldProps,
    inputFile,
    handlerUpload,
    textOptions,
  };
}

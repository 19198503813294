import { PropsWithChildren } from 'react';
import { Icon } from '../Icon';
import {
  Wrapper,
  ChildrenWrapper,
  TitleIconWrapper,
  LoaderWrapper,
  Title,
} from './styles';
import { FormWrapperViewProps } from './types';

export function FormWrapper({
  title,
  icon,
  children,
  isSubmitting,
}: PropsWithChildren<FormWrapperViewProps>) {
  return (
    <Wrapper>
      <TitleIconWrapper>
        {title && (
          <Title
            color="input-border"
            text={title}
            uppercase={true}
            size={32}
            weight={'Medium'}
          />
        )}
        {icon && <Icon glyph={icon} color="input-border" size={40} />}
      </TitleIconWrapper>
      <ChildrenWrapper marginTop={!!title || !!icon}>
        {children}
      </ChildrenWrapper>
      {isSubmitting && (
        <LoaderWrapper>
          {/* <Icon glyph="spinner" size={40} /> */}
        </LoaderWrapper>
      )}
    </Wrapper>
  );
}

import { useFormFieldController } from 'Hooks';
import { ChangeEvent } from 'react';
import { InputDecorator } from 'UIComponents';
import { FieldContainer } from '../FieldContainer';
import { StyledTextArea, StyledTextAreaWrapper } from './styles';
import {
  FormTextAreaViewProps,
  TextAreaViewProps,
  TextAreaWithDecoratorViewProps,
  UBTFormTextAreaViewProps,
} from './types';

export function TextArea({
  placeholder,
  disabled,
  value,
  options,
  error,
  onChange,
}: TextAreaViewProps) {
  const handlerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e?.target?.value);
  };

  return (
    <StyledTextAreaWrapper>
      <StyledTextArea
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        maxLength={options?.hasLimit && options?.maxLength}
        error={error}
        onChange={handlerChange}
      />
    </StyledTextAreaWrapper>
  );
}

export function TextAreaWithDecorator({
  label,
  error,
  ...props
}: TextAreaWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <TextArea error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormTextArea({
  value,
  isVisible,
  field,
  error,
}: UBTFormTextAreaViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <TextAreaWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || undefined}
      />
    </FieldContainer>
  );
}

export function FormTextArea({ field }: FormTextAreaViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);
  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <TextAreaWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || undefined}
      />
    </FieldContainer>
  );
}

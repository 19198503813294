import { Drop } from '../Drop';
import { WebCamBody } from '../WebCamBody';
import { InputDecorator } from '../../../InputDecorator';
import {
  DropFieldAction,
  DropFieldActions,
  LoaderContainer,
} from '../../styles';
import { useImageController } from './controller';
import { Icon } from '../../../Icon';
import {
  TYPE_FILE,
  TYPE_FILE_UPLOAD_CLAIM,
} from '../../../AnotherUpload/controller/constants';
import { useMemo } from 'react';
import { IsMediaRecorderExist } from '../../constants';
import { isAndroid } from 'react-device-detect';
import { ImageCaptureField } from '../CaptureField';
import {
  FormImageFieldViewProps,
  ImageFieldViewProps,
  ImageFieldWithDecoratorViewProps,
  RenderImageValueViewProps,
  UBTFormImageFieldViewProps,
} from './types';
// import { useFormContext } from 'Context';
import { CameraType } from '../../../Camera/types';
import { useFormMediaFieldController } from 'Hooks';
import { FieldContainer } from '../../../FieldContainer';
import { MediaValue } from 'UIComponents';

// function RenderInProgress({ value, handleRemove }: RenderImageValueViewProps) {
//   const { language } = useFormContext();

//   if (value?.safe_download_url) {
//     return (
//       <MediaValue type="Image" value={value} handleRemove={handleRemove} />
//     );
//   }

//   return (
//     <Text
//       text={language?.getTranslate('ui.media.scan.progress', { arg: 'image' })}
//       color="warning-text-color"
//       align="center"
//       size={22}
//     />
//   );
// }

// function RenderInfected() {
//   const { language } = useFormContext();
//   return (
//     <Text
//       text={language?.getTranslate('ui.media.scan.infected', { arg: 'image' })}
//       color="error-text-color"
//       align="center"
//       size={22}
//     />
//   );
// }

function RenderValue({ value, handleRemove }: RenderImageValueViewProps) {
  // if (value?.av_status === 'in_progress') {
  //   return <RenderInProgress value={value} handleRemove={handleRemove} />;
  // } else if (value?.av_status === 'infected') {
  //   return <RenderInfected />;
  // }

  return <MediaValue type="Image" value={value} handleRemove={handleRemove} />;
}

export function RenderImageValue({
  value,
  handleRemove,
}: RenderImageValueViewProps) {
  if (value) {
    return <RenderValue value={value} handleRemove={handleRemove} />;
  }
  return null;
}

export function ImageField({
  onChange,
  value,
  error,
  options,
  needRefresh,
  typeUploads,
  loading,
  watermark,
}: ImageFieldViewProps) {
  const {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    actionRef,
    anotherUpload,
    inputProps,
    handlerChange,
    handlerUseWebcam,
    showMobileButtons,
    liveOnly,
    dropFieldProps,
    inputFile,
    mobileOnly,
    onChangeWebcam,
    useWebCam,
    webCamRef,
    handlerUpload,
    textOptions,
  } = useImageController({
    options,
    onChange,
    value,
    needRefresh,
    typeUploads,
  });

  if (value) {
    return <RenderImageValue value={value} handleRemove={handlerRemove} />;
  }

  return (
    <Drop
      {...dropFieldProps}
      liveOnly={!!liveOnly}
      rejected={dropFieldProps.rejected}
      errorType="image"
      error={error}
    >
      {loading && (
        <LoaderContainer>
          <Icon glyph="spinner" color="white" size={50} />
        </LoaderContainer>
      )}
      <>
        <input
          {...inputProps}
          accept="image/*"
          type="file"
          ref={inputFile}
          onChange={handlerChange}
          style={{ display: 'none' }}
          multiple={false}
        />
        <WebCamBody
          mobileOnly={!!mobileOnly}
          anotherUpload={anotherUpload}
          onChange={onChangeWebcam}
          typeUploads={typeUploads}
          useWebCam={useWebCam}
          webCamRef={webCamRef}
          value={value}
          liveOnly={!!liveOnly}
          actionRef={actionRef}
          cameraType={CameraType.IMAGE}
          handlerButtonClick={handlerButtonClick}
          anotherType={TYPE_FILE.IMG}
          anotherCommentType="image"
          RenderValueComponent={RenderImageValue}
          textOptions={textOptions}
          mobileOnlyType={TYPE_FILE.IMG_MOBILE}
          actionButton={
            <DropFieldAction onClick={handlerUpload}>
              <Icon glyph="checkmark" />
            </DropFieldAction>
          }
          watermark={watermark}
        />
      </>
      <DropFieldActions>
        <div>
          {!value &&
            !useWebCam &&
            !anotherUpload &&
            showMobileButtons &&
            IsMediaRecorderExist && (
              <DropFieldAction onClick={handlerUseWebcam}>
                <Icon glyph="webCamera" />
              </DropFieldAction>
            )}
        </div>
        <div ref={actionRef}>
          {value && (
            <DropFieldAction onClick={handlerRemove}>
              <Icon glyph="delete" />
            </DropFieldAction>
          )}
          {useWebCam && (
            <DropFieldAction onClick={handlerUseWebcam}>
              <Icon glyph="cross" />
            </DropFieldAction>
          )}
          {anotherUpload && (
            <DropFieldAction onClick={toggleAnotherUpload()}>
              <Icon glyph="cross" />
            </DropFieldAction>
          )}
          {!useWebCam &&
            !anotherUpload &&
            !value &&
            showMobileButtons &&
            isAnotherEmail &&
            IsMediaRecorderExist && (
              <DropFieldAction
                onClick={toggleAnotherUpload(TYPE_FILE_UPLOAD_CLAIM.EMAIL)}
              >
                <Icon glyph="mail" />
              </DropFieldAction>
            )}
          {!useWebCam &&
            !anotherUpload &&
            !value &&
            showMobileButtons &&
            isAnotherQR &&
            IsMediaRecorderExist && (
              <DropFieldAction
                onClick={toggleAnotherUpload(TYPE_FILE_UPLOAD_CLAIM.QR)}
              >
                <Icon glyph="devices" />
              </DropFieldAction>
            )}
        </div>
      </DropFieldActions>
    </Drop>
  );
}

function ImageWithCheckCapture(props: ImageFieldViewProps) {
  const isCapture = useMemo(() => {
    const input = document.createElement('input');
    return input.capture !== undefined;
  }, []);

  if (isCapture && !isAndroid) {
    return <ImageCaptureField {...props} />;
  }

  return <ImageField {...props} />;
}

export function ImageFieldWithDecorator({
  label,
  error,
  ...props
}: ImageFieldWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <ImageWithCheckCapture {...props} error={!!error} />
    </InputDecorator>
  );
}

export function UBTFormImageField({
  field,
  value,
  error,
  isVisible,
}: UBTFormImageFieldViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <ImageFieldWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        loading={!!value?.loading}
        typeUploads={
          typeof (field.options as any)?.anotherUpload === 'undefined'
            ? [TYPE_FILE_UPLOAD_CLAIM.QR]
            : (field.options as any)?.anotherUpload
        }
      />
    </FieldContainer>
  );
}

export function FormImageField({ field }: FormImageFieldViewProps) {
  const { ref, value, onChange, error, label, loading, watermark } =
    useFormMediaFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <ImageFieldWithDecorator
        label={label}
        value={value}
        loading={loading}
        onChange={onChange}
        error={error}
        options={field.options}
        watermark={watermark}
        typeUploads={
          typeof (field.options as any)?.anotherUpload === 'undefined'
            ? [TYPE_FILE_UPLOAD_CLAIM.QR]
            : (field.options as any)?.anotherUpload
        }
      />
    </FieldContainer>
  );
}

import { useCallback, useMemo, useRef, useState } from 'react';
import { TYPE_FILE_UPLOAD_CLAIM } from '../../../../AnotherUpload/controller/constants';
import { UseCaptureFieldControllerProps } from './types';

export function useCaptureFieldController({
  options,
  onChange,
  typeUploads,
}: UseCaptureFieldControllerProps) {
  const inputFile = useRef<HTMLInputElement>(null);
  const captureRef = useRef<HTMLInputElement>(null);
  const [anotherUpload, setAnotherUpload] = useState<string>();
  let liveOnly = useMemo(() => options?.liveOnly, [options]);

  const handlerCapture = useCallback((e) => {
    e.preventDefault();
    captureRef.current?.click();
    setAnotherUpload(undefined);
  }, []);

  const handlerButtonClick = useCallback((e) => {
    e.preventDefault();
    inputFile.current?.click();
  }, []);

  const handlerRemove = useCallback(() => {
    onChange?.();
    if (inputFile.current) {
      inputFile.current.value = '';
    }
  }, [onChange]);

  const toggleAnotherUpload = useCallback(
    (type?: string) => () => {
      setAnotherUpload(type);
    },
    []
  );

  const isAnotherEmail = useMemo(
    () =>
      typeUploads?.find(
        (type: string) => type === TYPE_FILE_UPLOAD_CLAIM.EMAIL
      ),
    [typeUploads]
  );

  const isAnotherQR = useMemo(
    () =>
      typeUploads?.find((type: string) => type === TYPE_FILE_UPLOAD_CLAIM.QR),
    [typeUploads]
  );

  return {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    anotherUpload,
    liveOnly,
    inputFile,
    handlerCapture,
    captureRef,
  };
}

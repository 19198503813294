import { useApiHostContext, useClientContext } from 'Context';
import { FileValue } from 'aid-form-service/lib/State/StateControllers/FieldControllers/MediaFieldController/index.types';
import { useCallback, useMemo, useState } from 'react';

export function useRenderValueController(value?: FileValue) {
  const [mediaError, setMediaError] = useState(false);
  const { formClientId } = useClientContext();
  const { apiHost } = useApiHostContext();

  const onError = useCallback(() => setMediaError(true), []);

  const href = useMemo(
    () =>
      value?.id &&
      formClientId &&
      `${apiHost}/documents/download/${value.id}/download?client_id=${formClientId}`,
    [formClientId, value?.id, apiHost]
  );

  const src = useMemo(
    () =>
      value?.id &&
      formClientId &&
      `${apiHost}/documents/download/${value.id}/download?client_id=${formClientId}`,
    [formClientId, value?.id, apiHost]
  );

  return { mediaError, onError, href, src };
}

import styled from '@emotion/styled';
import { getColor, getFont } from 'UIUtils';
import { EmotionTextProps } from './types';

function getDefaultTextProps({
  family,
  weight,
  size,
  lineHeight,
  uppercase,
  color,
  align,
}: EmotionTextProps) {
  return `
    font-family: ${getFont(family || 'primary')};
    font-style: normal;
    font-weight: ${weight};
    font-size: ${size}px;
    line-height: ${lineHeight || '1.17'};
    color: ${getColor(color || 'primary-text-color')};
    ${align ? `text-align: ${align};` : ''}
    ${uppercase ? 'text-transform: uppercase;' : ''}
  `;
}

export const StyledText = styled.span<EmotionTextProps>`
  ${(props) => getDefaultTextProps(props)}
`;

export const StyledLink = styled.a<EmotionTextProps>`
  ${(props) => getDefaultTextProps(props)}
  cursor: pointer;
  text-decoration: unset;
`;

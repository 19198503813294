import { css } from '@emotion/react';
import { CustomUI } from 'aid-form-service/lib/ApiClient/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { ThemeType } from '../theme/types';
import { generateColors } from './utils';

export const COLOR_PREFIX = '--color-';

export function getAppColors(type: ThemeType, customUI: Maybe<CustomUI>) {
  if (type === ThemeType.ONBOARDING || type === ThemeType.EZ) {
    const theme = generateColors(ThemeType.STANDARD, customUI);
    return css(
      `${Object.entries(theme).reduce(
        (acc, [key, value]) =>
          `${acc}${COLOR_PREFIX}${key}: ${value || 'none'};`,
        ''
      )}
      *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      *::-webkit-scrollbar-track {
        background: transparent;
      }

      *::-webkit-scrollbar-thumb {
        background-color: var(${COLOR_PREFIX}scrollbar-background);
        border-radius: 20px;
        border: 1px solid var(${COLOR_PREFIX}scrollbar-background);
      }
    `
    );
  }
  const theme = generateColors(type, customUI);
  return css(
    `${Object.entries(theme).reduce(
      (acc, [key, value]) => `${acc}${COLOR_PREFIX}${key}: ${value || 'none'};`,
      ''
    )}
      *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      *::-webkit-scrollbar-track {
        background: transparent;
      }

      *::-webkit-scrollbar-thumb {
        background-color: var(${COLOR_PREFIX}scrollbar-background);
        border-radius: 20px;
        border: 1px solid var(${COLOR_PREFIX}scrollbar-background);
      }
    `
  );
}

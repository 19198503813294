import { ErrorTemplate } from 'UIComponents';
import { ThemeType, UIThemeProvider } from 'UIShared';
import { ErrorWasNotCompletedViewProps } from './types';
import { PropsWithChildren } from 'react';

export function ErrorWasNotCompleted({
  title,
  description,
  children,
}: PropsWithChildren<ErrorWasNotCompletedViewProps>) {
  return (
    <UIThemeProvider type={ThemeType.SYSTEM}>
      <ErrorTemplate
        color="error-text-color"
        title={title}
        description={description}
        customIcon={
          <svg
            width="65"
            height="80"
            viewBox="0 0 65 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M46.2931 5.51721H58.7069C61.754 5.51721 64.2242 7.98737 64.2242 11.0345V74.4827C64.2242 77.5298 61.754 80 58.7069 80H6.29312C3.24603 80 0.775879 77.5298 0.775879 74.4827V11.0345C0.775879 7.98737 3.24603 5.51721 6.29312 5.51721H46.2931Z"
              fill="#1F3B8E"
            />
            <path
              d="M45.9206 11.0344H57.3274C58.0892 11.0344 58.7068 11.652 58.7068 12.4137V73.1034C58.7068 73.8652 58.0892 74.4827 57.3274 74.4827H7.67228C6.91051 74.4827 6.29297 73.8652 6.29297 73.1034V12.4137C6.29297 11.652 6.91051 11.0344 7.67228 11.0344H45.9206Z"
              fill="#F6F8FF"
            />
            <path
              d="M45.976 45.5172H19.0422C17.9217 45.5178 16.8899 44.9076 16.3506 43.9254C15.8112 42.9432 15.8501 41.7451 16.4519 40.7999L30.2119 19.1917C30.7121 18.4068 31.5784 17.9316 32.5091 17.9316C33.4398 17.9316 34.3061 18.4068 34.8064 19.1917L48.5664 40.7999C49.1682 41.7451 49.207 42.9432 48.6677 43.9254C48.1283 44.9076 47.0965 45.5178 45.976 45.5172Z"
              fill="#B80929"
            />
            <path
              d="M32.4999 41.3807C33.2617 41.3807 33.8792 40.7632 33.8792 40.0014C33.8792 39.2396 33.2617 38.6221 32.4999 38.6221C31.7381 38.6221 31.1206 39.2396 31.1206 40.0014C31.1206 40.7632 31.7381 41.3807 32.4999 41.3807Z"
              fill="#F6F8FF"
            />
            <path
              d="M32.4999 35.862C31.7381 35.862 31.1206 35.2445 31.1206 34.4827V26.2068C31.1206 25.4451 31.7381 24.8275 32.4999 24.8275C33.2617 24.8275 33.8792 25.4451 33.8792 26.2068V34.4827C33.8792 35.2445 33.2617 35.862 32.4999 35.862Z"
              fill="#F6F8FF"
            />
            <path
              d="M38.0174 55.1723H13.1899C12.4281 55.1723 11.8105 54.5548 11.8105 53.793C11.8105 53.0312 12.4281 52.4137 13.1899 52.4137H38.0174C38.7792 52.4137 39.3968 53.0312 39.3968 53.793C39.3968 54.5548 38.7792 55.1723 38.0174 55.1723Z"
              fill="#5A79CB"
            />
            <path
              d="M47.6723 56.5517C47.3066 56.5516 46.9558 56.4063 46.6972 56.1476L45.3179 54.7683C44.7951 54.2271 44.8026 53.3668 45.3347 52.8347C45.8667 52.3027 46.727 52.2952 47.2682 52.8179L47.6723 53.2221L50.8351 50.0593C51.3763 49.5366 52.2366 49.5441 52.7686 50.0761C53.3007 50.6082 53.3082 51.4684 52.7854 52.0097L48.6475 56.1476C48.3889 56.4063 48.0381 56.5516 47.6723 56.5517Z"
              fill="#084335"
            />
            <path
              d="M38.0174 66.2069H13.1899C12.4281 66.2069 11.8105 65.5893 11.8105 64.8276C11.8105 64.0658 12.4281 63.4482 13.1899 63.4482H38.0174C38.7792 63.4482 39.3968 64.0658 39.3968 64.8276C39.3968 65.5893 38.7792 66.2069 38.0174 66.2069Z"
              fill="#5A79CB"
            />
            <path
              d="M47.6723 67.5861C47.3066 67.5861 46.9558 67.4407 46.6972 67.182L45.3179 65.8027C44.7951 65.2615 44.8026 64.4012 45.3347 63.8692C45.8667 63.3371 46.727 63.3296 47.2682 63.8524L47.6723 64.2565L50.8351 61.0937C51.3763 60.571 52.2366 60.5785 52.7686 61.1105C53.3007 61.6426 53.3082 62.5029 52.7854 63.0441L48.6475 67.182C48.3889 67.4407 48.0381 67.5861 47.6723 67.5861Z"
              fill="#084335"
            />
            <path
              d="M46.2932 5.5172V9.65513C46.2932 11.1787 45.0582 12.4137 43.5346 12.4137H21.4657C19.9421 12.4137 18.707 11.1787 18.707 9.65513V5.5172C18.707 3.99365 19.9421 2.75858 21.4657 2.75858H26.3401C27.1545 2.73883 27.9232 2.378 28.4588 1.76409C29.5026 0.640918 30.9668 0.00280762 32.5001 0.00280762C34.0335 0.00280762 35.4977 0.640918 36.5415 1.76409C37.077 2.378 37.8457 2.73883 38.6601 2.75858H43.5346C45.0582 2.75858 46.2932 3.99365 46.2932 5.5172Z"
              fill="#DCE3F5"
            />
            <path
              d="M32.4999 6.89656C33.2617 6.89656 33.8792 6.27902 33.8792 5.51725C33.8792 4.75548 33.2617 4.13794 32.4999 4.13794C31.7381 4.13794 31.1206 4.75548 31.1206 5.51725C31.1206 6.27902 31.7381 6.89656 32.4999 6.89656Z"
              fill="#F6F8FF"
            />
          </svg>
        }
      >
        {children}
      </ErrorTemplate>
    </UIThemeProvider>
  );
}

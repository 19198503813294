import {
  CameraImageViewProps,
  CameraType,
  CameraVideoViewProps,
  CameraViewProps,
} from '../types';
import { VideoJsPlayerWithRecordOptions } from './types';

export function getRecordByType(type: CameraType) {
  if (type === CameraType.IMAGE) {
    return {
      audio: false,
      image: {
        width: {
          min: 640,
          ideal: 1280,
          max: 1280,
        },
        height: {
          min: 480,
          ideal: 720,
          max: 720,
        },
      },
    };
  }

  return {
    audio: true,
    video: {
      width: { min: 640, ideal: 1280, max: 1280 },
      height: { min: 480, ideal: 720, max: 720 },
    },
    maxLength: 60,
    videoMimeType: 'video/webm;codecs=vp8,opus',
  };
}

export function getOptions(type: CameraType): VideoJsPlayerWithRecordOptions {
  return {
    controls: false,
    bigPlayButton: false,
    width: 1280,
    height: 720,
    fluid: true,
    plugins: {
      record: getRecordByType(type),
    },
  };
}

export function isVideoType(
  props: CameraViewProps
): props is CameraVideoViewProps {
  return props.type === CameraType.VIDEO;
}

export function isImageType(
  props: CameraViewProps
): props is CameraImageViewProps {
  return props.type === CameraType.IMAGE;
}

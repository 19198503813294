import { createApi } from '@synvox/api';
import axios from 'axios';
import * as Sentry from '@sentry/react';

const createApiClient = (baseURL: string, loginPath: string) => {
  const client = axios.create({
    baseURL,
    withCredentials: true,
  });

  client.interceptors.response.use(
    (config) => {
      if (!config.request.responseURL.includes(config.config.url)) {
        redirectToLoginPage(baseURL, loginPath);
      } else {
        switch (config?.status || 200) {
          case 303:
          case 302:
          case 301:
            redirectToLoginPage(baseURL, loginPath);
            break;
          default: {
            if (config?.status && config?.status !== 200) {
              Sentry.captureMessage(
                JSON.stringify({
                  url: config?.config?.url,
                  status: config?.status,
                })
              );
            }
            break;
          }
        }
        return config;
      }
    },
    (error) => {
      if (error.message === 'Network Error') {
        redirectToLoginPage(baseURL, loginPath);
      } else {
        switch (error?.response?.status) {
          case 0:
          case 303:
          case 302:
          case 301:
            redirectToLoginPage(baseURL, loginPath);
            break;
          default: {
            if (error?.status) {
              Sentry.captureMessage(
                JSON.stringify({
                  url: error?.config?.url,
                  status: error?.status,
                })
              );
            }
            break;
          }
        }
        return Promise.reject(error);
      }
    }
  );

  return client;
};

const redirectToLoginPage = (baseURL: string, loginPath: string) => {
  window.location.href = `${baseURL}${loginPath}/login?redirect_uri=${encodeURIComponent(
    window.location.href
  )}`;
};

const axiosClient = createApiClient(
  process.env.REACT_APP_API_HOST || '',
  '/auth'
);
const axiosCustomerClient = createApiClient(
  window.location.origin || process.env.REACT_APP_CUSTOMER_API_HOST || '',
  '/auth_customer'
);

const axiosCustomerWSClient = createApiClient(
  process.env.REACT_APP_API_HOST || '',
  '/auth_customer'
);

const { useApi: useApiNotAuthorized } = createApi(axiosCustomerClient, {
  retryCount: 5,
});
const { useApi } = createApi(axiosClient, {
  retryCount: 5,
});

function setHeaderNotAuthorized(header: string, value: string) {
  axiosCustomerClient.defaults.headers.common[`${header}`] = value;
}

function setHeaderAuthorized(header: string, value: string) {
  axiosClient.defaults.headers.common[`${header}`] = value;
}

export {
  useApi,
  useApiNotAuthorized,
  axiosClient,
  axiosCustomerClient,
  setHeaderNotAuthorized,
  axiosCustomerWSClient,
  setHeaderAuthorized,
};

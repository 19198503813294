import styled from '@emotion/styled';
import { parseAlign } from '../CollapseInfo/utils';
import { HyperlinkViewProps } from './types';

export const Container = styled.div<
  Required<Pick<HyperlinkViewProps, 'align' | 'size'>>
>`
  text-align: ${({ align }) => parseAlign(align)};
  margin-bottom: 20px;

  a {
    display: inline-flex;
    text-decoration: none;
  }

  a,
  span {
    cursor: pointer;
  }
`;

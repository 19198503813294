import { ThemeProvider } from './styles';
import { PropsWithChildren } from 'react';
import { UIThemeProviderViewProps } from './types';
import { Global } from '@emotion/react';
import { getAppFontsValues } from '../fonts';
import { useApiHostContext } from 'Context';

export function UIThemeProvider({
  children,
  ...props
}: PropsWithChildren<UIThemeProviderViewProps>) {
  const { apiHost } = useApiHostContext();
  return (
    <ThemeProvider {...props} apiHost={apiHost} id="theme">
      <Global styles={getAppFontsValues(props.type, props.customUI)} />
      {children}
    </ThemeProvider>
  );
}

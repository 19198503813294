import { createContext, PropsWithChildren, useContext } from 'react';
import { ClientContextType, ClientProviderViewProps } from './types';

export const ClientContext = createContext<ClientContextType>({
  formClientId: '',
});

export function useClientContext() {
  const context = useContext(ClientContext);

  return context;
}

export function ClientProvider({
  children,
  formClientId,
}: PropsWithChildren<ClientProviderViewProps>) {
  return (
    <ClientContext.Provider value={{ formClientId }}>
      {children}
    </ClientContext.Provider>
  );
}

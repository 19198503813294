import { createContext, useContext } from 'react';
import { FormApiContextProps } from './types';

export const FormApiContext = createContext<FormApiContextProps>({
  loading: false,
  onSubmit: () => Promise.resolve(undefined),
});

export function useFormApiContext() {
  return useContext(FormApiContext);
}

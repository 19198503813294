export function getFileSize(fileSize: string): string {
  if (fileSize) {
    const size = Number(fileSize);
    if (size < 1000000) {
      return (size / 1000).toFixed(1) + ' KB';
    }
    return (size / 1000000).toFixed(1) + ' MB';
  }
  return '0 KB';
}

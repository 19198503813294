import { useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { useFormFieldController } from 'Hooks';
import { InputDecorator } from 'UIComponents';
import { FieldContainer } from '../FieldContainer';
import { CustomInput, Header } from './components';
import { Wrapper } from './styles';
import {
  DatePickerViewProps,
  DatePickerWithDecoratorViewProps,
  FormDatePickerViewProps,
  PickerViewProps,
  UBTFormDatePickerViewProps,
} from './types';
import { MIN_THRESHOLD_YEARS, MAX_THRESHOLD_YEARS } from './constants';
import { convertLocalToUTCDate, convertUTCToLocalDate } from './utils';

function Picker({
  onChange,
  value,
  showTimeSelect,
  error,
  showMonthYearPicker,
  ...props
}: PickerViewProps) {
  const minDate =
    props.minDate && moment(props.minDate).isValid()
      ? moment(props.minDate)
      : moment().subtract(MIN_THRESHOLD_YEARS, 'years');

  const maxDate =
    props.maxDate && moment(props.maxDate).isValid()
      ? moment(props.maxDate)
      : moment().add(MAX_THRESHOLD_YEARS, 'years');

  const renderCustomHeader = useCallback(
    (props) => <Header minDate={minDate} maxDate={maxDate} {...props} />,
    [maxDate, minDate]
  );

  const onChangeDate = useCallback(
    (nextDate: Date | null) => {
      if (nextDate instanceof Date) {
        onChange?.(convertLocalToUTCDate(nextDate));
      }
    },
    [onChange]
  );

  return (
    <Wrapper error={error} showMonthYearPicker={showMonthYearPicker}>
      <ReactDatePicker
        selected={convertUTCToLocalDate(value)}
        dateFormat={showTimeSelect ? 'MM/dd/yyyy hh:mm aa' : 'MM/dd/yyyy'}
        onChange={onChangeDate}
        showMonthYearPicker={showMonthYearPicker}
        renderCustomHeader={renderCustomHeader}
        {...props}
      />
    </Wrapper>
  );
}

export function DatePicker(props: DatePickerViewProps) {
  return (
    <Picker
      customInput={<CustomInput showTimeSelect={props.showTimeSelect} />}
      {...props}
    />
  );
}

export function MonthPicker(props: PickerViewProps) {
  return <Picker showMonthYearPicker={true} dateFormat={'MMMM'} {...props} />;
}

export function YearPicker(props: PickerViewProps) {
  return (
    <Picker
      yearItemNumber={20}
      showYearPicker={true}
      dateFormat={'yyyy'}
      {...props}
    />
  );
}

export function DatePickerWithDecorator({
  label,
  error,
  ...props
}: DatePickerWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <DatePicker {...props} error={!!error} />
    </InputDecorator>
  );
}

export function UBTFormDatePicker({
  value,
  isVisible,
  field,
  error,
}: UBTFormDatePickerViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={!!field.options?.half_width}>
      <DatePickerWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        minDate={
          new Date(
            (field.options as any)?.dateConstraints?.isAfter?.replace(
              /\./g,
              '/'
            )
          )
        }
        maxDate={
          new Date(
            (field.options as any)?.dateConstraints?.isBefore?.replace(
              /\./g,
              '/'
            )
          )
        }
      />
    </FieldContainer>
  );
}

export function FormDatePicker({ field }: FormDatePickerViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <DatePickerWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        minDate={
          new Date(
            (field.options as any)?.dateConstraints?.isAfter?.replace(
              /\./g,
              '/'
            )
          )
        }
        maxDate={
          new Date(
            (field.options as any)?.dateConstraints?.isBefore?.replace(
              /\./g,
              '/'
            )
          )
        }
      />
    </FieldContainer>
  );
}

import { useCallback, useMemo } from 'react';
import { InputDecorator } from '../InputDecorator';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import { selectComponents } from './components';
import {
  FormSelectViewProps,
  SelectOption,
  SelectViewProps,
  SelectWithDecoratorViewProps,
  UBTFormSelectViewProps,
} from './types';
import { isMultiValue } from './utils';
import { StateManagedSelect } from './components/types';
import { NoteWrapper } from '../InputDecorator/styles';
import { Icon, Text } from 'UIComponents';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { FieldContainer } from '../FieldContainer';
import { useFormFieldController } from 'Hooks';

export function checkExist(
  firstArray: Maybe<any[]>,
  secondArray?: Maybe<any[]>
) {
  return firstArray?.find((checkedValue) =>
    secondArray?.includes(checkedValue)
  );
}

export function Select({
  data,
  multi,
  placeholder,
  error,
  defaultValue,
  disabled,
  searchable = false,
  onChange,
  value,
  creatable,
  components,
  menuPortalTarget,
  menuPlacement,
  options,
  ...props
}: SelectViewProps) {
  const selectOptions = useMemo(() => {
    const selectOptions = data || [];
    const sortedOptions = options?.sortValues
      ? selectOptions?.sort((a, b) => a.label.localeCompare(b.label))
      : selectOptions;

    return sortedOptions;
  }, [data, options?.sortValues]);

  const selectValue = useMemo(() => {
    if (multi && value) {
      if (!Array.isArray(value)) {
        return value.split(',').reduce((acc, val) => {
          const el = data?.find((it) => it.value === val);
          if (el) {
            return [...acc, el];
          }
          return acc;
        }, [] as SelectOption[]);
      } else {
        return value.reduce((acc, val) => {
          const el = data?.find((it) => it.value === val);
          if (el) {
            return [...acc, el];
          }
          return acc;
        }, [] as SelectOption[]);
      }
    } else if (value) {
      return data?.find((it) => it.value === value);
    }
    return null;
  }, [data, multi, value]);

  const renderComponents = useMemo(
    () => ({
      ...selectComponents,
      ...(components || {}),
    }),
    [components]
  );

  const handlerChange = useCallback(
    (
      newValue:
        | MultiValue<SelectOption | undefined>
        | SingleValue<SelectOption | undefined>
    ) => {
      if (isMultiValue(newValue)) {
        onChange?.(
          (newValue || []).reduce((acc, it) => {
            if (it?.value) {
              return [...acc, it.value];
            }
            return acc;
          }, [] as string[])
        );
      } else {
        onChange?.(newValue?.value);
      }
    },
    [onChange]
  );

  const Component = useMemo(
    () => (creatable ? Creatable : ReactSelect) as StateManagedSelect,
    [creatable]
  );

  const restrictionWarning = useMemo(() => {
    const valuesForRestrict = options?.selectRestrictions?.values;
    const warning = options?.selectRestrictions?.warning;

    if ((valuesForRestrict ? valuesForRestrict.length === 0 : true) || !value) {
      return null;
    } else {
      const values = Array.isArray(value) ? value : [value];
      return checkExist(valuesForRestrict, values) ? warning : null;
    }
  }, [options, value]);

  return (
    <>
      <Component
        {...props}
        value={selectValue}
        className="basic-single"
        classNamePrefix="select"
        components={renderComponents}
        isDisabled={disabled}
        options={selectOptions}
        menuPlacement="auto"
        menuPosition="absolute"
        isSearchable={searchable}
        isClearable={creatable}
        placeholder={placeholder}
        isMulti={!!multi}
        error={!!error}
        onChange={handlerChange}
      />
      {Boolean(restrictionWarning) && (
        <NoteWrapper>
          <Icon glyph="triangleWarning" color="warning-text-color" size={12} />
          <Text
            text={restrictionWarning}
            color="warning-text-color"
            size={12}
          />
        </NoteWrapper>
      )}
    </>
  );
}

export function SelectWithDecorator({
  label,
  error,
  ...props
}: SelectWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <Select error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormSelect({
  value,
  isVisible,
  field,
  error,
  multi,
}: UBTFormSelectViewProps) {
  const data = useMemo(() => {
    const array =
      (field.field_select_values || []).length > 0
        ? field.field_select_values
        : field.field_template.field_select_values;
    return array?.reduce((acc, value) => {
      return [
        ...acc,
        {
          label: value.name,
          value: field.use_name_as_value
            ? value.name
            : value.value || value.name,
        },
      ];
    }, [] as SelectOption[]);
  }, [field]);

  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <SelectWithDecorator
        label={field.name}
        value={value}
        data={data}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || undefined}
        multi={multi}
      />
    </FieldContainer>
  );
}

export function FormSelect({ field, multi }: FormSelectViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  const data = useMemo(() => {
    const array =
      (field.field_select_values || []).length > 0
        ? field.field_select_values
        : field.field_template.field_select_values;
    return array?.reduce((acc, value) => {
      return [
        ...acc,
        {
          label: value.name,
          value: field.field.use_name_as_value
            ? value.name
            : value.value || value.name,
        },
      ];
    }, [] as SelectOption[]);
  }, [field]);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <SelectWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        data={data}
        options={field.options}
        multi={multi}
        placeholder={field.options?.placeholder || ''}
        searchable
      />
    </FieldContainer>
  );
}

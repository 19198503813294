import { useCallback } from 'react';
import {
  CustomHeaderContainer,
  CustomHeaderText,
  CustomListWrapper,
  CustomValue,
} from '../shared.styles';
import { MonthViewProps } from './types';
import { useMonthsController } from './controller';

export function Months({
  changeMonth,
  setViewMode,
  minDate,
  maxDate,
  date,
}: MonthViewProps) {
  const { months, handleChangeMonth, label } = useMonthsController(
    changeMonth,
    setViewMode,
    date,
    minDate,
    maxDate
  );

  const renderMonth = useCallback(
    (item, index) => (
      <CustomValue
        isSelected={item.isSelected}
        disabled={item.isDisabled}
        onClick={handleChangeMonth(index)}
      >
        {item.month}
      </CustomValue>
    ),
    [handleChangeMonth]
  );

  return (
    <CustomHeaderContainer>
      <CustomHeaderText>{label}</CustomHeaderText>
      <CustomListWrapper>{months.map(renderMonth)}</CustomListWrapper>
    </CustomHeaderContainer>
  );
}

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFieldContext } from '../../../../../context';
import { useVerificationFieldContext } from '../../../context';

export function useCheckController() {
  const { isDocument, isFace, setCurrentState, rawImage, setRawImage } =
    useVerificationFieldContext();
  const { onChange, value } = useFieldContext();
  const isRender = useRef(true);

  useEffect(() => {
    return () => {
      isRender.current = false;
    };
  }, []);

  const title = useMemo(() => {
    if (isFace) {
      return 'Check your selfie';
    }
    if (isDocument) {
      return 'Check your document photo';
    }
  }, [isDocument, isFace]);

  const src = useMemo(() => rawImage?.url, [rawImage]);

  const handlerConfirm = useCallback(
    async (e) => {
      e.preventDefault();
      if (rawImage) {
        const promise = onChange?.(rawImage.file as File);
        setCurrentState('result');
        await promise;
        setRawImage((prev) => {
          if (prev) {
            (window.URL || window.webkitURL).revokeObjectURL(prev.url);
          }
          return undefined;
        });
      }
    },
    [onChange, setRawImage, setCurrentState, rawImage]
  );

  const declineImage = useCallback(
    (e) => {
      e.preventDefault();
      setCurrentState('camera');
      setRawImage((prev) => {
        if (prev) {
          (window.URL || window.webkitURL).revokeObjectURL(prev.url);
        }
        return undefined;
      });
    },
    [setCurrentState, setRawImage]
  );

  return { title, src, declineImage, loading: value?.loading, handlerConfirm };
}

import { defer } from '@synvox/api';
import { useApiContext } from 'Context';
import { UploadedFile } from 'aid-form-service';
import { Maybe } from 'aid-form-service/lib/utils';

export function usePDFViewerModel(document_id: Maybe<string>) {
  const api = useApiContext();
  const { data, loading } = defer<
    { data: UploadedFile | undefined } | undefined
  >(
    () =>
      document_id && (api as any).documents.download.meta_info[document_id]()
  );

  return { document: data?.data, loading };
}

import { PropsWithChildren } from 'react';
import { ConfirmModalContext } from './ConfirmModalContext';
import { useConfirmModalController } from './controller';

export function ConfirmModalProvider({ children }: PropsWithChildren<{}>) {
  const { value, modals } = useConfirmModalController();

  return (
    <>
      <ConfirmModalContext.Provider value={value.current}>
        {children}
      </ConfirmModalContext.Provider>
      {Object.values(modals.current)}
    </>
  );
}

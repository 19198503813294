import { FingerprintContext } from './context';
import { useFingerprintProviderController } from './controller';
import { PropsWithChildren, useCallback, useContext } from 'react';

export function useFingerprint() {
  const context = useContext(FingerprintContext);

  const getVisitorOptions = useCallback(async () => {
    try {
      const agent = await context;
      return await agent.get();
    } catch (e) {
      return {
        requestId: null,
        visitorFound: false,
        visitorId: null,
      };
    }
  }, [context]);

  return { getVisitorOptions };
}

export function FingerprintProvider({ children }: PropsWithChildren<{}>) {
  const context = useFingerprintProviderController();

  return (
    <FingerprintContext.Provider value={context as any}>
      {children}
    </FingerprintContext.Provider>
  );
}

import styled from '@emotion/styled/macro';
import { Text } from 'UIComponents';
import { getColor } from 'UIUtils';
import {
  EmotionCheckmarkViewProps,
  EmotionRadioWrapperViewProps,
  EmotionStyledInputViewProps,
} from './types';

export const Label = styled(Text)`
  padding-left: 25px;
  line-height: 16px;
  overflow-wrap: anywhere;
`;

export const Description = styled(Text)`
  padding-left: 25px;
  overflow-wrap: anywhere;
  margin-top: 8px;
`;

export const Checkmark = styled.span<EmotionCheckmarkViewProps>`
  position: absolute;
  height: 16px;
  width: 16px;
  background: ${({ disabled }) =>
    getColor(disabled ? 'disabled-input-background' : 'white')};
  border: ${({ error }) =>
    `1px solid ${getColor(error ? 'error-text-color' : 'input-border')}`};
  box-sizing: border-box;
  box-shadow: ${({ disabled }) =>
    disabled ? 'none' : `1px 1px 0px ${getColor('disabled-input-background')}`};
  border-radius: 50%;
  top: -8px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    box-sizing: border-box;
    left: 5px;
    top: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${getColor('white')};
  }
`;

export const LabelWraper = styled.div`
  display: flex;
`;

export const RadioWrapper = styled.label<EmotionRadioWrapperViewProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 22px;
  user-select: none;
  padding: 5px 5px;
  border-radius: 4px;

  ${({ disabled }) => disabled && 'opacity: 0.6;'}

  :hover {
    background: ${({ label, disabled }) =>
      !disabled && label
        ? getColor('disabled-input-background')
        : 'transparent'};
  }
`;

export const ContainerCheckmark = styled.div`
  position: relative;
  margin-top: 8px;
`;

export const StyledInput = styled.input<EmotionStyledInputViewProps>`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${ContainerCheckmark} {
    ${Checkmark} {
      border: none;
      background: ${({ error }) =>
        getColor(error ? 'error-text-color' : 'input-focus-background')};
      &:after {
        display: block;
      }
    }
  }
`;

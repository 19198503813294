import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { useRegionProviderController } from './controller';
import { RegionContextType } from './types';

export const RegionContext = createContext<RegionContextType>({});

export function useRegionContext() {
  const context = useContext(RegionContext);

  useEffect(() => {
    if (!context.countries && !context.loading) {
      context.loadCountries?.();
    }
  }, [context]);

  return context;
}

export function RegionProvider({ children }: PropsWithChildren<{}>) {
  const value = useRegionProviderController();
  return (
    <RegionContext.Provider value={value}>{children}</RegionContext.Provider>
  );
}

import { forwardRef } from 'react';
import ReactModal from 'react-modal';
import { PrimaryButton } from '../FormButtons';
import { Text } from '../Text';
import { useModalController } from './controller';
import { ModalsImperativeRef } from './controller/types';
import {
  styles,
  Container,
  TextContainer,
  ActionContainer,
  ModalContainer,
} from './styles';
import { ModalViewProps, RenderBodyViewProps } from './types';

function RenderBody({
  title,
  body,
  onClickConfirm,
  action,
}: RenderBodyViewProps) {
  return (
    <ModalContainer>
      <Container>
        <TextContainer>
          <Text
            text={title}
            size={20}
            weight="Bold"
            color="input-focus-border"
          />
          {body && <Text text={body} color="primary-text-color" />}
        </TextContainer>
      </Container>
      <ActionContainer>
        <PrimaryButton onClick={onClickConfirm} theme="main-deep-4">
          {action}
        </PrimaryButton>
      </ActionContainer>
    </ModalContainer>
  );
}

export const Modal = forwardRef<ModalsImperativeRef, ModalViewProps>(function (
  { title, body, action },
  ref
) {
  const { isOpen, close, onClickConfirm, parentSelector } = useModalController(
    ref,
    title
  );

  return (
    <ReactModal
      parentSelector={parentSelector}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={close}
      style={styles}
    >
      <RenderBody
        title={title}
        body={body}
        action={action}
        close={close}
        onClickConfirm={onClickConfirm}
      />
    </ReactModal>
  );
});

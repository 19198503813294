import { useMemo } from 'react';
import { SwitchByType } from './components';
import { RequiredViewProps } from './types';

export function Required({
  markingType = 'required',
  isRequired,
  type = 'default',
  ...props
}: RequiredViewProps) {
  const canShow = useMemo(() => {
    if (markingType === 'required') {
      return isRequired;
    } else if (markingType === 'optional') {
      return !isRequired;
    } else if (markingType === 'both') {
      return true;
    }
    return false;
  }, [isRequired, markingType]);

  if (canShow) {
    return <SwitchByType {...props} type={type} isRequired={isRequired} />;
  }

  return null;
}

import { createContext, useContext } from 'react';
import { FieldContextProps } from './types';

export const FieldContext = createContext<FieldContextProps>({
  error: false,
  onChange: () => {},
});

export function useFieldContext() {
  const context = useContext(FieldContext);

  return context;
}

import { useLanguageContext } from 'Context';
import { useDropFieldProps } from 'Hooks';
import { useCallback, useMemo, useState } from 'react';
import { useMediaController } from '../../../controller/MediaController';
import { ImageFieldViewProps } from '../types';

function checkMimeType(type: string) {
  if (/image\/.*/gm.test(type)) {
    return true;
  }
  return false;
}

export function useImageController({
  options,
  onChange,
  typeUploads,
}: Omit<ImageFieldViewProps, 'error' | 'loading'>) {
  const {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    actionRef,
    anotherUpload,
    handlerUseWebcam,
    showMobileButtons,
    liveOnly,
    inputFile,
    mobileOnly,
    onChangeWebcam,
    useWebCam,
    webCamRef,
    setUseWebCam,
  } = useMediaController({
    options,
    onChange,
    typeUploads,
  });
  const [loading, setLoading] = useState(false);
  const language = useLanguageContext();

  const onDropAccepted = useCallback(
    (files) => {
      onChange?.(files[0]);
    },
    [onChange]
  );

  const { onSetRejected, ...dropFieldProps } = useDropFieldProps(
    liveOnly || mobileOnly || false,
    {
      accept: 'image/*',
      maxSize: 15728640,
      onDropAccepted,
    }
  );

  const handlerUpload = useCallback(
    async (file) => {
      try {
        let image;
        setLoading(true);
        if (useWebCam) {
          const { blob } = webCamRef.current?.getScreenshot();
          image = await blob;
        } else {
          image = file;
        }
        await onChange?.(image);
        setLoading(false);
        setUseWebCam(false);
      } catch (e) {}
    },
    [onChange, useWebCam, webCamRef, setUseWebCam]
  );

  const handlerChange = useCallback(
    (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      if (file) {
        if (!checkMimeType(file.type)) {
          if (inputFile.current) {
            inputFile.current.value = '';
          }
          onSetRejected('file-invalid-type');
          return setUseWebCam(false);
        }
        if (file?.size > 15728640) {
          if (inputFile.current) {
            inputFile.current.value = '';
          }
          onSetRejected('file-too-large');
          return setUseWebCam(false);
        }

        handlerUpload(file);
      }
    },
    [handlerUpload, inputFile, onSetRejected, setUseWebCam]
  );

  let inputProps = useMemo(
    () => (liveOnly ? {} : dropFieldProps.getInputProps()),
    [liveOnly, dropFieldProps]
  );

  const textOptions = useMemo(
    () => ({
      anotherLabel: language?.getTranslate('ui.media.another_device.label', {
        arg: 'image',
      }),
      label: language?.getTranslate('ui.media.attach.label', { arg: 'image' }),
      description: language?.getTranslate('ui.media.attach.description', {
        type: 'image',
        int: 15,
      }),
      actionLabel: language?.getTranslate('ui.media.browse_files'),
      liveOnlyLabel: language?.getTranslate('ui.media.liveOnly.image'),
      anotherLiveOnlyComment: language?.getTranslate(
        'ui.media.only_mobile.label',
        { arg: 'image' }
      ),
      errorPermission: language?.getTranslate('ui.media.errors.access'),
      errorCameraUsed: language?.getTranslate('ui.media.errors.already_use'),
      errorDeviceLength: language?.getTranslate('ui.media.errors.empty'),
      errorResolution: language?.getTranslate('ui.media.errors.resolution'),
      errorAnotherComment: language?.getTranslate(
        'ui.media.another_device.or_label',
        { arg: 'image' }
      ),
      errorPermissionsComment: language?.getTranslate(
        'ui.media.errors.granted_access',
        { arg: 'image' }
      ),
      errorPermissionAction: language?.getTranslate('ui.media.liveOnly.image'),
    }),
    [language]
  );

  return {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    actionRef,
    anotherUpload,
    inputProps,
    handlerChange,
    handlerUseWebcam,
    showMobileButtons,
    liveOnly,
    dropFieldProps,
    inputFile,
    mobileOnly,
    onChangeWebcam,
    useWebCam,
    webCamRef,
    handlerUpload,
    textOptions,
    loading,
  };
}

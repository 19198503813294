import { THEME_FONTS } from './types';

export const FONTS = {
  Montserrat: `@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');`,
  Poppins: ` @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');`,
  Rubik: `@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');`,
  Archivo: `@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700&display=swap');`,
  Manrope: `@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;900&display=swap');`
};

export const STANDARD_FONTS: THEME_FONTS = {
  primary: 'Rubik',
  secondary: 'Montserrat',
};

export const BFM_FONTS: THEME_FONTS = {
  primary: 'Poppins',
  secondary: 'Poppins',
};


export const SYSTEM_FONTS: THEME_FONTS = {
  primary: 'Manrope',
  secondary:'Manrope',
};

export const ARIVAL_FONTS: THEME_FONTS = {
  primary: 'Rubik',
  secondary: 'Poppins',
};

export const EZZ_BANKING_FONTS: THEME_FONTS = {
  primary: 'Archivo',
  secondary: 'Archivo',
};

import { ErrorTemplate } from 'UIComponents';
import { ThemeType, UIThemeProvider } from 'UIShared';
import { DisabledVerificationFlowViewProps } from './types';
import { PropsWithChildren } from 'react';

export function DisabledVerificationFlow({
  title,
  description,
  children,
}: PropsWithChildren<DisabledVerificationFlowViewProps>) {
  return (
    <UIThemeProvider type={ThemeType.SYSTEM}>
      <ErrorTemplate
        title={title}
        color="error-text-color"
        description={description}
        customIcon={
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2638_2494)">
              <path
                d="M79.3134 22.745L57.255 0.686562C56.8155 0.247031 56.2194 0 55.5978 0H24.4022C23.7806 0 23.1845 0.247031 22.745 0.686562L0.686562 22.745C0.247031 23.1845 0 23.7806 0 24.4022V55.5977C0 56.2192 0.247031 56.8153 0.686562 57.2548L22.745 79.3133C23.1845 79.7528 23.7806 79.9998 24.4022 79.9998H55.5977C56.2192 79.9998 56.8153 79.7528 57.2548 79.3133L79.3133 57.2548C79.7528 56.8153 79.9998 56.2192 79.9998 55.5977V24.4022C80 23.7806 79.753 23.1845 79.3134 22.745Z"
                fill="#B80929"
              />
              <path
                d="M80 24.4016V55.5984C80 56.2187 79.7531 56.8156 79.3141 57.2547L57.2547 79.3141C56.8156 79.7531 56.2187 80 55.5984 80H40V0H55.5984C56.2187 0 56.8156 0.246875 57.2547 0.685937L79.3141 22.7453C79.7531 23.1844 80 23.7812 80 24.4016Z"
                fill="#6E0518"
              />
              <path
                d="M37.058 16.5625H42.9421C44.3058 16.5625 45.3816 17.7222 45.2793 19.0822L43.2804 45.6447C43.1883 46.8675 42.1694 47.8125 40.9432 47.8125H39.0568C37.8305 47.8125 36.8116 46.8673 36.7196 45.6447L34.7208 19.0822C34.6186 17.7222 35.6943 16.5625 37.058 16.5625Z"
                fill="white"
              />
              <path
                d="M45.2797 19.0828L43.2797 45.6453C43.1891 46.8672 42.1688 47.8125 40.9438 47.8125H40V16.5625H42.9422C44.3062 16.5625 45.3813 17.7219 45.2797 19.0828Z"
                fill="white"
              />
              <path
                d="M35.3203 58.7422V58.7578C35.3203 61.3423 37.4155 63.4375 40 63.4375C42.5845 63.4375 44.6797 61.3423 44.6797 58.7578V58.7422C44.6797 56.1577 42.5845 54.0625 40 54.0625C37.4155 54.0625 35.3203 56.1577 35.3203 58.7422Z"
                fill="white"
              />
              <path
                d="M44.6797 58.7422V58.7578C44.6797 60.05 44.1562 61.2203 43.3094 62.0672C42.4625 62.9141 41.2922 63.4375 40 63.4375V54.0625C42.5844 54.0625 44.6797 56.1578 44.6797 58.7422Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2638_2494">
                <rect width="80" height="80" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      >
        {children}
      </ErrorTemplate>
    </UIThemeProvider>
  );
}

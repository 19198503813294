import { debounce, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SelectOption } from '../../Select/types';
import { usePlacesController } from './PlacesController';

export function useMenuController(value?: string) {
  const refMenu = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const preventBlur = useRef<boolean>(false);
  const [focused, setFocused] = useState(false);
  const [data, setData] = useState<SelectOption[]>();
  const memoRequests = useRef<{ [key: string]: SelectOption[] }>({});
  const [loadingStates, setLoadingStates] = useState<{ [key: string]: true }>(
    {}
  );

  useEffect(() => {
    if (!value) {
      setData((prev) => {
        if (!prev) {
          return prev;
        }
        return undefined;
      });
    }
  }, [value]);

  const { loadPlacement, getPlace } = usePlacesController();

  const onFocusInput = useCallback(() => {
    setFocused(true);
    if (refInput.current) {
      onUpdateString.current(refInput.current.value);
    }
  }, []);

  const onBlurInput = useCallback(() => {
    if (!preventBlur.current) {
      setFocused(false);
    } else {
      preventBlur.current = false;
    }
  }, []);

  const opened = useMemo(() => focused && value, [focused, value]);

  const load = useRef((value: string) => {
    loadPlacement.current(value).then((data) => {
      memoRequests.current[value] = data || [];
      setData(data);
      setLoadingStates((prev) => {
        if (prev[value]) {
          const tmp = { ...prev };
          delete tmp[value];
          return tmp;
        }
        return prev;
      });
    });
  });

  const onUpdateString = useRef((value: string) => {
    if (memoRequests.current?.[value]) {
      return setData(memoRequests.current?.[value]);
    }
    setLoadingStates((prev) => {
      if (!prev[value]) {
        load.current(value);
        return { ...prev, [value]: true };
      }
      return prev;
    });
  });

  const loadByString = useRef(
    debounce((e) => {
      if (e) {
        onUpdateString.current(e);
      }
    }, 500)
  );

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (
        opened &&
        refInput.current &&
        !refInput.current.contains(e.target as Node) &&
        refMenu.current &&
        !refMenu.current.contains(e.target as Node)
      ) {
        setFocused(false);
      } else {
        preventBlur.current = true;
      }
    };
    if (opened) {
      document.addEventListener('mousedown', checkIfClickedOutside, true);
      document.addEventListener('touchstart', checkIfClickedOutside, true);
    }

    return () => {
      if (opened) {
        document.removeEventListener('mousedown', checkIfClickedOutside, true);
        document.removeEventListener('touchstart', checkIfClickedOutside, true);
      }
    };
  }, [opened]);

  return {
    opened,
    refMenu,
    refInput,
    onFocusInput,
    onBlurInput,
    loadByString,
    data,
    loading: !isEmpty(loadingStates),
    getPlace,
    setFocused,
  };
}

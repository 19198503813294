import { isMobile } from 'react-device-detect';
import { AnotherUpload } from '../../../AnotherUpload';
import { Default } from '../Default';
import { Field } from './components';
import { IsMediaRecorderExist } from '../../constants';
import { TYPE_FILE } from '../../../AnotherUpload/controller/constants';
import {
  RenderNotExistMediaRecorderViewProps,
  RenderOnlyMobileViewProps,
  WebcamBodyViewProps,
} from './types';

function RenderOnlyMobile({
  value,
  onChange,
  typeUploads,
  textOptions,
  mobileOnlyType,
  RenderValueComponent,
}: RenderOnlyMobileViewProps) {
  if (value) {
    return <RenderValueComponent value={value} />;
  }

  return (
    <AnotherUpload
      label={textOptions.anotherLabel}
      onChange={onChange}
      type={mobileOnlyType}
      typeUploads={typeUploads}
      titleComment={textOptions.anotherLiveOnlyComment}
    />
  );
}

function RenderNotExistMediaRecorder({
  value,
  onChange,
  typeUploads,
  textOptions,
  anotherType,
  RenderValueComponent,
}: RenderNotExistMediaRecorderViewProps) {
  if (value) {
    return <RenderValueComponent value={value} />;
  }

  return (
    <AnotherUpload
      label={textOptions.errorMediaRecorderLabel}
      onChange={onChange}
      type={anotherType}
      typeUploads={typeUploads}
      titleComment={textOptions.errorMediaRecorderComment}
    />
  );
}

export function WebCamBody({
  mobileOnly,
  value,
  onChange,
  webCamRef,
  anotherUpload,
  useWebCam,
  liveOnly,
  onComplete,
  handlerButtonClick,
  typeUploads,
  mobileOnlyType,
  actionRef,
  actionButton,
  RenderValueComponent,
  anotherType,
  anotherCommentType,
  cameraType,
  textOptions,
  watermark,
}: WebcamBodyViewProps) {
  if (mobileOnly && !isMobile) {
    return (
      <RenderOnlyMobile
        value={value}
        onChange={onChange}
        typeUploads={typeUploads}
        textOptions={textOptions}
        RenderValueComponent={RenderValueComponent}
        mobileOnlyType={mobileOnlyType}
      />
    );
  }

  if (!IsMediaRecorderExist && anotherType === TYPE_FILE.VIDEO) {
    return (
      <RenderNotExistMediaRecorder
        onChange={onChange}
        anotherType={anotherType}
        typeUploads={typeUploads}
        textOptions={textOptions}
        value={value}
        RenderValueComponent={RenderValueComponent}
      />
    );
  }

  return anotherUpload ? (
    <AnotherUpload
      label={textOptions.anotherLabel}
      onChange={onChange}
      type={anotherType}
      typeUploads={[anotherUpload]}
      watermark={watermark}
      commentType={anotherCommentType}
    />
  ) : useWebCam ? (
    <Field
      ref={webCamRef}
      onChange={onChange}
      onComplete={onComplete}
      typeUploads={typeUploads}
      actionButton={actionButton}
      actionRef={actionRef}
      anotherType={anotherType}
      cameraType={cameraType}
      anotherCommentType={anotherCommentType}
      textOptions={textOptions}
    />
  ) : value ? (
    <RenderValueComponent value={value} />
  ) : (
    <Default
      handlerButtonClick={handlerButtonClick}
      liveOnly={!!liveOnly}
      textOptions={textOptions}
    />
  );
}

import styled from '@emotion/styled';
import { getColor } from 'UIUtils';

export const CameraContainer = styled.div`
  position: relative;
  height: 280px;
  background: ${getColor('white')};
  overflow: hidden;
`;

export const Video = styled.video`
  display: none;
`;

export const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-border-radius: 1px;
`;

export const CameraActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 0 20px 20px 20px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

import { css } from '@emotion/react';
import { generateFonts } from './utils';
import { FONTS } from './constants';
import { CustomUI } from 'aid-form-service/lib/ApiClient/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { ThemeType } from 'UIShared';

export const FONT_PREFIX = '--font-';

export function getAppFontsValues(type: ThemeType, customUI: Maybe<CustomUI>) {
  return css(`
  ${Object.entries(generateFonts(type, customUI)).reduce(
    (acc, [_, value]) => `${acc}
  ${FONTS[value as keyof typeof FONTS]}`,
    ''
  )}
`);
}

export function getAppFonts(type: ThemeType, customUI: Maybe<CustomUI>) {
  return css(`
  ${Object.entries(generateFonts(type, customUI)).reduce(
    (acc, [key, value]) => `${acc}
    ${FONT_PREFIX}${key}: ${value || 'none'};`,
    ''
  )}
`);
}

import { useCallback, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { TYPE_FILE_UPLOAD_CLAIM } from '../../AnotherUpload/controller/constants';
import { WebCamImperativeRef } from '../components/WebCamBody/components/Field/controller/types';
import { UseMediaControllerProps } from './types';
import { FileValue } from 'aid-form-service/lib/State/StateControllers/FieldControllers/MediaFieldController/index.types';

export function useMediaController({
  options,
  onChange,
  typeUploads,
}: UseMediaControllerProps) {
  const [useWebCam, setUseWebCam] = useState(false);
  const inputFile = useRef<HTMLInputElement>(null);
  const webCamRef = useRef<WebCamImperativeRef>(null);
  const actionRef = useRef(null);
  const [anotherUpload, setAnotherUpload] = useState<string>();
  const liveOnly = useMemo(() => options?.liveOnly, [options]);
  const mobileOnly = useMemo(() => options?.mobile_only, [options]);

  const showMobileButtons = useMemo(
    () => isMobile || !mobileOnly,
    [mobileOnly]
  );

  const onChangeWebcam = useCallback(
    (file?: FileValue | File) => {
      if (file) {
        onChange?.(file as File);
        setAnotherUpload(undefined);
      }
    },
    [onChange]
  );

  const handlerUseWebcam = useCallback((e) => {
    e.preventDefault();
    setUseWebCam((prev) => !prev);
    setAnotherUpload(undefined);
  }, []);

  const handlerButtonClick = useCallback((e) => {
    e.preventDefault();
    inputFile.current?.click();
  }, []);

  const handlerRemove = useCallback(() => {
    if (inputFile.current) {
      inputFile.current.value = '';
    }
    onChange?.(undefined);
  }, [onChange]);

  const toggleAnotherUpload = useCallback(
    (type?: string) => () => {
      setUseWebCam(false);
      setAnotherUpload(type);
    },
    []
  );

  const isAnotherEmail = useMemo(
    () =>
      typeUploads?.find(
        (type: string) => type === TYPE_FILE_UPLOAD_CLAIM.EMAIL
      ),
    [typeUploads]
  );

  const isAnotherQR = useMemo(
    () =>
      typeUploads?.find((type: string) => type === TYPE_FILE_UPLOAD_CLAIM.QR),
    [typeUploads]
  );

  return {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    actionRef,
    anotherUpload,
    handlerUseWebcam,
    showMobileButtons,
    liveOnly,
    inputFile,
    mobileOnly,
    onChangeWebcam,
    useWebCam,
    webCamRef,
    setUseWebCam,
  };
}

export function validateEmail(value?: string) {
  if (!value) {
    return { message: 'Required' };
  }
  if (
    value &&
    !/.+@.+\..+/i.test(value) &&
    value !== '' &&
    value !== undefined &&
    value !== null
  ) {
    return { message: 'Invalid email format' };
  }
  return;
}

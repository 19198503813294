import { RequiredViewProps } from '../../types';
import { Default } from '../Default';
import { Header } from '../Header';

export function SwitchByType({
  type,
  ...props
}: Omit<RequiredViewProps, 'markingType'>) {
  switch (type) {
    case 'default':
      return <Default {...props} />;
    case 'header':
      return <Header {...props} />;
    default:
      return null;
  }
}

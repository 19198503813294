import { ErrorTemplate } from 'UIComponents';
import { ThemeType, UIThemeProvider } from 'UIShared';
import { ErrorWrongViewProps } from './types';
import { PropsWithChildren } from 'react';

export function ErrorWrong({
  title,
  description,
  children,
}: PropsWithChildren<ErrorWrongViewProps>) {
  return (
    <UIThemeProvider type={ThemeType.SYSTEM}>
      <ErrorTemplate
        color="error-text-color"
        title={title}
        description={description}
        customIcon={
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.4751 12.1024C21.506 12.6548 21.3005 13.194 20.9095 13.5873L18.9063 15.5902L37.1154 33.7988C38.0313 34.7148 38.0302 36.1985 37.1154 37.1134C36.1935 38.0352 34.7123 38.0248 33.8009 37.1134L15.5918 18.9048L13.5876 20.909C13.223 21.2735 12.728 21.4768 12.2132 21.4768C12.1779 21.4768 12.1391 21.4757 12.1027 21.4746C11.5491 21.4426 11.0365 21.1752 10.6918 20.7399L2.1568 10.3756C1.54477 9.60211 1.60883 8.49508 2.30711 7.79696L7.79711 2.30711C8.49539 1.60883 9.60242 1.54477 10.3759 2.1568L20.7416 10.6904C21.1768 11.0352 21.443 11.549 21.4751 12.1024Z"
              fill="#B80929"
            />
            <path
              d="M46.2017 42.8848L52.0684 48.7514C52.9832 49.6662 52.9832 51.151 52.0684 52.0659C51.1456 52.9885 49.6611 52.9731 48.7539 52.0659L42.8871 46.1993C41.9712 45.2834 41.9712 43.8007 42.8871 42.8848C43.802 41.97 45.2857 41.9689 46.2017 42.8848Z"
              fill="#B80929"
            />
            <path
              d="M46.2013 42.8848L52.068 48.7513C52.9829 49.6662 52.9829 51.151 52.068 52.0658L42.8867 42.8848C43.8016 41.97 45.2853 41.9689 46.2013 42.8848Z"
              fill="#6E0518"
            />
            <path
              d="M21.4752 12.1024C21.5062 12.6548 21.3007 13.194 20.9096 13.5873L18.9065 15.5902L37.1155 33.7988C38.0315 34.7148 38.0304 36.1985 37.1155 37.1134L5.05273 5.05149L7.79711 2.30711C8.49539 1.60883 9.60242 1.54477 10.3759 2.1568L20.7416 10.6904C21.177 11.0352 21.4432 11.549 21.4752 12.1024V12.1024Z"
              fill="#6E0518"
            />
            <path
              d="M77.315 74.7274L74.7286 77.3138C71.0349 81.0121 64.9737 80.8866 61.4429 76.9702L43.582 57.0568C42.7512 56.1288 42.7898 54.7157 43.6693 53.8339L53.7079 43.7955C54.5852 42.9205 55.9905 42.8763 56.9187 43.6983L76.9241 61.3988L76.9285 61.4032C80.8594 64.8993 81.0385 71.004 77.315 74.7274V74.7274Z"
              fill="#B80929"
            />
            <path
              d="M77.3155 74.7274L76.0229 76.0201L48.7529 48.7507L53.7082 43.7955C54.5856 42.9205 55.9909 42.8763 56.919 43.6983L76.9244 61.3988L76.9288 61.4032C80.8599 64.8993 81.039 71.004 77.3155 74.7274V74.7274Z"
              fill="#6E0518"
            />
            <path
              d="M79.6111 14.3419C79.2533 12.6372 77.1455 12.009 75.9142 13.2419L68.2392 20.9167C66.8455 22.3104 64.5861 22.3104 63.1923 20.9167L61.1361 18.8604L59.0798 16.8042C57.6861 15.4104 57.6861 13.1512 59.0798 11.759L66.7548 4.08263C67.9876 2.85138 67.3595 0.743726 65.6548 0.385914C59.8798 -0.828149 53.6236 0.807789 49.1392 5.29201C43.4283 11.0044 42.3376 19.5839 45.8642 26.3898L2.37545 62.114C-0.555797 64.4156 -0.815172 68.7592 1.8192 71.395L5.20983 74.7854L8.60045 78.1759C11.2364 80.8103 15.5801 80.5509 17.8817 77.6197L53.6065 34.1317C60.4128 37.6581 68.9925 36.5676 74.7048 30.8569C79.1894 26.3728 80.8251 20.1167 79.6111 14.3419V14.3419ZM13.4692 68.8701C12.8223 68.8701 12.2364 68.6076 11.8114 68.1842C11.388 67.7592 11.1255 67.1733 11.1255 66.5264C11.1255 65.2311 12.1755 64.1826 13.4692 64.1826C14.1176 64.1826 14.702 64.4451 15.127 64.8686C15.5505 65.2936 15.813 65.8779 15.813 66.5264C15.813 67.8201 14.7645 68.8701 13.4692 68.8701Z"
              fill="#F5DCDC"
            />
            <path
              d="M74.7052 30.8583C68.9927 36.5691 60.413 37.6597 53.6069 34.1331L17.8819 77.6211C15.5803 80.5524 11.2365 80.8116 8.60059 78.1774L5.20996 74.7869L11.8115 68.1855C12.2365 68.6089 12.8225 68.8714 13.4693 68.8714C14.7647 68.8714 15.8131 67.8214 15.8131 66.5277C15.8131 65.8792 15.5506 65.2949 15.1272 64.8699L61.1364 18.8617L63.1927 20.918C64.5865 22.3117 66.8458 22.3117 68.2396 20.918L75.9146 13.2431C77.1458 12.0103 79.2537 12.6385 79.6115 14.3431C80.8254 20.118 79.1896 26.3741 74.7052 30.8583V30.8583Z"
              fill="#B80929"
            />
          </svg>
        }
      >
        {children}
      </ErrorTemplate>
    </UIThemeProvider>
  );
}

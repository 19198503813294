import { Icon } from '../Icon';
import { LoaderContainer } from './styles';
import { GlobalLoaderViewProps } from './types';

export function GlobalLoader({ icon = 'spinner' }: GlobalLoaderViewProps) {
  return (
    <LoaderContainer key="global">
      <Icon glyph={icon} size={50} />
    </LoaderContainer>
  );
}

import { DefaultViewProps } from './types'
import { useDefaultController } from './controller'
import {
  ChangeButton,
  CustomHeaderContainer,
  ChangeButtonGroup,
  CustomHeaderText,
} from '../shared.styles'
import { ViewModes } from '../../types'
import { preventDefault } from 'UIUtils'

export function Default({
  increaseYear,
  decreaseYear,
  decreaseMonth,
  increaseMonth,
  setViewMode,
  minDate,
  maxDate,
  date,
}: DefaultViewProps) {
  const {
    year,
    month,
    prevYearButtonIsDisabled,
    nextYearButtonIsDisabled,
    nextMonthButtonIsDisabled,
    prevMonthButtonIsDisabled,
    handlerChangeViewMode,
  } = useDefaultController(date, setViewMode, minDate, maxDate)

  return (
    <CustomHeaderContainer>
      <ChangeButtonGroup>
        <ChangeButton
          disabled={prevYearButtonIsDisabled}
          onClick={preventDefault(decreaseYear)}
        >
          {'<<'}
        </ChangeButton>
        <ChangeButton
          disabled={prevMonthButtonIsDisabled}
          onClick={preventDefault(decreaseMonth)}
        >
          {'<'}
        </ChangeButton>
      </ChangeButtonGroup>
      <CustomHeaderText>
        <span onClick={handlerChangeViewMode(ViewModes.MONTHS)}>{month}</span>
        <span onClick={handlerChangeViewMode(ViewModes.YEARS)}>{year}</span>
      </CustomHeaderText>
      <ChangeButtonGroup>
        <ChangeButton
          disabled={nextMonthButtonIsDisabled}
          onClick={preventDefault(increaseMonth)}
        >
          {'>'}
        </ChangeButton>
        <ChangeButton
          disabled={nextYearButtonIsDisabled}
          onClick={preventDefault(increaseYear)}
        >
          {'>>'}
        </ChangeButton>
      </ChangeButtonGroup>
    </CustomHeaderContainer>
  )
}

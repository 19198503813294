import { PropsWithChildren, useMemo } from 'react';
import {
  OptionProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  ControlProps,
  SingleValueProps,
  MenuListProps,
  components,
  InputProps,
  MenuProps,
  PlaceholderProps,
} from 'react-select';
import { Icon, Text } from 'UIComponents';
import { SelectOption } from '../types';
import {
  DeleteIconContainer,
  LoaderContainer,
  MenuContainer,
  MultiValuesContainer,
  NoOptionsContainer,
  OptionContainer,
  ControlContainer,
  SingleValueText,
  MenuListContainer,
  PlaceholderContainer,
} from './styles';
import { SelectProps } from './types';

function SingleValue({
  children,
  ...props
}: SingleValueProps<SelectOption, boolean>) {
  return (
    <components.SingleValue {...props}>
      <SingleValueText>{children}</SingleValueText>
    </components.SingleValue>
  );
}

function Option(props: OptionProps<SelectOption, boolean>) {
  return (
    <OptionContainer
      {...props.innerProps}
      onClick={!props.isSelected ? props.innerProps.onClick : undefined}
      isSelected={props.isSelected}
    >
      <Text text={props.data?.label} size={12} />
    </OptionContainer>
  );
}

function MultiValueContainer(
  props: MultiValueGenericProps<SelectOption, boolean>
) {
  return <MultiValuesContainer>{props.children}</MultiValuesContainer>;
}

function MultiValueRemove(props: MultiValueRemoveProps<SelectOption, boolean>) {
  return (
    <DeleteIconContainer {...props.innerProps}>
      <Icon glyph="cross" size={9} />
    </DeleteIconContainer>
  );
}

function Control(props: ControlProps<SelectOption, boolean>) {
  const currentProps = useMemo(
    () => props.selectProps as SelectProps,
    [props.selectProps]
  );

  return (
    <ControlContainer
      ref={props.innerRef}
      error={!!currentProps.error}
      {...props.innerProps}
    >
      <components.Control {...props} />
    </ControlContainer>
  );
}

function MultiValueLabel(props: MultiValueGenericProps<SelectOption, boolean>) {
  return <Text size={11}>{props.children}</Text>;
}

function MenuList(props: MenuListProps<SelectOption, boolean>) {
  return (
    <MenuListContainer>
      <components.MenuList {...props} />
    </MenuListContainer>
  );
}

function Menu(props: MenuProps<SelectOption, boolean>) {
  return (
    <MenuContainer>
      <components.Menu {...props} />
    </MenuContainer>
  );
}

function IndicatorSeparator() {
  return null;
}

function Placeholder(props: PlaceholderProps<SelectOption, boolean>) {
  return (
    <components.Placeholder {...props}>
      <PlaceholderContainer>
        <Text color="placeholder-text-color" size={14}>
          {props.selectProps.placeholder}
        </Text>
      </PlaceholderContainer>
    </components.Placeholder>
  );
}

function NoOptionsMessage({ children }: PropsWithChildren<{}>) {
  return (
    <NoOptionsContainer>
      <Text color="note-text-color" size={18}>
        {children}
      </Text>
    </NoOptionsContainer>
  );
}

function LoadingMessage() {
  return (
    <LoaderContainer>
      <Icon glyph="spinner" color="primary-text-color" />
    </LoaderContainer>
  );
}

function Input(props: InputProps<SelectOption, boolean>) {
  return <components.Input {...props} autoComplete="new-password" />;
}

export const selectComponents = {
  Control,
  IndicatorSeparator,
  Placeholder,
  Menu,
  MenuList,
  Option,
  LoadingMessage,
  NoOptionsMessage,
  MultiValueContainer,
  MultiValueRemove,
  MultiValueLabel,
  SingleValue,
  Input,
};

import {
  DropFieldAction,
  DropFieldActions,
  LoaderContainer,
} from '../../styles';
import { Drop } from '../Drop';
import { useSignatureController } from './controller';
import SignaturePad from 'react-signature-pad-wrapper';
import { Icon } from '../../../Icon';
import { InputDecorator } from '../../../InputDecorator';
import { Default } from '../Default';
import {
  FormSignatureViewProps,
  RenderSignatureViewProps,
  SignatureFieldViewProps,
  SignatureWithDecoratorViewProps,
  UBTFormSignatureViewProps,
} from './types';
import { useFormMediaFieldController } from 'Hooks';
import { FieldContainer } from '../../../FieldContainer';
import { MediaValue } from 'UIComponents';
// import { useFormContext } from 'Context';

// function RenderInProgress({ value, handleRemove }: RenderSignatureViewProps) {
//   const { language } = useFormContext();
//   if (value?.safe_download_url) {
//     return <MediaValue type="Signature" value={value} handleRemove={handleRemove} />;
//   }

//   return (
//     <Text
//       text={language?.getTranslate('ui.media.scan.progress', {
//         arg: 'signature',
//       })}
//       color="warning-text-color"
//       align="center"
//       size={22}
//     />
//   );
// }

// function RenderInfected() {
//   const { language } = useFormContext();
//   return (
//     <Text
//       text={language?.getTranslate('ui.media.scan.infected', { arg: 'image' })}
//       color="error-text-color"
//       align="center"
//       size={22}
//     />
//   );
// }

function RenderValue({ value, handleRemove }: RenderSignatureViewProps) {
  // if (value?.av_status === 'in_progress') {
  //   return <RenderInProgress value={value} handleRemove={handleRemove} />;
  // } else if (value?.av_status === 'infected') {
  //   return <RenderInfected />;
  // }

  return (
    <MediaValue type="Signature" value={value} handleRemove={handleRemove} />
  );
}

function RenderSignature({ value, handleRemove }: RenderSignatureViewProps) {
  if (value) {
    return <RenderValue value={value} handleRemove={handleRemove} />;
  }

  return null;
}

export function Signature({
  label,
  onChange,
  value,
  options,
  loading,
  error,
}: SignatureFieldViewProps) {
  const {
    canvasProps,
    clearValue,
    handlerButtonClick,
    handlerChange,
    dropFieldProps,
    handlerConfirm,
    useSignPad,
    inputFile,
    setUseSignPad,
    sign,
    liveOnly,
    textOptions,
  } = useSignatureController({
    options,
    onChange,
    value,
  });

  if (value) {
    return <RenderSignature value={value} handleRemove={clearValue} />;
  }

  return (
    <Drop
      {...dropFieldProps}
      rejected={dropFieldProps.rejected}
      errorType="image"
      error={error}
      liveOnly={!!liveOnly}
    >
      {loading && (
        <LoaderContainer>
          <Icon glyph="spinner" color="white" size={50} />
        </LoaderContainer>
      )}
      <>
        <input
          {...dropFieldProps?.getInputProps()}
          accept="image/*"
          type="file"
          ref={inputFile}
          onChange={handlerChange}
          style={{ display: 'none' }}
          multiple={false}
        />
        {useSignPad ? (
          <SignaturePad ref={sign} canvasProps={canvasProps} />
        ) : (
          <Default
            handlerButtonClick={handlerButtonClick}
            liveOnly={!!liveOnly}
            textOptions={textOptions}
          />
        )}
      </>
      <DropFieldActions>
        <div>
          {!value && !useSignPad && (
            <DropFieldAction onClick={() => setUseSignPad(true)}>
              <Icon glyph="pencil" />
            </DropFieldAction>
          )}
          {!value && useSignPad && (
            <DropFieldAction onClick={() => setUseSignPad(false)}>
              <Icon glyph="cross" />
            </DropFieldAction>
          )}
        </div>
        <div>
          {value && (
            <DropFieldAction onClick={clearValue}>
              <Icon glyph="delete" />
            </DropFieldAction>
          )}
          {!value && useSignPad && (
            <DropFieldAction onClick={handlerConfirm}>
              <Icon glyph="checkmark" />
            </DropFieldAction>
          )}
        </div>
      </DropFieldActions>
    </Drop>
  );
}

export function SignatureWithDecorator({
  label,
  error,
  ...props
}: SignatureWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <Signature {...props} error={!!error} label={label} />
    </InputDecorator>
  );
}

export function UBTFormSignature({
  field,
  value,
  error,
  isVisible,
}: UBTFormSignatureViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <SignatureWithDecorator
        label={field.name}
        value={value}
        error={error}
        loading={!!value?.loading}
        options={field.options}
      />
    </FieldContainer>
  );
}

export function FormSignature({ field }: FormSignatureViewProps) {
  const { ref, value, onChange, error, label, loading } =
    useFormMediaFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <SignatureWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        loading={loading}
        error={error}
        options={field.options}
      />
    </FieldContainer>
  );
}

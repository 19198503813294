import { ImagesContainer, Image } from './styles';
import { useImagesRulesController } from './controller';

import wrongFace1 from './images/img.png';
import wrongFace2 from './images/img_1.png';
import rightFace1 from './images/img_2.png';
import rightDocument1 from './images/img_3.png';
import rightDocument2 from './images/img_4.png';
import wrongDocument1 from './images/img_5.png';

export function ImagesRules() {
  const { isDocument, isFace } = useImagesRulesController();

  return (
    <ImagesContainer>
      {isFace && (
        <>
          <Image imageUrl={wrongFace1} />
          <Image imageUrl={wrongFace2} />
          <Image imageUrl={rightFace1} />
        </>
      )}
      {isDocument && (
        <>
          <Image imageUrl={rightDocument1} />
          <Image imageUrl={rightDocument2} />
          <Image imageUrl={wrongDocument1} />
        </>
      )}
    </ImagesContainer>
  );
}

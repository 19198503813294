import MaskedInput from 'react-maskedinput'
import { Icon } from 'UIComponents'
import { InputContainer } from '../../styles'
import { StyledTextInputIconWrapper } from '../../../TextInput/styles'
import { useCustomInputController } from './controller'
import { CustomInputViewProps } from './types'

export function CustomInput({
  showTimeSelect,
  ...props
}: CustomInputViewProps) {
  const { mask, placeholder } = useCustomInputController(showTimeSelect)
  
  return (
    <InputContainer>
      <MaskedInput
        {...props}
        mask={mask}
        placeholder={placeholder}
        autoComplete="off"
      />
      <StyledTextInputIconWrapper>
        <Icon glyph="calendar" color="input-focus-border" size={16} />
      </StyledTextInputIconWrapper>
    </InputContainer>
  )
}

import { useLanguageContext } from 'Context';
import { useMemo } from 'react';
import { THEME_COLORS } from 'UIShared';
import { DefaultViewProps } from '../types';

export function useDefaultController(
  error: DefaultViewProps['error'],
  success: DefaultViewProps['success'],
  isRequired: DefaultViewProps['isRequired']
) {
  const language = useLanguageContext();

  const color: keyof THEME_COLORS = useMemo(() => {
    if (error) {
      return 'error-text-color';
    } else if (success) {
      return 'success-text-color';
    }
    return 'input-border';
  }, [error, success]);

  const text = useMemo(
    () =>
      language?.getTranslate(
        isRequired ? 'ui.support.required' : 'ui.support.optional'
      ),
    [isRequired, language]
  );

  return { color, text };
}

import { Text } from '../../../../../Text';
import { useDefaultController } from './controller';
import { Container } from './styles';
import { DefaultViewProps } from './types';

export function Default({ error, success, isRequired }: DefaultViewProps) {
  const { color, text } = useDefaultController(error, success, isRequired);

  return (
    <Container>
      <Text size={8} text={text} color={color} weight="Medium" />
    </Container>
  );
}

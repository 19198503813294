import styled from '@emotion/styled';
import {
  getColorByParams,
  getBoxShadowColorByParams,
  getBorderColorByParams,
} from '../TextInput/utils';
import { EmotionWrapperViewProps } from './types';
import { getColor, getFont } from 'UIUtils';

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Wrapper = styled.div<EmotionWrapperViewProps>`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__input-container {
    input {
      box-sizing: border-box;
      max-width: 100%;
      position: relative;
      display: block;
      width: inherit;
      border: 1px solid ${({ error }) => getBorderColorByParams(error)};
      border-radius: 4px;
      color: ${({ error }) => getColorByParams(error)};
      outline: none;
      font-family: ${getFont('primary')};
      font-style: normal;
      font-weight: normal;
      line-height: 1.17;
      font-size: 14px;
      padding: 6px 40px 6px 12px;
      background: transparent;
      z-index: 2;
      box-shadow: 0px 0px 5px 1px
        ${({ error }) => getBoxShadowColorByParams(error)};

      @media only screen and (max-width: 1024px) {
        font-size: 16px;
      }

      &:disabled {
        background-color: ${getColor('disabled-input-background')};
        opacity: 0.6;
        cursor: not-allowed;
        &::placeholder {
          color: ${getColor('disabled-input-background')};
        }
      }

      &::placeholder {
        color: ${getColor('placeholder-text-color')};
      }

      ${({ error }) =>
        !error &&
        `
          &:focus {
            border-color: ${getColor('input-focus-border')};
            box-shadow: 0px 0px 5px 1px ${getColor('input-outline')};
          }
        `}
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day-names {
    display: none;
  }

  .react-datepicker__day--disabled {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      background: none !important;
      color: ${getColor('primary-text-color')}!important;
    }
  }

  .react-datepicker__header {
    background: ${getColor('white')};
    padding: 15px 10px;
    border: none;
    font-size: 14px;
    font-family: ${getFont('primary')};
    font-weight: normal;
  }

  .react-datepicker__time-container {
    border-left: 1px solid var(--color-secondary-deep-5);
  }
  .react-datepicker-time__header {
    background: ${getColor('white')};
    font-size: 14px;
    font-family: Lato;
    font-weight: normal;
  }

  .react-datepicker {
    border: 1px solid ${getColor('input-outline')};
    box-shadow: 0 0 5px 1px ${getColor('input-outline')};
    border-radius: 4px;
    display: flex;
  }

  .react-datepicker__current-month {
    font-size: 14px;
    font-family: ${getFont('primary')};
    font-weight: normal;
  }

  .react-datepicker__day--outside-month {
    pointer-events: none;
    opacity: 0.3;
  }

  .react-datepicker__day--today {
    font-weight: 400;
  }

  .react-datepicker__month,
  .react-datepicker__year-text,
  .react-datepicker__day,
  .react-datepicker__time-list-item {
    font-family: ${getFont('primary')};

    color: ${getColor('note-text-color')};

    &--selected {
      background: ${getColor('input-focus-background')} !important;
      color: ${getColor('white')} !important;

      &:hover {
        background: ${getColor('input-focus-background')}!important;
      }
    }

    &--keyboard-selected {
      background-color: ${getColor('input-focus-border')};
      color: ${getColor('white')};
    }

    &:focus {
      outline: none;
    }
  }

  .react-datepicker__day {
    &:hover {
      background: ${getColor('input-outline')};
      color: ${getColor('white')};
    }
  }

  .react-datepicker__month {
    margin: 12px;
  }

  .react-datepicker__navigation {
    display: ${({ showMonthYearPicker }) =>
      showMonthYearPicker ? 'none' : 'block'};
    border: 0.45rem solid transparent;
    z-index: 1;
    top: 16px;
    height: 10px;
    width: 10px;

    &:focus {
      outline: none;
    }

    &--next {
      right: 12px;
      border-left-color: ${getColor('input-outline')};
    }

    &--previous {
      left: 12px;
      border-right-color: ${getColor('input-outline')};
    }
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      display: ${({ showMonthYearPicker }) =>
        showMonthYearPicker ? 'none' : 'block'};
    }

    .react-datepicker__month {
      text-transform: uppercase;
      &-text {
        padding: 6px 0px;

        &--keyboard-selected {
          background-color: ${getColor('input-focus-border')};
        }

        &:active {
          outline: none;
        }
      }
    }
  }

  .react-datepicker__year--container {
    width: 220px;

    .react-datepicker__year-wrapper {
      max-width: 300px !important;
    }

    .react-datepicker__year-text {
      text-transform: uppercase;

      padding: 6px 0px;

      &--keyboard-selected {
        background-color: ${getColor('input-focus-border')};
      }

      &:active {
        outline: none;
      }
    }
  }
`;

import { InputDecorator } from '../InputDecorator';
import {
  AddressInputViewProps,
  AddressInputWithDecoratorViewProps,
  FormAddressInputViewProps,
  UBTFormAddressInputViewProps,
} from './types';
import {
  StyledInput,
  StyledInputContainer,
  MenuContainer,
  OptionContainer,
  EmptyContainer,
  IconWrapper,
} from './styles';
import { useInputController } from './controller';
import { Icon, Text } from 'UIComponents';
import { MouseEvent, MutableRefObject, useCallback } from 'react';
import { FieldContainer } from '../FieldContainer';
import { useFormFieldController } from 'Hooks';
import { SelectOption } from '../Select/types';

function RenderItem({
  el,
  onSelectInput,
}: {
  el: SelectOption;
  onSelectInput: MutableRefObject<(el: SelectOption) => Promise<void>>;
}) {
  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onSelectInput.current(el);
    },
    [onSelectInput, el]
  );
  return (
    <OptionContainer onClick={onClick}>
      <Text text={el.label} size={12} />
    </OptionContainer>
  );
}

export function AddressInput({
  onChange,
  value,
  placeholder = 'Start typing the address',
  error,
  field,
}: AddressInputViewProps) {
  const {
    opened,
    onChangeInput,
    onFocusInput,
    onBlurInput,
    data,
    onSelectInput,
    loading,
    refMenu,
    refInput,
    isLoading,
  } = useInputController(field, value, onChange);

  const renderOption = useCallback(
    (el: SelectOption) => (
      <RenderItem key={el.value} el={el} onSelectInput={onSelectInput} />
    ),
    [onSelectInput]
  );

  return (
    <StyledInputContainer>
      <StyledInput
        ref={refInput}
        error={error}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChangeInput}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
      />
      <IconWrapper>
        <Icon glyph={isLoading ? 'spinner' : 'search'} size={12} />
      </IconWrapper>
      {opened && (
        <MenuContainer ref={refMenu}>
          {loading || !data ? (
            <EmptyContainer>
              <Icon glyph="spinner" size={12} />
            </EmptyContainer>
          ) : data?.length ? (
            data?.map(renderOption)
          ) : (
            <EmptyContainer>
              <Text text="No options" size={18} />
            </EmptyContainer>
          )}
        </MenuContainer>
      )}
    </StyledInputContainer>
  );
}

export function AddressInputWithDecorator({
  label,
  error,
  ...props
}: AddressInputWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <AddressInput error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormAddressInput({
  field,
  value,
  isVisible,
  error,
}: UBTFormAddressInputViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <AddressInputWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || undefined}
      />
    </FieldContainer>
  );
}

export function FormAddressInput({ field }: FormAddressInputViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <AddressInputWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        field={field}
        placeholder={field.options?.placeholder || undefined}
      />
    </FieldContainer>
  );
}

import { PropsWithChildren } from 'react';
import { useFormMediaFieldController } from 'Hooks';
import { FieldContainer } from '../FieldContainer';
import { InputDecorator } from '../InputDecorator';
import { Field } from './components';
import {
  VerificationWithDecoratorViewProps,
  VerificationViewProps,
  VerificationFieldViewProps,
  UBTVerificationViewProps,
} from './types';
import { FieldContext } from './context';

function VerificationField({
  type,
  ...props
}: PropsWithChildren<VerificationFieldViewProps>) {
  return (
    <FieldContext.Provider value={props}>
      <Field type={type} />
    </FieldContext.Provider>
  );
}

function VerificationWithDecorator({
  label,
  error,
  children,
  ...props
}: PropsWithChildren<VerificationWithDecoratorViewProps>) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <VerificationField {...props} error={!!error} />
    </InputDecorator>
  );
}

export function UBTFormVerification({
  value,
  type,
  isVisible,
  field,
  error,
}: UBTVerificationViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <VerificationWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        type={type}
      />
    </FieldContainer>
  );
}

export function Verification({ field, type }: VerificationViewProps) {
  const { ref, value, onChange, error, label } =
    useFormMediaFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <VerificationWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        type={type}
      />
    </FieldContainer>
  );
}

import { Text } from 'UIComponents';
import { ButtonContentContainer, NextButton } from '../../shared.styles';
import {
  InitContainer,
  TextContainer,
  ContentContainer,
  IconContainer,
  ActionsContainer,
} from './styles';
import { useInitController } from './controller';

export function Init() {
  const { changeView, title, description, Icon } = useInitController();

  return (
    <InitContainer>
      <ContentContainer>
        <IconContainer>
          <Icon />
        </IconContainer>
        <TextContainer>
          <Text
            text={title}
            uppercase
            weight="Bold"
            color="media-primary-text-color"
            size={14}
            align="center"
          />
          <Text
            text={description}
            weight="Thin"
            color="media-secondary-text-color"
            size={11}
            align="center"
          />
        </TextContainer>
      </ContentContainer>
      <ActionsContainer>
        <NextButton onClick={changeView}>
          <ButtonContentContainer>
            Let’s get you verified
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.62094 1.2002L3.7793 2.05522L7.68867 6.0002L3.7793 9.95835L4.62094 10.8002L9.42094 6.0002L4.62094 1.2002Z"
                fill="currentColor"
              />
            </svg>
          </ButtonContentContainer>
        </NextButton>
      </ActionsContainer>
    </InitContainer>
  );
}
import { Icon, Text } from 'UIComponents';
import { getFileSize } from '../../../../../forms/components/Templates/Builder/UI/MediaFields/components/Document/components/Field/components/Value/utils';
import { MediaValueViewProps } from './types';
import {
  ValueContainer,
  DeleteButton,
  ContentContainer,
  TextContainer,
} from './styles';

export function MediaValue({ value, type, handleRemove }: MediaValueViewProps) {
  if (value?.loading) {
    return (
      <ValueContainer>
        <Text
          color="media-secondary-text-color"
          text={`${type} is loading:`}
          size={14}
          weight="Medium"
        />
        <ContentContainer>
          <TextContainer>
            <Text
              text={value?.file_name || '...'}
              size={12}
              weight="Medium"
              color="active-text-color"
            />
            <Text
              text={getFileSize(value?.file_size?.toString() || '0')}
              weight="Thin"
              size={10}
              color="note-text-color"
            />
          </TextContainer>
          <Icon glyph="spinner" />
        </ContentContainer>
      </ValueContainer>
    );
  }
  return (
    <ValueContainer>
      <Text
        color="media-secondary-text-color"
        text={`${type} has been uploaded:`}
        size={14}
        weight="Medium"
      />
      <ContentContainer>
        <TextContainer>
          <Text
            text={value?.file_name || 'File'}
            size={12}
            weight="Medium"
            color="active-text-color"
          />
          <Text
            text={getFileSize(value?.file_size?.toString() || '0')}
            weight="Thin"
            size={10}
            color="note-text-color"
          />
        </TextContainer>
        <DeleteButton onClick={handleRemove}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_6426_5147)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.46466 0H7.53515C7.96706 0 8.31843 0.351375 8.31843 0.783281V1.80157H11.5926V2.74151H10.5973L9.82031 11.5711C9.79917 11.8137 9.59583 12 9.35222 12H2.63175C2.38816 12 2.18496 11.8139 2.16366 11.5712L1.38666 2.74151H0.407227V1.80157H3.68138V0.783281C3.68138 0.351375 4.03276 0 4.46466 0ZM4.62132 1.80157H7.3785V0.939937H4.62132V1.80157ZM8.92174 11.0601H3.06211L2.3302 2.74151H9.65381L8.92174 11.0601ZM4.65218 4.10271L4.85583 9.66407L3.91652 9.69852L3.71285 4.13718L4.65218 4.10271ZM8.2868 4.13733L7.34747 4.10287L7.14383 9.66422L8.08315 9.69853L8.2868 4.13733ZM6.46987 4.1201H5.52993V9.68148H6.46987V4.1201Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_6426_5147">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </DeleteButton>
      </ContentContainer>
    </ValueContainer>
  );
}

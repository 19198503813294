import styled from '@emotion/styled';
import { getColor } from 'UIUtils';
import { EmotionIconContainerProps, EmotionSvgProps } from './types';

export const IconContainer = styled.div<EmotionIconContainerProps>`
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  cursor: ${({ onClick }) => (typeof onClick === 'function' ? 'pointer' : '')};
  ${({ size }) => `
      width: ${size || 16}px;
      height: ${size || 16}px;
    `}
  ${({ outline, color }) =>
    outline
      ? `
    border-radius: 50%;
    border: ${getColor(color || 'primary-text-color')} 1px solid;
    padding: 8px;
  `
      : ''}
`;

export const Svg = styled.svg<EmotionSvgProps>`
  color: ${({ color }) => getColor(color || 'primary-text-color')};
  ${({ fill }) => (fill ? `fill: ${fill};` : '')}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ hoverable, disabled }) =>
    hoverable && !disabled ? 'pointer' : ''};
`;

import styled from '@emotion/styled';
import { Styles } from 'react-modal';
import { Icon } from '../Icon';

export const styles: Styles = {
  content: {
    background: 'white',
    borderRadius: '8px',
    padding: '32px',
    border: 'none',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
    width: 'max-content',
    position: 'static',
    justifyContent: 'center',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    bottom: '0',
    zIndex: 30,
  },
};

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;

  & > button {
    flex: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 20px;
`;

export const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  margin: auto;
  text-align: center;
  max-width: 380px;
`;

export const ModalContainer = styled.div`
  @media only screen and (max-width: 1024px) {
    max-width: 60vw;
  }
  @media only screen and (min-width: 1024px) {
    min-width: 370px;
  }
`;

import { PropsWithChildren, useMemo } from 'react';
import {
  UploadIcon,
  PlaceholderLabel,
  PlaceholderDescription,
  StyledButton,
  DefaultContainer,
} from './styles';
import { DefaultViewProps } from './types';

export function Default({
  handlerButtonClick,
  textOptions,
  liveOnly,
  children,
}: PropsWithChildren<DefaultViewProps>) {
  const currentLabel = useMemo(
    () => (liveOnly ? textOptions.liveOnlyLabel : textOptions.label),
    [textOptions.label, liveOnly, textOptions.liveOnlyLabel]
  );

  return (
    <DefaultContainer>
      <UploadIcon glyph="upload" color="input-focus-border" size={14} />
      <PlaceholderLabel text={currentLabel} />
      {!liveOnly && (
        <>
          <PlaceholderDescription text={textOptions.description} size={11} />
          <StyledButton onClick={handlerButtonClick}>
            {textOptions.actionLabel}
          </StyledButton>
        </>
      )}
      {children}
    </DefaultContainer>
  );
}

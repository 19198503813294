import styled from '@emotion/styled/macro';
import { Text } from 'UIComponents';
import { getColor } from 'UIUtils';
import {
  EmotionCheckboxWrapperViewProps,
  EmotionCheckmarkViewProps,
} from './types';

export const Label = styled(Text)`
  padding-left: 25px;
  white-space: pre-line;
  line-break: normal;
  padding-top: 2px;
`;

export const Checkmark = styled.span<EmotionCheckmarkViewProps>`
  position: absolute;
  height: 16px;
  width: 16px;
  background: ${({ disabled }) =>
    getColor(disabled ? 'disabled-input-background' : 'white')};
  border: ${({ error }) =>
    `1px solid ${getColor(error ? 'error-text-color' : 'input-border')}`};
  box-sizing: border-box;
  box-shadow: ${({ disabled }) =>
    disabled ? 'none' : `1px 1px 0px ${getColor('disabled-input-background')}`};
  border-radius: 4px;
  top: -8px;

  ${({ disabled }) => (disabled ? 'opacity: 0.6;' : '')}

  &:after {
    content: '';
    position: absolute;
    display: none;
    box-sizing: border-box;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 9px;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: ${({ disabled }) =>
      getColor(disabled ? 'note-text-color' : 'white')};
    transform: rotate(45deg);
  }

  &:before {
    content: '';
    position: absolute;
    display: none;
    box-sizing: border-box;
    top: 7px;
    left: 3px;
    width: 8px;
    height: 1px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: ${({ disabled }) =>
      getColor(disabled ? 'note-text-color' : 'white')};
  }
`;

export const CheckboxWrapper = styled.label<EmotionCheckboxWrapperViewProps>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 22px;
  user-select: none;
  padding: 5px 5px;
  border-radius: 4px;
  margin: 0 -5px 0;

  :hover {
    background: ${({ label, disabled }) =>
      !disabled && label
        ? getColor('disabled-input-background')
        : 'transparent'};
  }
`;

export const ContainerCheckmark = styled.div`
  position: relative;
`;

export const ContainerCheckbox = styled.div`
  margin-bottom: 20px;
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${ContainerCheckmark} {
    ${Checkmark} {
      border: none;
      background: ${getColor('input-focus-background')};
      &:after {
        display: block;
      }
    }
  }

  &:indeterminate ~ ${ContainerCheckmark} {
    ${Checkmark} {
      border: none;
      background: ${getColor('input-focus-background')};
      &:before {
        display: block;
      }
    }
  }
`;

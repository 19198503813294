import { AIDFormService } from 'aid-form-service/lib';
import { createContext, useContext } from 'react';

export const FormContext = createContext<AIDFormService>({} as any);

export const LanguageContext = createContext<AIDFormService['language']>(
  {} as any
);

export function useFormContext() {
  return useContext(FormContext);
}

export function useLanguageContext() {
  return useContext(LanguageContext);
}

import { useLanguageContext } from 'Context';
import { useDropFieldProps } from 'Hooks';
import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { TYPE_FILE_UPLOAD_CLAIM } from '../../../../AnotherUpload/controller/constants';
import { DocumentFieldViewProps } from '../types';

function checkMimeType(type: string) {
  if (/image\/.*/gm.test(type)) {
    return true;
  }
  switch (type) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'text/plain':
    case 'application/pdf':
      return true;
    default:
      return false;
  }
}

export function useDocumentController(
  value: DocumentFieldViewProps['value'],
  onChange: DocumentFieldViewProps['onChange'],
  typeUploads: DocumentFieldViewProps['typeUploads'],
  accept: DocumentFieldViewProps['accept']
) {
  const inputFile = useRef<HTMLInputElement>(null);
  const [anotherUpload, setAnotherUpload] = useState<string | false>(false);
  const language = useLanguageContext();

  const handleUpload = useCallback(
    async (files: any) => {
      try {
        await onChange?.(files[0].file);
      } catch (e) {}
    },
    [onChange]
  );

  const toggleAnotherUpload = useCallback(
    (type?: string) => (e: MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      setAnotherUpload(type || false);
    },
    []
  );

  const reduceFiles = useCallback(
    (propsFiles: File[]) =>
      propsFiles.reduce<{
        files: any[];
        bigSizeFileLength: number;
        hasTypeError: number;
      }>(
        (acc, file) => {
          const canUseFile = checkMimeType(file.type);
          if (!canUseFile) {
            return { ...acc, hasTypeError: ++acc.hasTypeError };
          }
          if (file?.size > 15728640) {
            return { ...acc, bigSizeFileLength: ++acc.bigSizeFileLength };
          }
          return {
            ...acc,
            files: [
              ...acc.files,
              {
                file,
                file_name: file.name,
                file_size: file.size,
                loading: true,
                uploaded: false,
              } as any,
            ],
          };
        },
        { files: [], bigSizeFileLength: 0, hasTypeError: 0 }
      ),
    []
  );

  const onDropAccepted = useCallback(
    (propsFiles) => {
      const { files } = reduceFiles(propsFiles);
      return handleUpload(files);
    },
    [handleUpload, reduceFiles]
  );

  const unionAccept =
    accept ||
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/pdf';

  let { onSetRejected, ...dropFieldProps } = useDropFieldProps(false, {
    accept: unionAccept,
    maxSize: 15728640,
    onDropAccepted,
    maxFiles: 1,
  });

  const handleInputChange = useCallback(
    (e) => {
      e.preventDefault();
      const { files, bigSizeFileLength, hasTypeError } = reduceFiles(
        Array.from(e.target.files)
      );

      if (hasTypeError) {
        onSetRejected('file-invalid-type');
      }
      if (bigSizeFileLength) {
        onSetRejected('file-too-large');
      }
      if (files.length > 0) {
        return handleUpload(files);
      }
    },
    [reduceFiles, onSetRejected, handleUpload]
  );

  const handlerButtonClick = useCallback((e) => {
    e.preventDefault();
    if (inputFile.current) {
      inputFile.current.click();
    }
  }, []);

  const handlerRemove = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onChange?.(undefined);
      if (inputFile.current) {
        inputFile.current.value = '';
      }
    },
    [onChange]
  );

  const inputProps = useMemo(
    () => dropFieldProps.getInputProps(),
    [dropFieldProps]
  );

  const isAnotherEmail = useMemo(
    () =>
      typeUploads?.find(
        (type: string) => type === TYPE_FILE_UPLOAD_CLAIM.EMAIL
      ),
    [typeUploads]
  );

  const isAnotherQR = useMemo(
    () =>
      typeUploads?.find((type: string) => type === TYPE_FILE_UPLOAD_CLAIM.QR),
    [typeUploads]
  );

  const textOptions = useMemo(
    () => ({
      label: language?.getTranslate('ui.media.attach.label', {
        arg: 'file',
      }),
      description: language?.getTranslate('ui.media.attach.description', {
        type: 'file',
        int: 15,
      }),
      actionLabel: language?.getTranslate('ui.media.browse_files'),
      anotherLabel: language?.getTranslate('ui.media.another_device.label', {
        arg: 'file',
      }),
    }),
    [language]
  );

  return {
    anotherUpload,
    toggleAnotherUpload,
    dropFieldProps,
    inputProps,
    inputFile,
    handleInputChange,
    handlerButtonClick,
    handlerRemove,
    isAnotherQR,
    isAnotherEmail,
    textOptions,
    unionAccept,
  };
}

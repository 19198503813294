import {
  FormCardInputViewProps,
  ICardInputProps,
  ICardInputWithDecoratorProps,
  UBTFormCardInputViewProps,
} from './types';
import { TextInput } from '../TextInput';
import { InputDecorator } from '../InputDecorator';
import { useCardInputController } from './controller';
import { useFormFieldController } from 'Hooks';
import { FieldContainer } from '../FieldContainer';

export function CardInput({
  value,
  onChange,
  error,
  ...props
}: ICardInputProps) {
  const { fieldValue, onChangeFieldValue, onKeyPress } = useCardInputController(
    value,
    onChange
  );
  return (
    <TextInput
      {...props}
      value={fieldValue}
      onChange={onChangeFieldValue}
      onKeyPress={onKeyPress}
      error={error}
    />
  );
}

export function CardInputWithDecorator({
  label,
  error,
  ...props
}: ICardInputWithDecoratorProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <CardInput error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormCardInput({
  field,
  value,
  error,
  isVisible,
}: UBTFormCardInputViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <CardInputWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || undefined}
      />
    </FieldContainer>
  );
}

export function FormCardInput({ field }: FormCardInputViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <CardInputWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || undefined}
      />
    </FieldContainer>
  );
}

import { useCallback, useEffect, useRef } from 'react';

export function useSubscription() {
  const ref = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      ref.current && clearInterval(ref.current);
    };
  }, []);

  const clear = useCallback(() => {
    if (ref.current) {
      clearInterval(ref.current);
    }
  }, []);

  const subscribe = useCallback((callback) => {
    if (ref.current) {
      clearInterval(ref.current);
    }
    ref.current = setInterval(async () => {
      await callback();
    }, 5000);
  }, []);

  return { subscribe, clear };
}

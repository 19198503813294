import { CustomUI } from 'aid-form-service/lib/ApiClient/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { THEME_COLORS } from 'UIShared';
import { ThemeType } from '../theme/types';
import {
  AID_COLORS,
  ARIVAL_COLORS,
  BFM_COLORS,
  STANDARD_COLORS,
} from './constants';

function getColor(color?: string) {
  if (color) {
    if (color.indexOf('#') === 0) {
      return color;
    }
    return `#${color}`;
  }
}

function generateCustomTheme(
  settings: CustomUI['settings'],
  currentColors: THEME_COLORS
): THEME_COLORS {
  return {
    'primary-text-color':
      getColor(settings?.mainColor) || currentColors['primary-text-color'],
    'note-text-color':
      getColor(settings?.accentColor) || currentColors['note-text-color'],
    'active-text-color': currentColors['active-text-color'],
    'powered-text-color': currentColors['powered-text-color'],
    'default-text-color': currentColors['default-text-color'],
    'default-state-color': currentColors['default-state-color'],
    'error-text-color':
      getColor(settings?.errorColor) || currentColors['error-text-color'],
    'warning-text-color':
      getColor(settings?.warningColor) || currentColors['warning-text-color'],
    'placeholder-text-color':
      getColor(settings?.accentColor) ||
      currentColors['placeholder-text-color'],
    'success-text-color':
      getColor(settings?.successColor) || currentColors['success-text-color'],
    'submit-button-text-color':
      getColor(settings?.accentColor) ||
      currentColors['submit-button-text-color'],
    'secondary-button-border-color':
      getColor(settings?.accentColor) ||
      currentColors['secondary-button-border-color'],
    'secondary-button-background-color':
      getColor(settings?.accentColor) ||
      currentColors['secondary-button-background-color'],

    'input-border':
      getColor(settings?.mainColor) || currentColors['input-border'],
    'input-outline':
      getColor(settings?.accentColor) || currentColors['input-outline'],
    'input-outline-secondary':
      getColor(settings?.accentColor) || currentColors['input-outline'],

    'input-focus-border':
      getColor(settings?.mainColor) || currentColors['input-focus-border'],
    'input-focus-background':
      getColor(settings?.mainColor) || currentColors['input-focus-background'],
    'input-focus-background-reverse':
      getColor(settings?.mainColor) ||
      currentColors['input-focus-background-reverse'],

    'disabled-input-background':
      getColor(settings?.accentColor) ||
      currentColors['disabled-input-background'],
    'media-primary-text-color':
      getColor(settings?.mainColor) ||
      currentColors['media-primary-text-color'],
    'media-secondary-text-color':
      getColor(settings?.accentColor) ||
      currentColors['media-secondary-text-color'],

    'scrollbar-background':
      getColor(settings?.accentColor) || currentColors['scrollbar-background'],

    background: getColor(settings?.backgroundColor) || currentColors['white'],

    white: '#fff' || currentColors['white'],
  };
}

export function generateDefaultColors(
  type: Exclude<ThemeType, ThemeType.ONBOARDING | ThemeType.EZ>
) {
  switch (type) {
    case ThemeType.STANDARD:
      return STANDARD_COLORS;
    case ThemeType.BFM:
      return BFM_COLORS;
    case ThemeType.AID:
      return AID_COLORS;
    case ThemeType.ARIVAL:
      return ARIVAL_COLORS;
    case ThemeType.SYSTEM:
      return STANDARD_COLORS;
  }
}

export function generateColors(
  type: Exclude<ThemeType, ThemeType.ONBOARDING | ThemeType.EZ>,
  customUI: Maybe<CustomUI>
) {
  const currentColors = generateDefaultColors(type);
  if (customUI?.enabled) {
    return generateCustomTheme(customUI.settings, currentColors);
  }
  return currentColors;
}

import { DescriptionAlign, DescriptionSize } from 'aid-form-service';
import { useFormFieldController } from 'Hooks';
import { useMemo } from 'react';
import { getUrl } from 'UIUtils';
import { FieldContainer } from '../FieldContainer';
import { Container, StyledButton } from './styles';
import {
  FormRedirectButtonViewProps,
  RedirectButtonViewProps,
  UBTFormRedirectButtonViewProps,
} from './types';

export function RedirectButton({
  label = 'Redirect button',
  openInNewTab,
  size = DescriptionSize.SMALL,
  align = DescriptionAlign.LEFT,
  url,
}: RedirectButtonViewProps) {
  const href = useMemo(() => getUrl(url), [url]);

  return (
    <Container size={size} align={align}>
      <a target={openInNewTab ? '_blank' : ''} href={href} rel="noreferrer">
        <StyledButton>{label}</StyledButton>
      </a>
    </Container>
  );
}

export function UBTFormRedirectButton({
  field,
  isVisible,
}: UBTFormRedirectButtonViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <RedirectButton
        label={field.options?.actions?.text || undefined}
        openInNewTab={field.options?.actions?.openInNewTab || undefined}
        size={field.options?.actions?.size || undefined}
        align={field.options?.actions?.align || undefined}
        url={field.options?.actions?.url || undefined}
      />
    </FieldContainer>
  );
}

export function FormRedirectButton({ field }: FormRedirectButtonViewProps) {
  const { ref } = useFormFieldController(field);
  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <RedirectButton
        label={field.options?.actions?.text || undefined}
        openInNewTab={field.options?.actions?.openInNewTab || undefined}
        size={field.options?.actions?.size || undefined}
        align={field.options?.actions?.align || undefined}
        url={field.options?.actions?.url || undefined}
      />
    </FieldContainer>
  );
}

import styled from '@emotion/styled';
import { getColor, getFont } from 'UIUtils';

export const ToggleForCollapseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${getFont('primary')};
`;

export const IconWrapper = styled.div<{ open: boolean }>`
  border: 1px solid ${getColor('input-border')};
  border-radius: 50%;
  padding: 10px;
  line-height: 0;
  cursor: pointer;
  margin-left: 12px;

  ${({ open }) =>
    !open &&
    `
    transform: rotate(180deg);
  `}

  &:hover {
    border-color: ${getColor('input-focus-border')};
    box-shadow: 4px 4px 4px ${getColor('disabled-input-background')};

    & svg {
      color: ${getColor('input-focus-border')};
    }
  }
`;

export const LabelWrapper = styled.div`
  margin-left: 12px;
  line-height: 0;
`;

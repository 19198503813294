import styled from '@emotion/styled';
import { getColor } from 'UIUtils';

export const CheckContainer = styled.div`
  display: grid;
  grid-template-rows: 18px 1fr max-content;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  background: ${getColor('white')};
`;

export const Image = styled.div<{ imageUrl?: string }>`
  margin-left: auto;
  margin-right: auto;
  height: 158px;
  width: 100%;
  border-radius: 4px;
  background: url('${({ imageUrl }) => imageUrl}') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

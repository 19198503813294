import React from 'react';
import { Icon, Text } from 'UIComponents';

import { IconWrapper, ToggleForCollapseWrapper, LabelWrapper } from './styles';
import { ToggleForCollapseViewProps } from './types';

export function ToggleForCollapse({
  label,
  open,
  onChange,
}: ToggleForCollapseViewProps) {
  return (
    <ToggleForCollapseWrapper>
      {label && (
        <LabelWrapper>
          <Text text={label} size={12} />
        </LabelWrapper>
      )}
      <IconWrapper onClick={onChange} open={open}>
        <Icon glyph="arrowUp" color="input-border" size={9} />
      </IconWrapper>
    </ToggleForCollapseWrapper>
  );
}

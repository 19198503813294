import { css } from '@emotion/react';
import { generateImages } from './utils';
import { CustomUI } from 'aid-form-service/lib/ApiClient/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { ThemeType } from 'UIShared';

export const IMAGE_PREFIX = '--image-';

export function getAppImages(theme: ThemeType, customUI: Maybe<CustomUI>, apiHost: string) {
  return css(`
  ${Object.entries(generateImages(theme, customUI, apiHost)).reduce(
    (acc, [key, value]) => `${acc}
      ${IMAGE_PREFIX}${key}: ${value || 'none'};`,
    ''
  )}
`);
}

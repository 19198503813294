import { defer } from '@synvox/api';
import { useApiContext } from 'Context';
import { PropsWithChildren, useMemo } from 'react';
import { Error403 } from 'GlobalErrors';
import { GlobalLoader } from 'UIComponents';
import { UserContext } from './context';

export function AuthContainer({ children }: PropsWithChildren<{}>) {
  const api = useApiContext();
  const { data, loading } = defer(() => api.core.accounts.me());

  const haveActiveClient = useMemo(() => {
    return !!(data as any)?.data?.accounts_clients?.length;
  }, [data]);

  if (loading) {
    return <GlobalLoader />;
  }

  if (haveActiveClient) {
    return (
      <UserContext.Provider value={(data as any)?.data}>
        {children}
      </UserContext.Provider>
    );
  }

  return <Error403 />;
}

import { StyledButton } from './styles';
import { PropsWithChildren } from 'react';
import { ButtonType, ButtonViewProps } from './types';

export function Button({
  children,
  disabled,
  buttonType = ButtonType.FILLED,
  ...props
}: PropsWithChildren<ButtonViewProps>) {
  return (
    <StyledButton disabled={disabled} buttonType={buttonType} {...props}>
      {children}
    </StyledButton>
  );
}

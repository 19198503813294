import { useCallback, useEffect, useRef, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

export function useDropFieldProps(
  liveOnly: boolean,
  options?: DropzoneOptions | undefined
) {
  let { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      ...options,
    });
  const timeOutRef = useRef<NodeJS.Timeout>();
  const [rejected, setRejected] = useState<string | boolean>(false);

  const onSetRejected = useCallback((code) => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(() => {
      setRejected(false);
      timeOutRef.current = undefined;
    }, 3000);
    setRejected(code);
  }, []);

  useEffect(() => {
    if (fileRejections) {
      setRejected((prev) => {
        if (fileRejections.length > 0 && fileRejections[0]?.errors[0]?.code) {
          if (timeOutRef.current) {
            clearTimeout(timeOutRef.current);
          }
          timeOutRef.current = setTimeout(() => {
            setRejected(false);
            timeOutRef.current = undefined;
          }, 3000);
          return fileRejections[0].errors[0].code;
        } else if (fileRejections.length === 0 && prev) {
          if (timeOutRef.current) {
            clearTimeout(timeOutRef.current);
          }
          return !prev;
        }
        return prev;
      });
    }
  }, [fileRejections]);

  useEffect(() => {
    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, []);

  return liveOnly
    ? {
        getRootProps: () => {},
        getInputProps: () => {},
        isDragActive: false,
        rejected: false,
        onSetRejected: () => {},
      }
    : {
        getRootProps,
        getInputProps,
        isDragActive,
        rejected,
        onSetRejected,
      };
}

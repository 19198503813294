import { isRepeatableItem } from 'aid-form-service';
import { FieldControllerDelegate } from 'aid-form-service/lib/State/StateControllers/FieldControllers/FieldController/index.delegate';
import { GroupControllerDelegate } from 'aid-form-service/lib/State/StateControllers/GroupsControllers/Group/index.delegate';
import { RepeatableGroupControllerDelegate } from 'aid-form-service/lib/State/StateControllers/GroupsControllers/Repeatable/Group/index.delegate';
import { RepeatableItemControllerDelegate } from 'aid-form-service/lib/State/StateControllers/GroupsControllers/Repeatable/RepeatableItem/index.delegate';
import { ScreenControllerDelegate } from 'aid-form-service/lib/State/StateControllers/ScreenController/index.delegate';
import { useEffect, useRef } from 'react';

export function useFormObjectController<T extends HTMLElement = HTMLDivElement>(
  object:
    | ScreenControllerDelegate
    | GroupControllerDelegate
    | RepeatableGroupControllerDelegate
    | FieldControllerDelegate
    | RepeatableItemControllerDelegate
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current && !isRepeatableItem(object)) {
      object.setNode?.(ref.current);
    }
    return () => {
      if (!isRepeatableItem(object)) {
        object.setNode?.(undefined);
      }
    };
  }, [object]);

  return { ref };
}

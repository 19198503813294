import styled from '@emotion/styled';
import { getColor } from 'UIUtils';

export const ValueContainer = styled.div`
  background: ${getColor('secondary-button-background-color')};
  border-radius: 6px;
  display: grid;
  grid-Template-columns: 1fr;
  grid-template-rows: max-content max-content;
  padding: 10px 12px;
  position: relative;
  overflow: hidden;
  gap: 6px;
`;

export const ContentContainer = styled.div`
  background: ${getColor('white')};
  border: 1px solid ${getColor('input-outline')};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr;
  padding: 6px 8px;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  align-items: center;
  gap: 2px;
`;

export const DeleteButton = styled.button`
  background: ${getColor('white')};
  color: ${getColor('error-text-color')};
  border: 1px solid ${getColor('error-text-color')};
  width: 22px;
  height: 22px;
  pointer-events: all;
  display: grid;
  grid-template-columns: 1fr;
  outline: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

import { Text } from 'UIComponents';
import { ActionsContainer, NextButton, PrevButton } from '../../shared.styles';
import { IntroContainer, ContentContainer, TextContainer } from './styles';
import { ImagesRules } from './components';
import { useIntroController } from './controller';

export function Intro() {
  const { changeView, title, description, isMobile } = useIntroController();

  return (
    <IntroContainer>
      <ContentContainer>
        <Text
          text={title}
          uppercase
          size={14}
          weight="Bold"
          color="media-primary-text-color"
          align="center"
        />
        <TextContainer>
          <Text
            text={description}
            align="center"
            size={11}
            weight="Thin"
            color="media-secondary-text-color"
          />
        </TextContainer>
        <ImagesRules />
      </ContentContainer>
      <ActionsContainer>
        <PrevButton onClick={(e) => changeView(e, 'init')}>
          Cancel
        </PrevButton>
        <NextButton onClick={(e) => changeView(e, 'camera')}>
          {isMobile ? 'Take a picture' : 'Next'}
        </NextButton>
      </ActionsContainer>
    </IntroContainer>
  );
}

import { CameraDevices } from './controller/types';

export interface EmotionCameraContainerViewProps {
  loading?: boolean;
}

export enum CameraType {
  VIDEO,
  IMAGE,
}

export interface CameraVideoViewProps {
  onComplete?: (blob: Blob) => void;
  isOnInitially?: boolean;
  onError: (error?: DOMException) => void;
  onEnumerateReady?: (devices: CameraDevices) => void;
  type: CameraType.VIDEO;
}

export interface CameraImageViewProps
  extends Pick<CameraVideoViewProps, 'onError' | 'onEnumerateReady'> {
  type: CameraType.IMAGE;
}

export type CameraViewProps = CameraVideoViewProps | CameraImageViewProps;

export interface CameraViewState {
  loading: boolean;
}

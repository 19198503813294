import { useFormFieldController } from 'Hooks';
import { useCallback } from 'react';
import { FieldContainer } from '../FieldContainer';
import { VerticalInputDecorator } from '../InputDecorator';
import { Checkmark, ContainerCheckmark, StyledInput } from './styles';
import {
  CheckboxViewProps,
  FormCheckboxViewProps,
  UBTFormCheckboxViewProps,
} from './types';

export function Checkbox({
  disabled,
  value,
  indeterminate,
  onChange,
  error,
  ...rest
}: CheckboxViewProps) {
  const handlerChange = useCallback(() => {
    if (!disabled) {
      onChange?.(!value);
    }
  }, [onChange, value, disabled]);

  return (
    <>
      <StyledInput
        type="checkbox"
        checked={value}
        ref={(el) => el && (el.indeterminate = !!indeterminate)}
        onChange={handlerChange}
        disabled={disabled}
        {...rest}
      />
      <ContainerCheckmark>
        <Checkmark error={error} disabled={disabled} />
      </ContainerCheckmark>
    </>
  );
}

export function UBTFormCheckbox({
  field,
  isVisible,
  value,
  error,
}: UBTFormCheckboxViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={isVisible}>
      <VerticalInputDecorator
        label={field.options?.description?.text || field.name}
        error={error}
        options={field.options}
        paddingLeft="25px"
      >
        <Checkbox value={value} error={error} />
      </VerticalInputDecorator>
    </FieldContainer>
  );
}

export function FormCheckbox({ field }: FormCheckboxViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <VerticalInputDecorator
        label={field.options?.description?.text || label}
        error={error}
        options={field.options}
        paddingLeft="25px"
      >
        <Checkbox value={value} onChange={onChange} error={error} />
      </VerticalInputDecorator>
    </FieldContainer>
  );
}

import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { UseFormParams } from './types';

export function useFormParams() {
  const { formId } = useParams<UseFormParams>();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  return {
    formId,
    submissionId:
      query.get('submission') || query.get('submission_id') || undefined,
  };
}

import { useMemo, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useVerificationFieldContext } from '../../../context';
import { FieldState } from '../../../types';

export function useIntroController() {
  const { setCurrentState, isFace, isDocument, inputRef } = useVerificationFieldContext();

  const changeView = useCallback((e, state: FieldState) => {
    e.preventDefault();
    if (state !== 'camera' || !isMobile) {
      setCurrentState(state);
    }
    if (state === 'camera' && isMobile) {
      inputRef?.current?.click();
    }
  }, [inputRef, setCurrentState]);

  const title = useMemo(() => {
    if (isFace) {
      return 'Get ready for your selfie';
    }
    if (isDocument) {
      return 'Get ready for your document photo';
    }
  }, [isFace, isDocument]);

  const description = useMemo(() => {
    if (isFace) {
      return 'When taking a selfie, make sure your face is clearly visible and in the photo frame. \nAlso, try to avoid direct light and dark lighting that would make your facial features difficult to recognize.';
    }
    if (isDocument) {
      return 'When using an ID card, driver’s license, ans some residence permit you will be asked to take photos of the front and back of the identity document. Please, check if your device’s camera is uncovered and working.';
    }
  }, [isFace, isDocument]);

  return { changeView, title, description, isMobile };
}

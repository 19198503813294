import { MediaFieldControllerDelegate } from 'aid-form-service/lib/State/StateControllers/FieldControllers/MediaFieldController/index.delegate';
import { FileValue } from 'aid-form-service/lib/State/StateControllers/FieldControllers/MediaFieldController/index.types';
import { Error } from 'aid-form-service/lib/State/StateControllers/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { useFormObjectController } from 'Hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

function isUploadedFile(value: FileValue | File): value is FileValue {
  return !!(value as any).id;
}

export function useFormMediaFieldController(
  field: MediaFieldControllerDelegate
) {
  const { ref } = useFormObjectController(field);
  const [value, setValue] = useState<FileValue>(field.value);
  const [error, setError] = useState(field.error);
  const label = useMemo(
    () => (!field.options?.hide_label ? field.name : undefined),
    [field.options?.hide_label, field.name]
  );
  const watermark = useMemo(() => field.options?.watermark, [field]);

  const onChange = useCallback(
    async (value?: FileValue | File | undefined) => {
      if (value && isUploadedFile(value)) {
        field.value = value;
      } else if (value) {
        await field.setValueByFile(value);
      } else {
        field.value = undefined;
      }
    },
    [field]
  );

  const onChangeByFile = useCallback(
    (value?: File) => {
      if (value) {
        field.setValueByFile(value);
      } else {
        field.value = undefined;
      }
    },
    [field]
  );

  useEffect(() => {
    const updateValue = function (nextValue: any) {
      setValue(nextValue);
    };
    field.subscribe('value', updateValue);
    const updateError = function (error: Maybe<Error>) {
      setError(error);
    };
    field.subscribe('error', updateError);
    return () => {
      field.unsubscribe('value', updateValue);
      field.unsubscribe('error', updateError);
    };
  }, [field]);

  return {
    ref,
    onChange,
    value,
    error,
    label,
    loading: !!value?.loading,
    onChangeByFile,
    watermark,
  };
}

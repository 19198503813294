export interface DropFieldActionsViewProps {
  hasVideo?: boolean;
}

export enum TypeMediaField {
  IMAGE = 'image',
  VIDEO = 'video',
  SIGNATURE = 'signature',
  DOC = 'doc',
}

export interface TextOptions {
  anotherLabel?: string;
  label?: string;
  description?: string;
  actionLabel?: string;
  liveOnlyLabel?: string;
  anotherLiveOnlyComment?: string;
  errorPermission?: string;
  errorCameraUsed?: string;
  errorDeviceLength?: string;
  errorResolution?: string;
  errorAnotherComment?: string;
  errorPermissionsComment?: string;
  errorPermissionAction?: string;
  errorMediaRecorderLabel?: string;
  errorMediaRecorderComment?: string;
}

export type ObjectTextOptions = {
  [key in TypeMediaField]: TextOptions;
};

import { PropsWithChildren, useMemo } from 'react';
import { StyledLink, StyledText } from './styles';
import { DefaultTextViewProps, TextViewProps } from './types';
import { transformTypeToWeight } from './utils';

function RenderText({
  text,
  children,
  ...props
}: PropsWithChildren<DefaultTextViewProps>) {
  return <StyledText {...props}>{text || children}</StyledText>;
}

function RenderLink({
  text,
  children,
  ...props
}: PropsWithChildren<DefaultTextViewProps>) {
  return (
    <StyledLink {...props} rel="noopener noreferrer">
      {text || children}
    </StyledLink>
  );
}

export function Text({
  asLink,
  children,
  weight = 'Regular',
  size = 14,
  ...props
}: PropsWithChildren<TextViewProps>) {
  const Component = useMemo(() => (asLink ? RenderLink : RenderText), [asLink]);

  return (
    <Component {...props} weight={transformTypeToWeight(weight)} size={size}>
      {children}
    </Component>
  );
}

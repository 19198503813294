import styled from '@emotion/styled';
import { Icon } from 'UIComponents';
import { getColor } from 'UIUtils';
import { Button } from '../Button';
import { StyledErrorRow } from '../InputDecorator/styles';
import { DropFieldActionsViewProps } from './types';

export const ErrorText = styled(StyledErrorRow)`
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  text-align: center;
  margin: auto 0;
`;

export const StyledIcon = styled(Icon)`
  color: ${getColor('input-border')};
`;

export const DropFieldActions = styled.div<DropFieldActionsViewProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute !important;
  bottom: ${({ hasVideo }) => (hasVideo ? 'auto' : '0')};
  top: ${({ hasVideo }) => (hasVideo ? '12px' : 'auto')};
  padding: 0 20px 16px 20px;
  box-sizing: border-box;
  z-index: 13;
  pointer-events: none;

  & > * {
    display: flex;
    width: 100px;
    justify-content: space-between;

    &:last-of-type {
      justify-content: flex-end;
    }
  }

  & > div {
    & > button:first-of-type {
      margin-left: 0;
    }
    & > button {
      margin-left: 20px;
    }
  }
`;

export const DropFieldAction = styled(Button)`
  padding: 0;
  border-radius: 50%;
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  border: 1px solid ${getColor('media-secondary-text-color')};
  background: ${getColor('white')};

  pointer-events: all;

  & svg {
    color: ${getColor('media-secondary-text-color')};
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 10;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${getColor('input-border')};
`;

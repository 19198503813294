import styled from '@emotion/styled/macro';
import { Text } from 'UIComponents';
import { getColor } from 'UIUtils';

export const Container = styled.div`
  background: ${getColor('input-outline')};
  border-radius: 16px;
  display: flex;
  padding: 4px 12px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  width: max-content;
  max-width: calc(100% - 24px);
  margin-bottom: 20px;
`;

export const Loading = styled.div`
  background: ${getColor('input-border')};
  margin-left: 8px;
  width: 180px;
  height: 16.38px;
  border-radius: 16px;
`;

export const SVGContainer = styled.svg`
  color: ${getColor('input-focus-border')};
  min-height: 10px;
  min-width: 10px;
`;

export const Label = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${getColor('input-focus-border')};
  margin-left: 8px;
`;

import { HeaderViewProps } from './types';
import { Default, Months, Years } from './components';
import { useHeaderController } from './controller';
import { ViewModes } from './types';

export function Header({
  decreaseYear,
  increaseYear,
  increaseMonth,
  decreaseMonth,
  changeMonth,
  changeYear,
  date,
  minDate,
  maxDate,
}: HeaderViewProps) {
  const { viewMode, setViewMode } = useHeaderController(
    date,
    changeMonth,
    minDate,
    maxDate
  );

  if (viewMode === ViewModes.YEARS) {
    return (
      <Years
        date={date}
        decreaseYear={decreaseYear}
        increaseYear={increaseYear}
        changeYear={changeYear}
        setViewMode={setViewMode}
        minDate={minDate}
        maxDate={maxDate}
      />
    );
  }

  if (viewMode === ViewModes.MONTHS) {
    return (
      <Months
        changeMonth={changeMonth}
        date={date}
        setViewMode={setViewMode}
        minDate={minDate}
        maxDate={maxDate}
      />
    );
  }

  return (
    <Default
      decreaseYear={decreaseYear}
      decreaseMonth={decreaseMonth}
      increaseMonth={increaseMonth}
      increaseYear={increaseYear}
      date={date}
      setViewMode={setViewMode}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}

import { CameraInitParams } from './types';

export class Camera {
  canvas: HTMLCanvasElement;
  video: HTMLVideoElement;
  context: CanvasRenderingContext2D;

  constructor(canvas: HTMLCanvasElement, video: HTMLVideoElement) {
    this.canvas = canvas;
    this.video = video;
    const context = canvas.getContext('2d');
    if (!context) {
      throw Error('Can not create context');
    }
    this.context = context;
  }

  static async init(params: CameraInitParams) {
    const config = {
      audio: false,
      video: {
        width: { min: 640, ideal: 1280, max: 1280 },
        height: { min: 480, ideal: 720, max: 720 },
      },
      frameRate: {
        ideal: 24,
      },
    };

    const stream = await navigator.mediaDevices.getUserMedia(config);
    const camera = new Camera(params.canvas, params.video);
    camera.video.srcObject = stream;
    await new Promise<undefined>((res) => {
      camera.video.onloadedmetadata = () => {
        res(undefined);
      };
    });

    camera.video.play();
    const videoWidth = camera.video.videoWidth;
    const videoHeight = camera.video.videoHeight;

    camera.video.width = videoWidth;
    camera.video.height = videoHeight;

    camera.canvas.width = camera.video.width;
    camera.canvas.height = camera.video.height;

    camera.context.translate(camera.canvas.width, 0);
    camera.context.scale(-1, 1);

    camera.renderPrediction();

    return camera;
  }

  finish() {
    const tracks = (this.video.srcObject as any)?.getTracks?.();
    if (Array.isArray(tracks)) {
      for (let i = 0; i < tracks.length; i++) {
        try {
          tracks[i]?.stop?.();
        } catch (e) {}
      }
    }
    this.video.srcObject = null;
  }

  async renderPrediction() {
    if (this.video.readyState < 2) {
      await new Promise((resolve) => {
        this.video.onloadeddata = () => {
          resolve(undefined);
        };
      });
    }
    this.drawCtx();
    requestAnimationFrame(this.renderPrediction.bind(this));
  }

  drawCtx() {
    const width = this.video.width / (this.canvas.width / this.video.width);
    const height =
      this.video.height / (this.canvas.height / this.canvas.height);
    this.context.drawImage(this.video, 0, 0, width, height);
  }
}

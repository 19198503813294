import styled from '@emotion/styled/macro';
import { getColor } from 'UIUtils';
import { Text } from '../Text';
import { EmotionArrowViewProps, EmotionWrapperViewProps } from './types';

export const Arrow = styled.div<EmotionArrowViewProps>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5.5px 0 5.5px;
  border-color: ${getColor('input-focus-border')} transparent transparent
    transparent;
  ${({ open }) => (open ? 'transform: rotate(180deg);' : '')}
`;

export const Wrapper = styled.div<EmotionWrapperViewProps>`
  background: ${({ open }) =>
    open
      ? 'linear-gradient(180deg, #FFFFFF 51.56%, #F8F8F8 100%)'
      : getColor('disabled-input-background')};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ open }) =>
    open === false ? 'transparent' : getColor('input-focus-border')};
  border-radius: 4px;
  padding: 9px 10px;
  margin-bottom: 20px;
  max-width: 100%;
  ${({ disabled }) => (disabled ? 'opacity: 0.7;' : '')}
`;

export const Control = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-left: 15px;
  }
  & > div:first-of-type {
    margin-left: 5px;
  }
`;

export const Title = styled(Text)`
  user-select: none;
`;

export const Collapse = styled.div`
  margin-top: 12px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const Description = styled(Text)`
  display: block;
  line-height: 1.4;
  white-space: pre-line;
`;

import { useMemo } from 'react';
import { isAndroid } from 'react-device-detect';
import { FieldContainer } from '../../../FieldContainer';
import {
  TYPE_FILE,
  TYPE_FILE_UPLOAD_CLAIM,
} from '../../../AnotherUpload/controller/constants';
import { Icon } from '../../../Icon';
import { InputDecorator } from '../../../InputDecorator';
import { IsMediaRecorderExist } from '../../constants';
import {
  DropFieldAction,
  DropFieldActions,
  ErrorText,
  LoaderContainer,
} from '../../styles';
import { TypeMediaField } from '../../types';
import { VideoCaptureField } from '../CaptureField';
import { Drop } from '../Drop';
import { WebCamBody } from '../WebCamBody';
import { useRenderValueController, useVideoController } from './controller';
import { MediaErrorContainer, LinkError, RecordIcon, StopIcon } from './styles';
import {
  FormVideoFieldViewProps,
  RenderVideoValueViewProps,
  UBTFormImageFieldViewProps,
  VideoFieldViewProps,
  VideoFieldWithDecoratorViewProps,
} from './types';
import { useFormMediaFieldController } from 'Hooks';
import { MediaValue } from 'UIComponents';
import { useFormContext } from 'Context';

// function RenderInProgress() {
//   const { language } = useFormContext();
//   return (
//     <Text
//       text={language?.getTranslate('ui.media.scan.progress', { arg: 'video' })}
//       color="warning-text-color"
//       align="center"
//       size={22}
//     />
//   );
// }

// function RenderInfected() {
//   const { language } = useFormContext();
//   return (
//     <Text
//       text={language?.getTranslate('ui.media.scan.infected', { arg: 'video' })}
//       color="error-text-color"
//       align="center"
//       size={22}
//     />
//   );
// }

export function RenderVideoValue({
  value,
  handleRemove,
}: RenderVideoValueViewProps) {
  const { mediaError, href } = useRenderValueController(value);
  const { language } = useFormContext();

  // if (value?.av_status === 'in_progress' && !value?.safe_download_url) {
  //   return <RenderInProgress />;
  // } else if (value?.av_status === 'infected') {
  //   return <RenderInfected />;
  // }

  return (
    <>
      {mediaError ? (
        <MediaErrorContainer>
          <ErrorText size={22}>
            {language?.getTranslate('ui.media.errors.not_supported.label')}
          </ErrorText>
          {href && (
            <ErrorText>
              {language?.getTranslate(
                'ui.media.errors.not_supported.description'
              )}{' '}
              <LinkError
                href={href}
                text={language?.getTranslate(
                  'ui.media.errors.not_supported.action'
                )}
                target="_blank"
                download={value?.file_name}
                asLink
              />
            </ErrorText>
          )}
        </MediaErrorContainer>
      ) : (
        <MediaValue type="Video" value={value} handleRemove={handleRemove} />
      )}
    </>
  );
}

export function VideoField({
  onChange,
  value,
  options,
  error,
  loading,
  typeUploads,
}: VideoFieldViewProps) {
  const {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    actionRef,
    anotherUpload,
    inputProps,
    handlerChange,
    handlerUseWebcam,
    showMobileButtons,
    liveOnly,
    dropFieldProps,
    inputFile,
    mobileOnly,
    onChangeWebcam,
    useWebCam,
    webCamRef,
    onComplete,
    stopRecord,
    textOptions,
  } = useVideoController({
    options,
    onChange,
    value,
    typeUploads,
  });

  if (value) {
    return <RenderVideoValue value={value} handleRemove={handlerRemove} />;
  }

  return (
    <Drop
      {...dropFieldProps}
      liveOnly={!!liveOnly}
      rejected={dropFieldProps.rejected}
      errorType={TypeMediaField.VIDEO}
      error={error}
    >
      {loading && (
        <LoaderContainer>
          <Icon glyph="spinner" color="white" size={50} />
        </LoaderContainer>
      )}
      <>
        <input
          {...inputProps}
          accept="video/*"
          type="file"
          ref={inputFile}
          onChange={handlerChange}
          style={{ display: 'none' }}
          multiple={false}
        />
        <WebCamBody
          mobileOnly={!!mobileOnly}
          anotherUpload={anotherUpload}
          onChange={onChangeWebcam}
          typeUploads={typeUploads}
          useWebCam={useWebCam}
          webCamRef={webCamRef}
          onComplete={onComplete}
          value={value}
          liveOnly={!!liveOnly}
          actionRef={actionRef}
          cameraType={0}
          handlerButtonClick={handlerButtonClick}
          anotherType={TYPE_FILE.VIDEO}
          anotherCommentType="video"
          RenderValueComponent={RenderVideoValue}
          textOptions={textOptions}
          mobileOnlyType={TYPE_FILE.VIDEO_MOBILE}
          actionButton={
            <DropFieldAction onClick={stopRecord}>
              <StopIcon />
            </DropFieldAction>
          }
        />
      </>
      <DropFieldActions hasVideo={!!value}>
        <div ref={actionRef}>
          {!useWebCam &&
            !anotherUpload &&
            !value &&
            showMobileButtons &&
            IsMediaRecorderExist && (
              <DropFieldAction onClick={handlerUseWebcam}>
                <RecordIcon />
              </DropFieldAction>
            )}
        </div>
        <div>
          {!useWebCam &&
            !anotherUpload &&
            !value &&
            showMobileButtons &&
            isAnotherEmail &&
            IsMediaRecorderExist && (
              <DropFieldAction
                onClick={toggleAnotherUpload(TYPE_FILE_UPLOAD_CLAIM.EMAIL)}
              >
                <Icon glyph="mail" />
              </DropFieldAction>
            )}
          {!useWebCam &&
            !anotherUpload &&
            !value &&
            showMobileButtons &&
            isAnotherQR &&
            IsMediaRecorderExist && (
              <DropFieldAction
                onClick={toggleAnotherUpload(TYPE_FILE_UPLOAD_CLAIM.QR)}
              >
                <Icon glyph="devices" />
              </DropFieldAction>
            )}
          {useWebCam && (
            <DropFieldAction onClick={handlerUseWebcam}>
              <Icon glyph="cross" />
            </DropFieldAction>
          )}
          {anotherUpload && (
            <DropFieldAction onClick={toggleAnotherUpload()}>
              <Icon glyph="cross" />
            </DropFieldAction>
          )}
          {value && (
            <DropFieldAction onClick={handlerRemove}>
              <Icon glyph="delete" />
            </DropFieldAction>
          )}
        </div>
      </DropFieldActions>
    </Drop>
  );
}

function VideoWithCheckCapture(props: VideoFieldViewProps) {
  const isCapture = useMemo(() => {
    const input = document.createElement('input');
    return input.capture !== undefined;
  }, []);

  if (isCapture && !isAndroid) {
    return <VideoCaptureField {...props} />;
  }

  return <VideoField {...props} />;
}

export function VideoFieldWithDecorator({
  label,
  error,
  ...props
}: VideoFieldWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <VideoWithCheckCapture {...props} error={!!error} />
    </InputDecorator>
  );
}

export function UBTFormVideoField({
  field,
  value,
  error,
  isVisible,
}: UBTFormImageFieldViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <VideoFieldWithDecorator
        label={field.name}
        value={value}
        error={error}
        loading={!!value?.loading}
        options={field.options}
      />
    </FieldContainer>
  );
}

export function FormVideoField({ field }: FormVideoFieldViewProps) {
  const { ref, value, onChange, error, label, loading } =
    useFormMediaFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <VideoFieldWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        loading={loading}
        typeUploads={
          typeof (field.options as any)?.anotherUpload === 'undefined'
            ? [TYPE_FILE_UPLOAD_CLAIM.QR]
            : (field.options as any)?.anotherUpload
        }
      />
    </FieldContainer>
  );
}

import { FieldViewProps } from './types';
import { useFieldController } from './controller';
import { VerificationFieldContext } from './context';
import { FieldContainer } from './shared.styles';

export function Field({ type }: FieldViewProps) {
  const { contextData, onChangeImage, inputRef, ComponentByState } =
    useFieldController(type);

  return (
    <VerificationFieldContext.Provider value={contextData}>
      <FieldContainer>
        <ComponentByState />
      </FieldContainer>
      <input
        accept="image/*"
        ref={inputRef}
        type="file"
        onClick={(e) => e.stopPropagation()}
        onChange={onChangeImage}
        style={{ display: 'none' }}
      />
    </VerificationFieldContext.Provider>
  );
}

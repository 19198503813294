import { MultiValue, SingleValue } from 'react-select';
import { SelectOption } from './types';

export function isMultiValue(
  value:
    | MultiValue<SelectOption | undefined>
    | SingleValue<SelectOption | undefined>
): value is MultiValue<SelectOption | undefined> {
  return Array.isArray(value);
}

import styled from '@emotion/styled';
import { Text } from 'UIComponents';
import { getColor } from 'UIUtils';

export const MediaErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  & > span:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export const RecordIcon = styled.div`
  width: 10px;
  height: 10px;
  background-color: #900000;
  border-radius: 50%;
`;

export const LinkError = styled(Text)`
  color: ${getColor('error-text-color')};

  & > span {
    color: ${getColor('error-text-color')};
  }
`;

export const StopIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${getColor('input-border')};
`;

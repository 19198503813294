import { ChangeEvent } from 'react';
import { ContainerToggler, StyledInput, Toggler } from './styles';
import {
  FormToggleViewProps,
  ToggleViewProps,
  UBTFormToggleViewProps,
} from './types';
import { useFormFieldController } from 'Hooks';
import { FieldContainer } from '../FieldContainer';
import { VerticalInputDecorator } from '../InputDecorator';

export function Toggle({
  label,
  disabled,
  onChange,
  value,
  error,
}: ToggleViewProps) {
  const handlerChange = (e: ChangeEvent) => {
    e.stopPropagation();
    if (onChange && !disabled) {
      onChange(!value);
    }
  };

  return (
    <>
      <StyledInput
        type="checkbox"
        checked={value}
        onChange={handlerChange}
        disabled={disabled}
        error={error}
      />
      <ContainerToggler>
        <Toggler disabled={disabled} error={error} />
      </ContainerToggler>
    </>
  );
}

export function UBTFormToggle({
  value,
  isVisible,
  field,
  error,
}: UBTFormToggleViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <VerticalInputDecorator
        label={field.name}
        error={error}
        options={field.options}
        paddingLeft="38px"
      >
        <Toggle label={field.name} value={value} error={error} />
      </VerticalInputDecorator>
    </FieldContainer>
  );
}

export function FormToggle({ field }: FormToggleViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);
  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <VerticalInputDecorator
        label={label}
        error={error}
        options={field.options}
        paddingLeft="38px"
      >
        <Toggle label={label} value={value} onChange={onChange} error={error} />
      </VerticalInputDecorator>
    </FieldContainer>
  );
}

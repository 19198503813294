import { useCallback, useMemo } from 'react';
import { useDropFieldProps } from 'Hooks';
import { ImageFieldViewProps } from '../../../../Image/types';
import { useCaptureFieldController } from '../../../controller';
import { useFormContext } from 'Context';

export function useImageCaptureFieldController({
  options,
  onChange,
  typeUploads,
}: Omit<ImageFieldViewProps, 'error' | 'loading'>) {
  const {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    anotherUpload,
    liveOnly,
    inputFile,
    handlerCapture,
    captureRef,
  } = useCaptureFieldController({
    options,
    onChange,
    typeUploads,
  });
  const { language } = useFormContext();

  const onDropAccepted = useCallback(
    (files) => {
      onChange?.(files[0]);
    },
    [onChange]
  );

  let { onSetRejected, ...dropFieldProps } = useDropFieldProps(
    liveOnly || false,
    {
      accept: 'image/*',
      maxSize: 15728640,
      onDropAccepted,
    }
  );

  const handlerUpload = useCallback(
    async (file) => {
      file?.preventDefault?.();
      onChange?.(file);
    },
    [onSetRejected, onChange]
  );

  const handlerChange = useCallback(
    (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      if (file?.size > 15728640) {
        if (inputFile.current) {
          inputFile.current.value = '';
        }
        return onSetRejected('file-too-large');
      }

      handlerUpload(file);
    },
    [handlerUpload, inputFile, onSetRejected]
  );

  let inputProps = useMemo(
    () => (liveOnly ? {} : dropFieldProps.getInputProps()),
    [liveOnly, dropFieldProps]
  );

  const textOptions = useMemo(
    () => ({
      anotherLabel: language?.getTranslate('ui.media.another_device.label', {
        arg: 'image',
      }),
      label: language?.getTranslate('ui.media.attach.label', { arg: 'image' }),
      description: language?.getTranslate('ui.media.attach.description', {
        type: 'image',
        int: 15,
      }),
      actionLabel: language?.getTranslate('ui.media.browse_files'),
      liveOnlyLabel: language?.getTranslate('ui.media.liveOnly.image'),
      anotherLiveOnlyComment: language?.getTranslate(
        'ui.media.only_mobile.label',
        { arg: 'image' }
      ),
      errorPermission: language?.getTranslate('ui.media.errors.access'),
      errorCameraUsed: language?.getTranslate('ui.media.errors.already_use'),
      errorDeviceLength: language?.getTranslate('ui.media.errors.empty'),
      errorResolution: language?.getTranslate('ui.media.errors.resolution'),
      errorAnotherComment: language?.getTranslate(
        'ui.media.another_device.or_label',
        { arg: 'image' }
      ),
      errorPermissionsComment: language?.getTranslate(
        'ui.media.errors.granted_access',
        { arg: 'image' }
      ),
      errorPermissionAction: language?.getTranslate('ui.media.liveOnly.image'),
    }),
    [language]
  );

  return {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    anotherUpload,
    inputProps,
    handlerChange,
    handlerCapture,
    captureRef,
    liveOnly,
    dropFieldProps,
    inputFile,
    handlerUpload,
    textOptions,
  };
}

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { UploadedFile } from './types';
import { useApiContext, useClientContext } from 'Context';
import { cutFileName } from '../formApiClient/utils';

export function useDocument(id: string) {
  const { getFile } = useUploadService();
  const [data, setData] = useState<UploadedFile>();
  const [loading, setLoading] = useState(true);

  const loadFile = useCallback(async () => {
    if (id) {
      setLoading(true);
      const file = await getFile(id);
      setData(file);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [getFile, id]);

  useEffect(() => {
    loadFile();
  }, [loadFile]);
  return { data, loading };
}

export function useUploadService() {
  const api = useApiContext();
  const { formClientId } = useClientContext();

  const uploadFile = useCallback(
    async (kind, file, watermark) => {
      if (file) {
        if (!formClientId) {
          throw Error('For external form need use clientId in params');
        }
        const formData = new FormData();
        const fileName =
          cutFileName(file.name) ||
          `${kind}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}`;
        formData.append('file_name', fileName);
        formData.append('kind', kind);
        formData.append('service_name', 'core');
        formData.append('file_size', file.size);
        formData.append('is_watermark_required', `${!!watermark?.enabled}`);
        if (watermark?.value) {
          formData.append('watermark_text', watermark.value);
        }

        const res: any = await api.documents.upload.safe.post(
          {
            client_id: formClientId,
          },
          { data: formData }
        );

        if (res?.data?.success && res?.data?.data?.safe_upload_url) {
          const uploadUrl = res?.data?.data?.safe_upload_url;
          if (uploadUrl) {
            let headers = {};
            if (uploadUrl.includes('x-amz-server-side-encryption')) {
              headers = {
                'x-amz-server-side-encryption': 'AES256',
              };
            }
            const uploadToSafeLink = await fetch(uploadUrl, {
              method: 'PUT',
              body: file,
              headers,
            });

            if (uploadToSafeLink.status === 200) {
              return res?.data?.data as UploadedFile;
            }
          }
        }

        return;
      }
    },
    [formClientId]
  );

  const getFile = useCallback(async (id: string) => {
    try {
      const res: any = await api.documents.download.meta_info[id]();

      if (res?.data?.data) {
        return res.data.data as UploadedFile;
      }
      return;
    } catch (e) {
      return;
    }
  }, []);

  return { uploadFile, getFile };
}

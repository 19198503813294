import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import { getColor } from '../../utils';

export const StyledToastContainer = styled(ToastContainer)`
  min-width: 588px;

  .Toastify__close-button {
    opacity: 1;
    display: flex;
    color: ${getColor('active-text-color')};
    position: absolute;
    right: 4px;
    top: 4px;

    &--warning {
      color: ${getColor('warning-text-color')};
    }
    &--error {
      color: ${getColor('error-text-color')};
    }
    &--success {
      color: ${getColor('success-text-color')};
    }
  }
  .Toastify__toast {
    background: ${getColor('white')};
    color: ${getColor('active-text-color')};
    border: 1px solid ${getColor('active-text-color')};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    min-height: fit-content;
    max-width: fit-content;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;
    margin-left: auto;

    @media only screen and (max-width: 480px) {
      min-width: 100vw;
      max-width: 100vw;
      margin-left: 0;
    }

    &--error {
      background: ${getColor('white')};
      border: 1px solid ${getColor('error-text-color')};
      color: ${getColor('error-text-color')};

      & svg {
        color: ${getColor('error-text-color')};
      }
    }
    &--warning {
      background: ${getColor('white')};
      border: 1px solid ${getColor('warning-text-color')};
      color: ${getColor('warning-text-color')};
      & svg {
        color: ${getColor('warning-text-color')};
      }
    }
    &--success {
      background: ${getColor('white')};
      border: 1px solid ${getColor('success-text-color')};
      color: ${getColor('success-text-color')};
      & svg {
        color: ${getColor('success-text-color')};
      }
    }
    &--default {
      background: ${getColor('white')};
      border: 1px solid ${getColor('active-text-color')};
      color: ${getColor('default-text-color')};
      & svg {
        color: ${getColor('default-text-color')};
      }
    }
  }
  .Toastify__toast-body {
    padding: 24px 32px;
    margin: 0;
    min-height: fit-content;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
  }
  .Toastify__close-button {
    &--notification {
      color: linear-gradient(
        90deg,
        #1f3b8e 13.71%,
        #5a79cb 26.74%,
        #dce3f5 51.48%,
        #5a79cb 74.31%,
        #1f3b8e 100%
      );
    }
    &--default {
      color: ${getColor('default-text-color')};
    }
  }
  .Toastify__progress-bar {
    background-color: transparent;
    height: 2px;
    background: ${getColor('active-text-color')};

    &--warning {
      background: ${getColor('warning-text-color')};
    }
    &--error {
      background: ${getColor('error-text-color')};
    }
    &--success {
      background: ${getColor('success-text-color')};
    }
    &--notification {
      background: linear-gradient(
        90deg,
        #1f3b8e 13.71%,
        #5a79cb 26.74%,
        #dce3f5 51.48%,
        #5a79cb 74.31%,
        #1f3b8e 100%
      );
    }
    &--default {
      background: ${getColor('default-text-color')};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  margin-right: 16px;
`;

import styled from '@emotion/styled/macro';
import { getColor, getFont } from 'UIUtils';
import ReactSelect from 'react-select';
import { SelectProps, StateManagedSelect } from './types';
import {
  getBorderColorByParams,
  getBoxShadowColorByParams,
} from '../../TextInput/utils';
import { Text } from 'UIComponents';

export const TitleContainer = styled.div`
  ${({ color }) => color && `color: ${color};`}
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  & > span {
    line-height: 1.17;
  }
  @media only screen and (max-width: 1024px) {
    & > span {
      font-size: 16px;
    }
  }
`;

export const DropdownIndicator = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${getColor('input-border')};
`;

export const ClearIndicator = styled.div`
  width: 10px;
  height: 10px;
  background: red;
`;

export const MultiValuesContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: ${getColor('disabled-input-background')};
  border-radius: 4px;
  padding: 2px 8px;
  margin: 2px;

  & > span {
    line-height: 18px;
    overflow-wrap: anywhere;
  }

  & > div {
    &:hover {
      background: ${getColor('input-outline')};
    }
  }
`;

export const DeleteIconContainer = styled.div`
  margin-left: 5px;
  min-height: 18px;

  & svg {
    color: ${getColor('primary-text-color')};
  }
`;

export const SelectValuesContainer = styled.div`
  & > div > div {
    padding: 2px 2px !important;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`;

export const ControlContainer = styled.div<
  Required<Pick<SelectProps, 'error'>>
>`
  & > div {
    border: 1px solid ${({ error }) => getBorderColorByParams(error)};
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    min-height: fit-content;
    box-shadow: 0px 0px 5px 1px
      ${({ error }) => getBoxShadowColorByParams(error)};

    ${({ error }) => `
        &:hover {
            border: 1px solid ${getColor(
              error ? 'error-text-color' : 'input-focus-border'
            )};
            box-shadow: 0 0 5px 1px ${getColor(
              error ? 'error-text-color' : 'input-outline'
            )};
        }
    `}

    & > div:first-of-child {
      padding: 2px 2px !important;
    }

    & > .select__indicators {
      & svg {
        color: ${getColor('input-focus-background')};
      }
    }

    & .select__single-value {
      margin: 0;
    }

    & .select__placeholder {
      margin: 0px;
    }

    & .select__value-container--is-multi {
      padding: 0px !important;
      & > .select__input-container {
        padding: 6px 12px;
      }
      & > .select__placeholder {
        padding: 6px 12px;
      }
    }

    & .select__value-container {
      padding: 6px 12px;
      line-height: 1.17;
      font-family: ${getFont('primary')};
      font-size: 14px;
      @media only screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }

    & > .select__indicators {
      padding: 0px !important;
      & > div {
        padding: 0px 8px !important;
      }
    }
  }

  & .select__input-container {
    margin: 0px;
    padding: 0px;
  }
`;

export const NoOptionsContainer = styled.div`
  max-width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SingleValueText = styled(Text)`
  padding: 0;
  margin: 0;
  line-height: 1.17;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const CrossContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`;

export const MenuContainer = styled.div`
  & > div {
    z-index: 20;
    width: 100%;
    border: 1px solid ${getColor('input-outline')};
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 1px ${getColor('input-outline')};
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const MenuListContainer = styled.div`
  & > div {
    padding: 0;
  }
`;

export const StyledReactSelect = styled(
  ReactSelect
)<SelectProps>`` as StateManagedSelect;

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 150px;
`;

export const OptionContainer = styled.div<{ isSelected: boolean }>`
  display: grid;
  padding: 10px;
  align-items: center;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};

  & span {
    color: ${getColor('note-text-color')};
  }

  &:hover {
    background: ${getColor('input-outline')};

    & span {
      color: ${getColor('primary-text-color')};
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background: ${getColor('input-focus-background')}!important;
    &:hover {
      background: ${getColor('input-focus-background')}!important;
    }
    & svg {
        color: ${getColor('white')}!important;
    }

    & span {
      color: ${getColor('white')}!important;
    }
  `}
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: default;

  & > div {
    cursor: default;
  }
`;

import styled from '@emotion/styled/macro';
import { getColor } from 'UIUtils';
import { Text } from '../Text';

export const Wrapper = styled.div`
  box-shadow: 0px 4px 8px #E3DEE9;
  box-sizing: border-box;
  background-color: ${getColor('white')};
  border-radius: 8px;
  border: 1px solid ${getColor('input-border')};
  padding: 60px;
  width: max-content
  margin: auto;
  margin-top: 32px;
  position: relative;
  max-width: 440px;
`;

export const ChildrenWrapper = styled.div<{ marginTop?: boolean }>`
  min-width: 320px;
  ${({ marginTop }) => marginTop && 'margin-top: 32px;'}
  position: relative;
`;

export const Title = styled(Text)`
  letter-spacing: -0.5px;
`;

export const TitleIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  span + div {
    margin-top: 32px;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  z-index: 25;
`;

import styled from '@emotion/styled';
import { PrimaryButton, SecondaryButton } from 'UIComponents';

export const FieldContainer = styled.div`
  position: relative;
  border: 1px solid #DCE3F5;
  border-radius: 6px;
  overflow: hidden;
`;

export const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const ButtonContentContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: max-content max-content;
  gap: 12px;
`;

export const NextButton = styled(PrimaryButton)`
  margin-top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 11px;
  height: 24px;
`;

export const PrevButton = styled(SecondaryButton)`
  margin-top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 11px;
  height: 24px;
`;

import styled from '@emotion/styled';
import { getColor } from 'UIUtils';

export const Container = styled.div`
  z-index: 15;
  background: ${getColor('white')};
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  right: 12px;
  padding: 1px 6px;
  top: -6px;
  height: 12px;
`;

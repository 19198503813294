import styled from '@emotion/styled';
import { getColor } from 'UIUtils';

export const InitContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, max-content);
  gap: 34px;
  padding: 20px;
  background: ${getColor('white')};
`;

export const TextContainer = styled.div`
  display: grid;
  grid-template-rows: 18px 28px;
  gap: 8px;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: 80px max-content;
  gap: 16px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getColor('media-secondary-text-color')};
`;

export const ActionsContainer = styled.div`
  margin-bottom: 32px;
`;

import { createContext, useContext } from 'react';
import { StateContextProps } from './types';

export const VerificationFieldContext = createContext<StateContextProps>({
  setCurrentState: () => {},
  setRawImage: () => {},
  isFace: false,
  isDocument: false,
});

export function useVerificationFieldContext() {
  const context = useContext(VerificationFieldContext);

  return context;
}

import { useCallback, useMemo, useState } from 'react';
import { IConfirmedInputWithConfirmProps, IValue } from '../types';

export function useFieldWithConfirmController(
  value: IConfirmedInputWithConfirmProps['value'],
  onChange: IConfirmedInputWithConfirmProps['onChange']
) {
  const [currentValue, setCurrentValue] = useState(value);
  const onChangeInput = useCallback(
    (phoneVal: IValue) => {
      setCurrentValue((prev) => {
        const next = {
          ...prev,
          value: phoneVal,
        };
        const isConfirmed = !!(prev?.confirmed || [])?.includes(phoneVal || '');
        if (isConfirmed) {
          onChange?.(next);
        }
        return next;
      });
    },
    [setCurrentValue, onChange]
  );

  const onChangeConfirm = useCallback(
    (confirmPhone: string) => {
      setCurrentValue((prev) => {
        const next = {
          ...prev,
          confirmed: [...(prev?.confirmed || []), confirmPhone],
        };
        onChange?.(next);
        return next;
      });
    },
    [onChange]
  );

  const isConfirmed = useMemo(() => {
    if (currentValue?.confirmed) {
      return !!currentValue?.confirmed?.includes(currentValue?.value || '');
    }
    return false;
  }, [currentValue]);

  return { onChangeInput, onChangeConfirm, isConfirmed, currentValue };
}

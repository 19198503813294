import { useFormContext } from 'Context';
import { useEffect, useRef } from 'react';
import { SelectOption } from '../../Select/types';
import { loadGooglePlaces } from './utils';

export function usePlacesController() {
  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const placesService = useRef<google.maps.places.PlacesService>();
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken>();
  const form = useFormContext();

  const init = useRef(() => {
    if (typeof google !== 'undefined') {
      autocompleteService.current =
        new google.maps.places.AutocompleteService();

      const div = document.getElementById('places_service');
      if (div) {
        placesService.current = new google.maps.places.PlacesService(
          div as HTMLDivElement
        );
      }

      if (!sessionToken.current) {
        sessionToken.current =
          new google.maps.places.AutocompleteSessionToken();
      }
    }
  });

  const loadPlacement = useRef(async (input: string) => {
    if (placesService.current && input) {
      const googlePlaces =
        await autocompleteService.current?.getPlacePredictions({
          input,
          sessionToken: sessionToken.current ? sessionToken.current : '',
          types: ['address'],
        });

      const options = (googlePlaces?.predictions || []).reduce(
        (acc, item: google.maps.places.AutocompletePrediction) => {
          if (!acc.uniqLabel[item.description]) {
            return {
              uniqLabel: { ...acc.uniqLabel, [item.description]: true },
              array: [
                ...acc.array,
                { label: item.description, value: item.place_id },
              ],
            };
          }

          return acc;
        },
        {
          uniqLabel: {} as { [key: string]: boolean },
          array: [] as SelectOption[],
        }
      );

      return options.array;
    }
  });

  const getPlace = useRef(
    async (
      placeId: string
    ): Promise<google.maps.places.PlaceResult | undefined> => {
      return new Promise((res, rej) => {
        if (!placesService.current) {
          res(undefined);
        }
        placesService.current?.getDetails(
          {
            placeId,
            sessionToken: sessionToken.current,
            fields: ['address_component'],
          },
          (e) => {
            if (e) {
              res(e);
            } else {
              res(undefined);
            }
          }
        );
      });
    }
  );

  useEffect(() => {
    loadGooglePlaces(init.current, form.data?.options.language || 'en');
  }, [form]);

  return { loadPlacement, getPlace };
}

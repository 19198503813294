const isBrowser = typeof window !== 'undefined' && window.document;

export function loadGooglePlaces(callback: () => void, language?: string) {
  const placeDiv = document.getElementById('places_service');

  if (!isBrowser || (typeof google !== 'undefined' && google?.maps))
    return callback();

  if (!placeDiv) {
    const newPlaceDiv = document.createElement('div');
    newPlaceDiv.id = 'places_service';
    document.body.appendChild(newPlaceDiv);
  }

  const script = document.getElementById('google_api');

  if (script) {
    if (typeof google !== 'undefined' && google?.maps) return callback();
    return script.addEventListener('load', () => callback());
  }

  if (process.env.REACT_APP_GOOGLE_API_KEY) {
    const newScript = document.createElement('script');
    newScript.src = `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_API_KEY
    }&libraries=places${
      language ? `&language=${language}` : ''
    }&callback=initGooglePlaces`;
    newScript.async = true;
    newScript.id = 'google_api';
    (window as any).initGooglePlaces = () => {
      callback();
    };
    document.body.appendChild(newScript);
    return;
  }

  return callback;
}

export function getPrefixTag(tags: string[]) {
  if (tags && Array.isArray(tags) && tags.length) {
    let tagPrefix;
    for (let i = 0, length = tags.length; i < length; i++) {
      const splitTag = tags[i].split('_');
      const lastTag = splitTag[splitTag.length - 1];

      if (lastTag && lastTag === 'ADDRESS') {
        tagPrefix = tags[i].replace('ADDRESS', '');
        break;
      }
    }

    return tagPrefix;
  }
  return;
}

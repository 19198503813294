import { useCallback, useRef, useEffect, useMemo } from 'react';
import { useVerificationFieldContext } from '../../../context';
import { Camera } from '../camera';
import { DocumentFocus, FaceFocus } from '../components';

export function useCameraController() {
  const { setCurrentState, isDocument, isFace, setRawImage } =
    useVerificationFieldContext();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const cameraRef = useRef<Promise<Camera>>();

  const Focus = useMemo(() => {
    if (isDocument) {
      return DocumentFocus;
    }
    if (isFace) {
      return FaceFocus;
    }
    return FaceFocus;
  }, [isFace, isDocument]);

  useEffect(() => {
    if (canvasRef.current && videoRef.current) {
      cameraRef.current = Camera.init({
        canvas: canvasRef.current,
        video: videoRef.current,
      });
    }

    return () => {
      cameraRef.current?.then((camera) => camera.finish());
    };
  }, []);

  const changeView = useCallback(
    (e) => {
      e.preventDefault();
      cameraRef.current?.then((camera) => camera.finish());
      setCurrentState('intro');
    },
    [setCurrentState]
  );

  const handlerConfirm = useCallback(
    async (e) => {
      e.preventDefault();
      if (canvasRef.current) {
        canvasRef.current.toBlob((blob) => {
          if (blob) {
            cameraRef.current?.then((camera) => camera.finish());
            setRawImage({
              file: blob,
              url: (window.URL || window.webkitURL).createObjectURL(blob),
            });
            setCurrentState('check');
          }
        });
      }
    },
    [canvasRef, setRawImage, setCurrentState]
  );

  return {
    changeView,
    canvasRef,
    videoRef,
    handlerConfirm,
    Focus,
  };
}

import moment from 'moment';
import { useEffect, useState } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ViewModes } from '../types';
import { HeaderViewProps } from '../types';

export function useHeaderController(
  date: ReactDatePickerCustomHeaderProps['date'],
  changeMonth: ReactDatePickerCustomHeaderProps['changeMonth'],
  minDate: HeaderViewProps['minDate'],
  maxDate: HeaderViewProps['maxDate']
) {
  const [viewMode, setViewMode] = useState<ViewModes>(ViewModes.DEFAULT);

  useEffect(() => {
    if (moment(date).year() === moment(minDate).year()) {
      if (moment(date).month() < moment(minDate).month()) {
        changeMonth(moment(minDate).month());
      }
    }

    if (moment(date).year() === moment(maxDate).year()) {
      if (moment(date).month() > moment(maxDate).month()) {
        changeMonth(moment(maxDate).month());
      }
    }
  }, [date]);

  return {
    viewMode,
    setViewMode,
  };
}

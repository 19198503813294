import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { ViewModes } from '../../../types';
import { DefaultViewProps } from '../types';

export function useDefaultController(
  date: DefaultViewProps['date'],
  setViewMode: DefaultViewProps['setViewMode'],
  minDate: DefaultViewProps['minDate'],
  maxDate: DefaultViewProps['maxDate']
) {
  const year = useMemo(() => moment(date).year(), [date]);
  const month = useMemo(() => moment.months(moment(date).get('month')), [date]);

  const prevYearButtonIsDisabled = useMemo(
    () => moment(date).subtract(1, 'year') < moment(minDate),
    [minDate, date]
  );

  const nextYearButtonIsDisabled = useMemo(
    () => moment(date).add(1, 'year') > moment(maxDate),
    [maxDate, date]
  );

  const nextMonthButtonIsDisabled = useMemo(() => {
    if (year === moment(maxDate).year()) {
      return moment(date).month() >= moment(maxDate).month();
    }

    return false;
  }, [date, maxDate, year]);

  const prevMonthButtonIsDisabled = useMemo(() => {
    if (year === moment(minDate).year()) {
      return moment(date).month() <= moment(minDate).month();
    }

    return false;
  }, [date, minDate, year]);

  const handlerChangeViewMode = useCallback(
    (mode: ViewModes) => {
      return () => {
        setViewMode(mode);
      };
    },
    [setViewMode]
  );

  return {
    year,
    month,
    handlerChangeViewMode,
    prevYearButtonIsDisabled,
    nextYearButtonIsDisabled,
    nextMonthButtonIsDisabled,
    prevMonthButtonIsDisabled,
  };
}

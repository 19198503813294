import styled from '@emotion/styled';
import { getColor } from 'UIUtils';

export const IntroContainer = styled.div`
  padding: 20px;
  background: ${getColor('white')};
  display: grid;
  grid-template-rows: repeat(2, max-content);
  gap: 24px;
`;

export const TextContainer = styled.div`
  display: grid;
  padding-left: 4px;
  padding-right: 4px;
  grid-template-rows: 56px;
  margin-bottom: 8px;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: 18px max-content max-content;
  gap: 12px;
`;

import { useFormContext } from 'Context';
import { useMemo } from 'react';
import { CustomInputViewProps } from '../types';

export function useCustomInputController(
  showTimeSelect: CustomInputViewProps['showTimeSelect']
) {
  const { language } = useFormContext();
  const mask = useMemo(
    () => (showTimeSelect ? '11/11/1111 11:11 **' : '11/11/1111'),
    [showTimeSelect]
  );

  const placeholder = useMemo(
    () =>
      language
        ? `${language.getTranslate(
            'ui.placeholders.date.month'
          )}/${language.getTranslate(
            'ui.placeholders.date.day'
          )}/${language.getTranslate('ui.placeholders.date.year')}`
        : '',
    [language]
  );

  return {
    placeholder,
    mask,
  };
}

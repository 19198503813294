import { PropsWithChildren, useContext, createContext, useMemo } from 'react';
import { ApiContextType } from './types';

export const ApiContext = createContext<ApiContextType>({
  useApi: () => {
    return {} as any;
  },
  setHeader(key, value) {},
  apiHost: '',
  axiosInstance: {} as any,
});

export function useApiContext() {
  const { useApi } = useContext(ApiContext);
  const api = useApi();

  return api;
}

export function useSetHeaderContext() {
  const { setHeader } = useContext(ApiContext);

  return setHeader;
}

export function useApiHostContext() {
  const context = useContext(ApiContext);

  return context;
}

export function ApiContextProvider({
  useApi,
  setHeader,
  apiHost,
  axiosInstance,
  children,
}: PropsWithChildren<ApiContextType>) {
  const value = useMemo(
    () => ({ useApi, apiHost, axiosInstance, setHeader }),
    [useApi, apiHost, axiosInstance, setHeader]
  );

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

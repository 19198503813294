import styled from '@emotion/styled/macro';
import { getColor, getFont } from 'UIUtils';
import { parseAlign } from '../CollapseInfo/utils';
import { NoteSizes } from './constants';
import { EmotionNoteContainerViewProps } from './types';

export const NoteContainer = styled.div<EmotionNoteContainerViewProps>`
  max-width: 100%;
  display: block;
  font-family: ${getFont('primary')};
  color: ${getColor('primary-text-color')};
  padding: 10px 0;
  white-space: pre-wrap;
  text-align: ${({ align }) => parseAlign(align)};
  font-size: ${({ size }) => NoteSizes[size]}px;
  overflow-wrap: break-word;
`;

import uniqueId from 'lodash.uniqueid';
import {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  createRef,
  cloneElement,
  Ref,
} from 'react';
import { Modal } from '../Modal';
import {
  RenderedModals,
  PromiseControlRef,
  UseModalControllerHook,
  ModalsImperativeRef,
  UseConfirmModalHook,
} from './types';
import { ModalViewProps } from '../types';
import { useModalController as useModalControllerHook } from 'Hooks';

export function useConfirmModalController(): UseConfirmModalHook {
  const modals = useRef<RenderedModals>({});
  const [, reRender] = useState<{}>();

  const createElement = useCallback((options: ModalViewProps, uniqID) => {
    const createdModal = modals.current?.[uniqID];
    if (createdModal) {
      return cloneElement(createdModal, options);
    }
    return <Modal ref={createRef()} key={uniqID} {...options} />;
  }, []);

  const update = useCallback(
    (options: ModalViewProps, uniqID?: string) => {
      const id = uniqID || uniqueId('modal_uniq_id');
      modals.current = {
        ...modals.current,
        [id]: createElement(options, id),
      };
      reRender({});
      if (!uniqID) {
        return id;
      }
    },
    [createElement]
  );

  const show = useCallback((uniqID?: string) => {
    if (uniqID) {
      return modals.current?.[uniqID]?.ref?.current?.show();
    }
    return;
  }, []);

  const remove = useCallback((uniqID) => {
    let currentModals = { ...modals.current };
    delete currentModals[uniqID];
    modals.current = currentModals;
    reRender({});
  }, []);

  const value = useRef({ show, remove, update });

  return { value, modals };
}

export function useModalController(
  ref: Ref<ModalsImperativeRef>,
  title?: string
): UseModalControllerHook {
  const { open, toggleOpen } = useModalControllerHook();
  const promise = useRef<PromiseControlRef>();
  const parentSelector = useRef(() => document.getElementById('theme'));

  const show = useCallback(() => {
    if (!title) {
      return;
    }
    return new Promise((resolve, reject) => {
      promise.current = {
        resolve,
        reject,
      };
      toggleOpen(true);
    });
  }, [toggleOpen, title]);

  const close = useCallback(() => {
    promise.current?.reject?.();
    toggleOpen(false);
  }, [toggleOpen]);

  useImperativeHandle(ref, () => ({
    show,
  }));

  const onClickConfirm = useCallback(() => {
    promise.current?.resolve?.();
    toggleOpen(false);
  }, [toggleOpen]);

  return {
    close,
    onClickConfirm,
    parentSelector: parentSelector.current,
    isOpen: open,
  };
}

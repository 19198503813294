import styled from '@emotion/styled/macro';
import { getColor, getFont } from 'UIUtils';
import { Icon } from '../Icon';
import { Text } from '../Text';

export const HeaderContainer = styled.div`
  display: grid;
  align-items: center;
  gap: 18px;
  position: absolute;
  top: 5%;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 710px;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  position: relative;
`;

export const SvgPath = styled.path`
  fill: none;
  stroke: ${getColor('input-focus-border')};
  stroke-width: 7.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dashoffset: 175;
`;

export const SvgText = styled.text`
  font-family: ${getFont('primary')};
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 60px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${getColor('placeholder-text-color')};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70vw;
  gap: 40px;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > button {
    margin-right: 20px;
    width: 240px;
    height: 40px;
    border-width: 1px;
  }

  & > button:last-child {
    margin-right: 0;
  }
`;

export const IconContainer = styled(Icon)`
  margin-top: -40px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FooterText = styled(Text)`
  position: absolute;
  bottom: 5%;
`;

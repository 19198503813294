import { RenderVideoValue } from '../../../Video';
import { AnotherUpload } from '../../../../../AnotherUpload';
import {
  TYPE_FILE,
  TYPE_FILE_UPLOAD_CLAIM,
} from '../../../../../AnotherUpload/controller/constants';
import {
  DropFieldAction,
  DropFieldActions,
  LoaderContainer,
} from '../../../../styles';
import { Default } from '../../../Default';
import { Drop } from '../../../Drop';
import { useVideoCaptureFieldController } from './controller';
import { Icon } from '../../../../../Icon';
import { RecordIcon } from '../../../Video/styles';
import { VideoFieldViewProps } from '../../../Video/types';
import { TypeMediaField } from '../../../../types';
import { ImageCaptureInputViewProps } from '../ImageCaptureField/types';

function RenderField({
  value,
  onChange,
  anotherUpload,
  liveOnly,
  handlerButtonClick,
  RenderValueComponent,
  anotherType,
  anotherCommentType,
  textOptions,
}: ImageCaptureInputViewProps) {
  return anotherUpload ? (
    <AnotherUpload
      label={textOptions.anotherLabel}
      onChange={onChange}
      type={anotherType}
      typeUploads={[anotherUpload]}
      commentType={anotherCommentType}
    />
  ) : value ? (
    <RenderValueComponent value={value} />
  ) : (
    <Default
      handlerButtonClick={handlerButtonClick}
      liveOnly={liveOnly}
      textOptions={textOptions}
    />
  );
}

export function VideoCaptureField({
  onChange,
  value,
  error,
  options,
  typeUploads,
  loading,
}: VideoFieldViewProps) {
  const {
    toggleAnotherUpload,
    isAnotherQR,
    isAnotherEmail,
    handlerRemove,
    handlerButtonClick,
    anotherUpload,
    inputProps,
    handlerChange,
    handlerCapture,
    captureRef,
    liveOnly,
    dropFieldProps,
    inputFile,
    handlerUpload,
    textOptions,
  } = useVideoCaptureFieldController({
    options,
    onChange,
    value,
    typeUploads,
  });

  if (value) {
    return <RenderVideoValue value={value} handleRemove={handlerRemove} />;
  }

  return (
    <Drop
      {...dropFieldProps}
      liveOnly={!!liveOnly}
      rejected={dropFieldProps.rejected}
      errorType={TypeMediaField.VIDEO}
      error={error}
    >
      {loading && (
        <LoaderContainer>
          <div>
            <Icon glyph="spinner" />
          </div>
        </LoaderContainer>
      )}
      <>
        <input
          {...inputProps}
          accept="video/*"
          type="file"
          ref={inputFile}
          onChange={handlerChange}
          style={{ display: 'none' }}
          multiple={false}
        />
        <input
          accept="video/*"
          type="file"
          ref={captureRef}
          onChange={handlerChange}
          style={{ display: 'none' }}
          multiple={false}
          capture="user"
        />
        <RenderField
          onChange={handlerUpload}
          anotherUpload={anotherUpload}
          value={value}
          liveOnly={!!liveOnly}
          handlerButtonClick={handlerButtonClick}
          anotherType={TYPE_FILE.VIDEO}
          anotherCommentType="video"
          RenderValueComponent={RenderVideoValue}
          textOptions={textOptions}
        />
      </>
      <DropFieldActions hasVideo={!!value}>
        <div>
          {!anotherUpload && !value && (
            <DropFieldAction onClick={handlerCapture}>
              <RecordIcon />
            </DropFieldAction>
          )}
        </div>
        <div>
          {!anotherUpload && !value && isAnotherEmail && (
            <DropFieldAction
              onClick={toggleAnotherUpload(TYPE_FILE_UPLOAD_CLAIM.EMAIL)}
            >
              <Icon glyph="mail" />
            </DropFieldAction>
          )}
          {!anotherUpload && !value && isAnotherQR && (
            <DropFieldAction
              onClick={toggleAnotherUpload(TYPE_FILE_UPLOAD_CLAIM.QR)}
            >
              <Icon glyph="devices" />
            </DropFieldAction>
          )}
          {anotherUpload && (
            <DropFieldAction onClick={toggleAnotherUpload()}>
              <Icon glyph="cross" />
            </DropFieldAction>
          )}
          {value && (
            <DropFieldAction onClick={handlerRemove}>
              <Icon glyph="delete" />
            </DropFieldAction>
          )}
        </div>
      </DropFieldActions>
    </Drop>
  );
}

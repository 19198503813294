import styled from '@emotion/styled/macro';
import { getColor, getFont } from 'UIUtils';
import {
  getBorderColorByParams,
  getBoxShadowColorByParams,
} from '../TextInput/utils';
import { AddressInputViewProps } from './types';
import { StyledTextInputIconWrapper } from '../TextInput/styles';

export const StyledInput = styled.input<Pick<AddressInputViewProps, 'error'>>`
  width: 100%;
  border: 1px solid ${({ error }) => getBorderColorByParams(error)};
  border-radius: 4px;
  width: inherit;
  line-height: 1.17;
  overflow: hidden;
  position: relative;
  padding: 4px 12px;
  display: block;
  font-family: ${getFont('primary')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 6px 40px 6px 12px;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
  box-shadow: 0px 0px 5px 1px ${({ error }) => getBoxShadowColorByParams(error)};

  &:disabled {
    background-color: ${getColor('disabled-input-background')};
    opacity: 0.6;
    cursor: not-allowed;
    color: ${getColor('primary-text-color')};

    &::placeholder {
      color: ${getColor('primary-text-color')};
    }
  }

  ${({ error }) =>
    !error &&
    `
    &:focus {
      border-color: ${getColor('input-focus-border')};
      box-shadow: 0px 0px 4px 1px ${getColor('input-outline')};
    }

    &:hover {
      box-shadow: 0px 0px 4px 1px ${getColor('input-outline')};
    }
  `}

  &::placeholder {
    color: ${getColor('placeholder-text-color')};
  }
`;

export const StyledInputContainer = styled.div`
  width: 100%;
  display: inline-flex;
  position: relative;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
  cursor: pointer;
  & span {
    color: ${getColor('note-text-color')};
  }

  &:hover {
    background: ${getColor('input-outline')};

    & span {
      color: ${getColor('primary-text-color')};
    }
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const IconWrapper = styled(StyledTextInputIconWrapper)`
  pointer-events: none;
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  z-index: 100;
  max-height: 156px;
  background: white;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0px 0px 5px 1px ${getColor('input-outline')};

  & > ${OptionContainer} {
    &:hover {
      background-color: ${getColor('input-outline')};
    }
  }
`;

export const TitleContainer = styled.div`
  ${({ color }) => color && `color: ${color};`}
`;

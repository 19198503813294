import { CustomUI } from 'aid-form-service/lib/ApiClient/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { ThemeType } from 'UIShared';
import { ICONS_ARIVAL, ICONS_BFM, ICONS_STANDARD } from './constants';
import { THEME_IMAGES } from './types';

function getUrlById(apiHost: string, id: string) {
  return `${apiHost}/documents/public_download/${id}/download`;
}

function generateCustomTheme(
  settings: CustomUI['settings'],
  currentImages: THEME_IMAGES,
  apiHost: string,
) {
  return {
    icon: settings?.iconImg
      ? `url(${getUrlById(apiHost, settings?.iconImg)})`
      : currentImages.icon,
    background: settings?.backgroundImg
      ? `url(${getUrlById(apiHost, settings.backgroundImg)})`
      : currentImages.background,
  };
}

function generateDefaultIcons(theme: ThemeType) {
  switch (theme) {
    case ThemeType.BFM:
      return ICONS_BFM;
    case ThemeType.STANDARD:
      return ICONS_STANDARD;
    case ThemeType.ARIVAL:
      return ICONS_ARIVAL;
    default:
      return {};
  }
}

export function generateImages(theme: ThemeType, customUI: Maybe<CustomUI>, apiHost: string) {
  const currentImages = generateDefaultIcons(theme);
  if (customUI?.enabled) {
    return generateCustomTheme(customUI?.settings, currentImages, apiHost);
  }
  return currentImages;
}

import {
  FormViewerViewProps,
  UBTViewerViewProps,
  ViewerViewProps,
} from './types';
import { FieldContainer } from '../FieldContainer';
import { usePDFViewerController } from './controller';
import { Container, Label, Loading, SVGContainer } from './styles';
import { Portal } from './components';
import { useFormFieldController } from 'Hooks';

function Viewer({ document_id }: ViewerViewProps) {
  const { document, loading, show, onToggleShow } =
    usePDFViewerController(document_id);

  return (
    <>
      <Container onClick={onToggleShow}>
        <SVGContainer
          width="10"
          height="10"
          viewBox="0 0 10 10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.659845 4.03372L3.7575 1.35057C4.41348 0.783976 5.26989 0.483938 6.18097 0.500663C7.09206 0.517287 7.93032 0.867304 8.54982 1.46726C9.18765 2.05049 9.53386 2.85041 9.49738 3.68368C9.4609 4.51696 9.09651 5.28363 8.44053 5.85023L4.96019 8.86668C4.46817 9.29995 3.84867 9.5 3.21084 9.5C2.53668 9.5 1.86241 9.25006 1.37049 8.78344C0.404742 7.85021 0.441218 6.38358 1.46162 5.50034L4.79619 2.61724C5.12411 2.33394 5.54331 2.18397 5.99885 2.2006C6.45439 2.21723 6.87346 2.38393 7.18319 2.68384C7.49294 2.98376 7.65692 3.36716 7.63873 3.78379C7.62056 4.20043 7.43829 4.58371 7.11037 4.86699L5.90771 5.90022C5.68909 6.08355 5.36103 6.08355 5.16059 5.8836C4.96014 5.68365 4.96014 5.38361 5.17876 5.20029L6.39962 4.15033C6.52723 4.05035 6.58188 3.90039 6.60006 3.75033C6.60006 3.60036 6.5454 3.45041 6.43609 3.33369C6.32678 3.21697 6.16282 3.16699 5.99874 3.15036C5.83478 3.15036 5.67082 3.20035 5.5432 3.30033L2.19042 6.20027C1.5891 6.71687 1.57091 7.58351 2.13576 8.11674C2.7006 8.6667 3.64816 8.68334 4.23119 8.16673L7.71152 5.15028C8.16707 4.75027 8.42217 4.21704 8.44032 3.65044C8.4585 3.08384 8.2217 2.53385 7.8025 2.11722C7.36515 1.70058 6.78212 1.46726 6.16262 1.45066C5.54311 1.43403 4.94177 1.65061 4.48622 2.03401L1.38857 4.71715C1.16995 4.90048 0.841892 4.90048 0.641454 4.70053C0.441009 4.51697 0.459401 4.21704 0.659845 4.03372Z"
            fill="currentColor"
          />
        </SVGContainer>
        {loading ? <Loading /> : <Label text={document?.file_name} size={12} />}
      </Container>
      {show && document_id && (
        <Portal
          document_name={document?.file_name || 'file.pdf'}
          document_id={document_id}
          type="pdf"
          close={onToggleShow}
        />
      )}
    </>
  );
}

export function UBTFormViewer({ isVisible, field }: UBTViewerViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={false}>
      <Viewer document_id={field.preloaded_document_id} />
    </FieldContainer>
  );
}

export function FormViewer({ field }: FormViewerViewProps) {
  const { ref } = useFormFieldController(field);
  return (
    <FieldContainer ref={ref} hidden={!field.isVisible} halfWidth={false}>
      <Viewer document_id={field.field?.preloaded_document_id} />
    </FieldContainer>
  );
}

import { PropsWithChildren, useMemo } from 'react';
import { ErrorText } from '../../styles';
import { DropContainer, ErrorContainer } from './styles';
import { DropViewProps } from './types';
import { useFormContext } from 'Context';

export function Drop({
  getRootProps,
  isDragActive,
  children,
  liveOnly,
  rejected,
  errorType,
  error,
  errorAccept,
}: PropsWithChildren<DropViewProps>) {
  const rootProps = useMemo(() => (liveOnly ? {} : getRootProps()), []);
  const { language } = useFormContext();
  const errorMessage = useMemo(() => {
    switch (rejected) {
      case 'file-too-large':
        return language?.getTranslate('ui.media.errors.large', {
          type: errorType,
          int: errorType === 'video' ? 20 : 15,
        });
      case 'file-invalid-type':
        return `${language?.getTranslate('ui.media.errors.type', {
          arg: errorType,
        })}${errorAccept ? ` (${errorAccept})` : ''}`;
      case 'low-internet-connection':
        return language?.getTranslate('ui.media.errors.internet');
      case 'too-many-files':
        return language?.getTranslate('ui.media.errors.drag');
    }
  }, [rejected, errorType, language, errorAccept]);

  return (
    <DropContainer
      error={error}
      isDragActive={isDragActive}
      rejected={rejected}
      {...rootProps}
    >
      {errorMessage && (
        <ErrorContainer>
          <ErrorText color="error-text-color">{errorMessage}</ErrorText>
        </ErrorContainer>
      )}
      {children}
    </DropContainer>
  );
}

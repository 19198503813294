import { Component } from 'react';
import { CameraViewProps, CameraViewState } from './types';
import * as Sentry from '@sentry/react';
import { CameraContainer, LoaderContainer, Container } from './styles';
import { CameraDevices, VideoJsPlayerWithRecord } from './controller/types';
import { Maybe } from 'yup/lib/types';
import { CURRENT_DEVICE, VIDEO_INPUT } from './controller/constants';
import { getOptions, isImageType, isVideoType } from './controller/helpers';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import { isDesktop } from 'react-device-detect';
import { Icon } from 'UIComponents';

export class Camera extends Component<CameraViewProps, CameraViewState> {
  video: Maybe<HTMLVideoElement>;
  devices: Maybe<CameraDevices>;
  player: Maybe<VideoJsPlayerWithRecord>;
  state = { loading: true };

  public startRecord(withPlayer?: VideoJsPlayerWithRecord) {
    const currentPlayer = withPlayer || this.player;
    if (currentPlayer) {
      currentPlayer.record().start();
    }
  }

  public stopRecord() {
    if (this.player) {
      this.player.record().stopDevice();
    }
  }

  setCheckInactiveStream(player: VideoJsPlayerWithRecord) {
    player.record().stream.oninactive = () => {
      try {
        const tracks = player.record().stream.getVideoTracks();
        if (tracks) {
          const error = `
                          message: The stream was cut off when recording a video. Stream is inactive.
                          device: ${CURRENT_DEVICE}.
                        `;
          Sentry.captureMessage(error);
          this.props.onError();
        }
      } catch (e) {}
    };
  }

  setupReadyHandlers(player: VideoJsPlayerWithRecord) {
    player.one('deviceReady', function () {
      player.record().enumerateDevices();
    });
    player.on('enumerateReady', () => {
      this.setCheckInactiveStream(player);
      if (!this.devices) {
        this.devices = player.record().devices.reduce(
          (acc, device) => {
            if (
              device.kind === VIDEO_INPUT &&
              !acc.devicesIds[device.deviceId]
            ) {
              return {
                ...acc,
                devices: [...acc.devices, device],
                count: acc.count + 1,
                devicesIds: { ...acc.devicesIds, [device.deviceId]: true },
              };
            }
            return acc;
          },
          { devices: [], index: 0, count: 0, devicesIds: {} } as CameraDevices
        );
        this.props.onEnumerateReady?.(this.devices);
      }
    });
    player.on('deviceReady', () => {
      if (isVideoType(this.props)) {
        this.props.isOnInitially && this.startRecord(player);
      }
      this.setState({ loading: false });
    });
  }

  public screenshot = () => {
    const video = this.video;
    const canvas = document.createElement('canvas');
    if (video && canvas) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var ctx = canvas.getContext('2d');
      if (ctx) {
        if (isDesktop) {
          ctx.translate(canvas.width, 0);
          ctx.scale(-1, 1);
        }
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const url = canvas.toDataURL('image/png');
        return {
          url,
          blob: new Promise<Blob | null>((res) => canvas.toBlob(res)),
        };
      }
    }
  };

  setupErrorsHandlers(player: VideoJsPlayerWithRecord) {
    player.on('deviceError', () => {
      const error = `
                    message: Stream cannot start due to device error.
                    deviceErrorCode: ${player.deviceErrorCode}.
                    device: ${CURRENT_DEVICE}.
                  `;
      Sentry.captureMessage(error);
      this.props.onError(player.deviceErrorCode);
    });
    player.on('error', () => {
      this.props.onError();
    });
    player.on('enumerateError', () => {
      const error = `
                  message: The player cannot access the video recorder.
                  deviceErrorCode: ${player.enumerateErrorCode}.
                  device: ${CURRENT_DEVICE}.
                `;
      Sentry.captureMessage(error);
      this.props.onError();
    });
  }

  public destructPlayer() {
    if (this.player && !this.player.isDisposed_) {
      this.player.dispose();
    }
  }

  updateCamera(player: VideoJsPlayerWithRecord, nextId: string) {
    player.record().stream.oninactive = undefined;
    if (isImageType(this.props)) {
      player.record().recordImage = {
        ...player.record().recordImage,
        deviceId: { exact: nextId },
      };
    } else {
      player.record().recordVideo = {
        ...player.record().recordVideo,
        deviceId: { exact: nextId },
      };
    }
    player.record().stopDevice();
    player.record().getDevice();
  }

  public swipeCameraToNextVideoInput() {
    const currentDevices = this.devices;
    const currentPlayer = this.player;
    if (currentDevices && currentPlayer) {
      const nextIndex = currentDevices.index + 1;
      if (nextIndex) {
        const nextDevice = currentDevices.devices[nextIndex];
        if (nextDevice) {
          currentDevices.index = nextIndex;
          this.updateCamera(currentPlayer, nextDevice.deviceId);
        } else if (currentDevices.index !== 0 && currentDevices.devices[0]) {
          currentDevices.index = 0;
          this.updateCamera(currentPlayer, currentDevices.devices[0].deviceId);
        }
      }
    }
  }

  setupFinishHandlers(player: VideoJsPlayerWithRecord) {
    player.on('finishRecord', () => {
      if (isVideoType(this.props)) {
        this.props.onComplete?.(player.recordedData);
      }
      if (!player.isDisposed_) {
        player.dispose();
      }
    });
  }

  initialPlayer(
    ref: HTMLVideoElement,
    options: VideoJsPlayerOptions
  ): VideoJsPlayerWithRecord {
    const player = videojs(ref, options, function () {
      player.record().getDevice();
    }) as VideoJsPlayerWithRecord;

    this.setupErrorsHandlers(player);
    this.setupReadyHandlers(player);
    this.setupFinishHandlers(player);

    return player;
  }

  componentDidMount() {
    if (this.video) {
      const options = getOptions(this.props.type);
      const instancePlayer = this.initialPlayer(this.video, options);
      this.player = instancePlayer;
    }
  }

  componentWillUnmount() {
    if (!this.player?.isDisposed_) {
      this.player?.dispose();
    }
  }

  render() {
    return (
      <Container>
        <CameraContainer loading={this.state.loading}>
          <LoaderContainer>
            <Icon glyph="spinner" />
          </LoaderContainer>
          <div data-vjs-player>
            <video
              ref={(node) => (this.video = node)}
              playsInline
              tabIndex={-1}
              crossOrigin="use-credentials"
              className="video-js vjs-default-skin"
            />
          </div>
        </CameraContainer>
      </Container>
    );
  }
}

import { useCallback, useState } from 'react';

export function useModalController() {
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(
    (value) => setOpen((open) => (typeof value == 'boolean' ? value : !open)),
    []
  );

  return { open, toggleOpen };
}

import {
  osName,
  engineName,
  osVersion,
  engineVersion,
  browserName,
  browserVersion,
} from 'react-device-detect';

export const CURRENT_DEVICE = `${osName} : ${osVersion} / ${browserName} : ${browserVersion} / ${engineName} : ${engineVersion} `;

export const VIDEO_INPUT = 'videoinput';

import styled from '@emotion/styled';
import InputMask from 'react-input-mask';
import { getColor, getFont } from 'UIUtils';
import { Icon } from '../Icon';
import { TextInputViewProps } from './types';
import {
  getBorderColorByParams,
  getColorByParams,
  getBoxShadowColorByParams,
} from './utils';

export const StyledTextInput = styled(InputMask)<TextInputViewProps>`
  position: relative;
  display: block;
  width: inherit;
  border: 1px solid ${({ error }) => getBorderColorByParams(error)};
  border-radius: 4px;
  color: ${({ error }) => getColorByParams(error)};
  outline: none;
  font-family: ${getFont('primary')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.17;
  padding: ${({ error }) => `6px ${error ? 60 : 34}px 6px 12px`};
  box-shadow: 0px 0px 5px 1px ${({ error }) => getBoxShadowColorByParams(error)};
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
  &:disabled {
    background-color: ${getColor('disabled-input-background')};
    opacity: 0.6;
    cursor: not-allowed;
    color: ${getColor('primary-text-color')};

    &::placeholder {
      color: ${getColor('primary-text-color')};
    }
  }

  ${({ error }) =>
    !error &&
    `
  &:focus {
    border-color: ${getColor('input-focus-border')};
    box-shadow: 0px 0px 4px 1px ${getColor('input-outline')};
  }
  `}

  &::placeholder {
    color: ${getColor('placeholder-text-color')};
  }
`;

export const StyledTextInputIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  * {
    flex-shrink: 0;
  }
  & > div {
    margin-right: 10px;
  }
`;
export const StyledTextInputIcon = styled(Icon)<{ action?: boolean }>`
  cursor: ${({ action }) => (action ? 'pointer' : 'default')};
`;

export const StyledTextInputWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  position: relative;
`;

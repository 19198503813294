import { useCallback, useMemo, useState } from 'react';
import { IValue, TypeConfirmedField } from '../types';
import { useApiContext } from 'Context';
import { FieldControllerDelegate } from 'aid-form-service/lib/State/StateControllers/FieldControllers/FieldController/index.delegate';

export function useConfirmFieldController(
  value?: IValue,
  onChangeConfirm?: (phone: string) => void,
  type?: TypeConfirmedField,
  field?: FieldControllerDelegate
) {
  const api = useApiContext();
  const [codeValue, setCodeState] = useState('');
  const [loading, setLoading] = useState(false);

  const onChange = useCallback((e) => {
    setCodeState(e);
  }, []);

  const disabled = useMemo(() => {
    return !codeValue || codeValue.indexOf('_') !== -1;
  }, [codeValue]);

  const onConfirmPhone = useCallback(async () => {
    if (value) {
      setLoading(true);
      try {
        const res: any = await api.core.public.validations.verify_code.post(
          {},
          {
            data: {
              channel: type,
              to: value,
              code: codeValue,
            },
          }
        );
        if (res?.data?.success) {
          onChangeConfirm?.(value);
        } else if (field) {
          field.setError({
            message: JSON.stringify({
              type: 'confirmationCode',
            }),
          });
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  }, [codeValue, value, onChangeConfirm, type, field]);

  return { onChange, codeValue, disabled, onConfirmPhone, loading };
}

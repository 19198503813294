import { useCallback, useMemo } from 'react';
import { useVerificationFieldContext } from '../../../context';
import { useFieldContext } from '../../../../../context';

export function useImageValueController() {
  const { onChange, value } = useFieldContext();
  const { setCurrentState, isDocument } = useVerificationFieldContext();

  const type = useMemo(
    () => (isDocument ? 'Document photo' : 'Face photo'),
    [isDocument]
  );

  const handleRemove = useCallback(
    (e) => {
      e.preventDefault();
      setCurrentState('intro');
      onChange?.(undefined);
    },
    [setCurrentState, onChange]
  );

  return { value, type, handleRemove };
}

import { ErrorTemplate } from 'UIComponents';
import { ThemeType, UIThemeProvider } from 'UIShared';
import { ErrorExpiredViewProps } from './types';
import { PropsWithChildren } from 'react';

export function ErrorExpired({
  title,
  description,
}: PropsWithChildren<ErrorExpiredViewProps>) {
  return (
    <UIThemeProvider type={ThemeType.SYSTEM}>
      <ErrorTemplate
        title={title}
        description={description}
        customIcon={
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1122_1271)">
              <path
                d="M35.028 44.972C28.6309 38.5749 18.2231 38.5749 11.826 44.972L4.79783 52.0002C-1.59928 58.3973 -1.59928 68.805 4.79783 75.2021C11.1949 81.5992 21.6027 81.5992 27.9998 75.2021L35.028 68.1739C41.4251 61.7768 41.4251 51.3691 35.028 44.972ZM21.3707 68.5731C18.6192 71.3239 14.1777 71.3245 11.4269 68.5731C8.67539 65.8222 8.676 61.3801 11.4269 58.6292L18.4551 51.601C21.2065 48.8501 25.6475 48.8501 28.3989 51.601C31.1504 54.3519 31.1498 58.7934 28.3989 61.5449L21.3707 68.5731Z"
                fill="#DCE3F5"
              />
              <path
                d="M35.028 68.1739L27.9998 75.2021C21.6027 81.5992 11.195 81.5992 4.79785 75.2021L11.4269 68.5731C14.1777 71.3245 18.6193 71.3239 21.3707 68.5731L28.3989 61.5448C31.1498 58.7934 31.1498 54.3525 28.3989 51.601L35.028 44.972C41.4251 51.3691 41.4251 61.7768 35.028 68.1739V68.1739Z"
                fill="#1F3B8E"
              />
              <path
                d="M75.2018 4.79798C68.8047 -1.59912 58.397 -1.59912 51.9999 4.79798L44.9717 11.8262C38.5746 18.2233 38.5746 28.631 44.9717 35.0281C51.3688 41.4252 61.7765 41.4252 68.1736 35.0281L75.2018 27.9999C81.5989 21.6028 81.5989 11.1951 75.2018 4.79798V4.79798ZM61.5445 28.3991C58.7937 31.1499 54.3522 31.1499 51.6007 28.3991C48.8492 25.6482 48.8498 21.2061 51.6007 18.4552L58.6289 11.427C61.3804 8.67555 65.8213 8.67555 68.5728 11.427C71.3242 14.1779 71.3242 18.6194 68.5728 21.3709L61.5445 28.3991Z"
                fill="#DCE3F5"
              />
              <path
                d="M63.945 47.7222L62.4624 52.1698L52.5186 48.855L54.0011 44.408L63.945 47.7222Z"
                fill="#1F3B8E"
              />
              <path
                d="M48.851 52.5159L52.1652 62.4598L47.7175 63.9423L44.4033 53.9984L48.851 52.5159Z"
                fill="#1F3B8E"
              />
              <path
                d="M32.2797 16.0565L35.5939 26.0004L31.1462 27.4829L27.832 17.5391L32.2797 16.0565Z"
                fill="#1F3B8E"
              />
              <path
                d="M27.4834 31.1481L26.0015 35.5957L16.0576 32.2815L17.5396 27.8339L27.4834 31.1481Z"
                fill="#1F3B8E"
              />
              <path
                d="M75.2018 27.9999L68.1736 35.0281C61.7765 41.4252 51.3688 41.4252 44.9717 35.0281L51.6007 28.3991C54.3516 31.1499 58.7937 31.1499 61.5446 28.3991L68.5728 21.3709C71.3242 18.6194 71.3242 14.1779 68.5728 11.427L75.2018 4.79797C81.5989 11.1951 81.5989 21.6028 75.2018 27.9999V27.9999Z"
                fill="#1F3B8E"
              />
              <path
                d="M58.2304 21.7701C55.4795 19.0186 51.038 19.0186 48.2865 21.7701L36.6855 33.371L46.6294 43.3143L58.2304 31.7133C60.9818 28.9624 60.9818 24.5209 58.2304 21.7701Z"
                fill="#1F3B8E"
              />
              <path
                d="M58.2301 31.7133L46.6292 43.3143L41.6572 38.3429L58.2301 21.7701C60.9816 24.5209 60.9816 28.9624 58.2301 31.7133Z"
                fill="#15265A"
              />
              <path
                d="M33.3711 36.6852L21.7702 48.2862C19.0193 51.0376 19.0193 55.4792 21.7702 58.23C24.521 60.9815 28.9632 60.9809 31.714 58.23L43.315 46.6291L33.3711 36.6852Z"
                fill="#1F3B8E"
              />
              <path
                d="M38.3434 41.6571L43.3147 46.6291L31.7138 58.23C28.9629 60.9809 24.5214 60.9815 21.7705 58.23L38.3434 41.6571Z"
                fill="#15265A"
              />
            </g>
            <defs>
              <clipPath id="clip0_1122_1271">
                <rect width="80" height="80" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      />
    </UIThemeProvider>
  );
}

import { useApiContext } from 'Context';
import { useCallback, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

export function useIntercomWithFormController() {
  const api = useApiContext();
  const [loading, setLoading] = useState(true);
  const [userHash, setUserHash] = useState<string>();

  const init = useCallback(async () => {
    if (FEAuthService.store.options.access_token) {
      try {
        const res: any = await api.core.public.intercom_token();
        if (res?.data?.success) {
          setUserHash(res?.data?.data?.token);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return { loading, userHash };
}

export function useIntercomController(userHash?: string) {
  const [isOpen, setIsOpen] = useState(false);

  const { boot, show, hide } = useIntercom();

  useEffect(() => {
    boot({
      userHash,
      hideDefaultLauncher: true,
      horizontalPadding: 80,
      verticalPadding: 100,
    });
  }, []);

  useEffect(() => {
    (window as any).Intercom?.('onHide', () =>
      setIsOpen((currentOpen) => (currentOpen ? !currentOpen : currentOpen))
    );
  }, []);

  const toggleOpen = useCallback(
    () =>
      setIsOpen((currentOpen) => {
        currentOpen ? hide() : show();
        return !currentOpen;
      }),
    [show, hide]
  );

  return { toggleOpen, isOpen };
}

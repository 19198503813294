import { DescriptionSize } from 'aid-form-service';

export const NoteSizes = {
  [DescriptionSize.SMALL]: 12,
  [DescriptionSize.MIDDLE]: 16,
  [DescriptionSize.BIGGER]: 20,
  SMALL: 12,
  MIDDLE: 16,
  BIGGER: 20,
};

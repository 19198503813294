import FingerprintJS, {} from '@fingerprintjs/fingerprintjs-pro';
import { useMemo } from 'react';

export function useFingerprintProviderController() {
  const agent = useMemo(
    () =>
      FingerprintJS.load({
        token: process.env.REACT_APP_FINGERPRINT_TOKEN || '',
        endpoint: process.env.REACT_APP_FINGERPRINT_ENDPOINT,
      }),
    []
  );

  return agent;
}

export function convertUTCToLocalDate(date?: Date) {
  if (!date) {
    return undefined;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
}

export function convertLocalToUTCDate(date?: Date) {
  if (!date) {
    return undefined;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date;
}

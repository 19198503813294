import { Svg } from './styles';

export function DocumentFocus() {
  return (
    <Svg
      width="320"
      height="184"
      viewBox="0 0 320 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 182H8C4.68629 182 2 179.314 2 176V158"
        stroke="#0F7C62"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M26 2H8C4.68629 2 2 4.68629 2 8V26"
        stroke="#0F7C62"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M294 182H312C315.314 182 318 179.314 318 176V158"
        stroke="#0F7C62"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M294 2H312C315.314 2 318 4.68629 318 8V26"
        stroke="#0F7C62"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </Svg>
  );
}

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { AuthContainer } from 'Container';
import { ApiContextProvider } from 'Context';
import {
  useApi,
  useApiNotAuthorized,
  axiosCustomerClient,
  axiosClient,
  setHeaderNotAuthorized,
  setHeaderAuthorized,
} from 'Api';
import { GlobalLoader } from 'UIComponents';
import { lazy, Suspense } from 'react';

const VerificationFlow = lazy(() => import('../verificationFlow'));
const External = lazy(() => import('../forms/components/External'));
const Internal = lazy(() => import('../forms/components/Internal'));
const Error404 = lazy(() => import('../globalErrors/404'));
const MobileUploadFiles = lazy(() => import('../upload'));
const NewUbt = lazy(() => import('../forms/components/Ubt/new'));

function RedirectToCustomerAuth() {
  const location = useLocation();

  window.location.href = `${window.location.origin}/customer_oidc/customer_flow${location.search}`;

  return <GlobalLoader />;
}

export function Routes() {
  return (
    <Router>
      <Suspense fallback={<GlobalLoader />}>
        <Switch>
          <Route
            path={[
              '/upload/:id/:lang',
              '/upload/:id',
              '/form/verification_flows/:flow_id/:lang',
              '/form/verification_flows/:flow_id',
              '/external/forms/:formId',
              '/external/form_invitation/:formId',
            ]}
          >
            <ApiContextProvider
              useApi={useApiNotAuthorized}
              setHeader={setHeaderNotAuthorized}
              apiHost={
                window.location.origin ||
                process.env.REACT_APP_CUSTOMER_API_HOST ||
                ''
              }
              axiosInstance={axiosCustomerClient}
            >
              <Route path={['/upload/:id/:lang', '/upload/:id']}>
                <MobileUploadFiles />
              </Route>
              <Route
                path={[
                  '/form/verification_flows/:flow_id/:lang',
                  '/form/verification_flows/:flow_id',
                ]}
              >
                <VerificationFlow />
              </Route>
              <Route path="/external/forms/:formId">
                <External />
              </Route>
              <Route path="/external/form_invitation/:formId">
                <External asFormInvitation />
              </Route>
            </ApiContextProvider>
          </Route>
          <Route
            path={[
              '/forms/:formId',
              '/replay/:formId/:submissionRef',
              '/new_replay/:formId/:submissionRef',
            ]}
          >
            <ApiContextProvider
              useApi={useApi}
              setHeader={setHeaderAuthorized}
              apiHost={process.env.REACT_APP_API_HOST || ''}
              axiosInstance={axiosClient}
            >
              <AuthContainer>
                <Route path="/forms/:formId">
                  <Internal />
                </Route>
                <Route path="/replay/:formId/:submissionRef">
                  <NewUbt />
                </Route>
              </AuthContainer>
            </ApiContextProvider>
          </Route>
          <Route path="/oidc/customer_flow">
            <RedirectToCustomerAuth />
          </Route>
          <Route path="/">
            <Error404 />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

import { useState } from 'react';
import { Icon } from '../Icon';
import {
  Wrapper,
  Title,
  Collapse,
  Arrow,
  Header,
  Description,
  Control,
} from './styles';
import {
  CollapsedInfoViewProps,
  FormCollapsedInfoViewProps,
  UBTFormCollapsedInfoViewProps,
} from './types';
import { CollapseSizes } from './constants';
import { DescriptionAlign, DescriptionSize } from 'aid-form-service';
import { useFormFieldController } from 'Hooks';
import { FieldContainer } from '../FieldContainer';
import { parseAlign } from './utils';

export function CollapsedInfo({
  label,
  description,
  disabled,
  open,
  size = DescriptionSize.SMALL,
  align = DescriptionAlign.LEFT,
}: CollapsedInfoViewProps) {
  const [isOpen, setOpen] = useState(open === 'Open' ? true : false);

  const handlerToggle = () => {
    setOpen(!isOpen);
  };

  return (
    <Wrapper open={isOpen} disabled={disabled}>
      <Header onClick={handlerToggle}>
        <Title open={isOpen} text={label} color="input-focus-border" />
        <Control>
          <Icon glyph="info" color="input-focus-border" />
          <Arrow open={isOpen} />
        </Control>
      </Header>
      {isOpen && (
        <Collapse>
          <Description
            size={CollapseSizes[size]}
            align={parseAlign(align)}
            text={description}
            color="input-focus-border"
          />
        </Collapse>
      )}
    </Wrapper>
  );
}

export function UBTFormCollapseInfo({
  field,
  isVisible,
}: UBTFormCollapsedInfoViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={!!field.options?.half_width}>
      <CollapsedInfo
        size={field.options?.description?.size || undefined}
        align={field.options?.description?.align || undefined}
        open={field.options?.open}
        label={field.name}
        description={field.options?.description?.text}
      />
    </FieldContainer>
  );
}

export function FormCollapseInfo({ field }: FormCollapsedInfoViewProps) {
  const { ref } = useFormFieldController(field);
  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <CollapsedInfo
        size={field.options?.description?.size || undefined}
        align={field.options?.description?.align || undefined}
        open={field.options?.open}
        label={field.name}
        description={field.options?.description?.text}
      />
    </FieldContainer>
  );
}

import { ErrorTemplate } from 'UIComponents';
import { ThemeType, UIThemeProvider } from 'UIShared';

export function Error403() {
  return (
    <UIThemeProvider type={ThemeType.SYSTEM}>
      <ErrorTemplate title="Forbidden" code={403} />
    </UIThemeProvider>
  );
}

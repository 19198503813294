import { Suspense, lazy, useMemo } from 'react';
import { PortalViewProps } from './types';
import { Container } from './styles';
import { createPortal } from 'react-dom';

const components = {
  pdf: lazy(() => import('./components/PDF')),
};

export function Portal({
  document_id,
  type,
  close,
  document_name,
}: PortalViewProps) {
  const Component = useMemo(() => components[type], [type]);
  return createPortal(
    <Container>
      <Suspense fallback={<></>}>
        <Component
          document_id={document_id}
          document_name={document_name}
          close={close}
        />
      </Suspense>
    </Container>,
    document.getElementById('theme')!
  );
}

import { THEME_COLORS } from 'UIShared';

export const AID_COLORS: THEME_COLORS = {
  'primary-text-color': 'rgb(25, 29, 38)',
  'note-text-color': 'rgba(25, 29, 38, 0.5)',
  'default-text-color': 'rgb(104, 104, 104)',
  'active-text-color': 'rgb(104, 104, 104)',
  'powered-text-color': '',
  'error-text-color': '#871d1d',
  'warning-text-color': '#8d6213',
  'placeholder-text-color': 'rgba(106, 57, 158, 0.5)',
  'success-text-color': '#025a2b',
  'submit-button-text-color': '',
  'secondary-button-border-color': 'rgba(25, 29, 38, 0.5)',
  'secondary-button-background-color': '#fff',
  'media-primary-text-color': 'rgba(106, 57, 158, 1)',
  'default-state-color': '#BCBEC0',
  'media-secondary-text-color': 'rgba(106, 57, 158, 0.5)',

  'input-border': 'rgba(106, 57, 158, 0.5)',
  'input-outline': 'rgba(106, 57, 158, 0.5)',
  'input-outline-secondary': 'rgba(106, 57, 158, 0.5)',

  'input-focus-border': 'rgba(106, 57, 158, 1)',
  'input-focus-background': 'rgba(106, 57, 158, 1)',
  'input-focus-background-reverse': 'rgba(106, 57, 158, 1)',

  'scrollbar-background': 'rgba(106, 57, 158, 0.3)',

  'disabled-input-background': 'rgba(106, 57, 158, 0.1)',

  white: '#fff',
};

export const ARIVAL_COLORS: THEME_COLORS = {
  'primary-text-color': 'rgb(25, 29, 38)',
  'note-text-color': 'rgba(25, 29, 38, 0.5)',
  'default-text-color': 'rgb(104, 104, 104)',
  'active-text-color': 'rgb(104, 104, 104)',
  'powered-text-color': '',
  'error-text-color': '#871d1d',
  'warning-text-color': '#8d6213',
  'placeholder-text-color': 'rgb(117, 117, 117);',
  'success-text-color': '#025a2b',
  'submit-button-text-color': '',
  'secondary-button-border-color': 'rgba(25, 29, 38, 0.5)',
  'secondary-button-background-color': '#fff',
  'media-primary-text-color': 'rgba(25, 29, 38, 1)',
  'default-state-color': '#BCBEC0',
  'media-secondary-text-color': 'rgba(25, 29, 38, 0.5)',

  'input-border': 'rgba(25, 29, 38, 0.5)',
  'input-outline': 'rgba(25, 29, 38, 0.5)',
  'input-outline-secondary': 'rgb(220, 227, 245, 0.2)',

  'input-focus-border': 'rgba(25, 29, 38, 1)',
  'input-focus-background': 'rgba(25, 29, 38, 1)',
  'input-focus-background-reverse': 'rgba(25, 29, 38, 1)',

  'scrollbar-background': 'rgba(25, 29, 38, 0.3)',

  'disabled-input-background': 'rgba(25, 29, 38, 0.2)',

  white: '#fff',
};

export const STANDARD_COLORS: THEME_COLORS = {
  'primary-text-color': '#111D41',
  'note-text-color': '#4f4f4f',
  'default-text-color': '#8A8C91',
  'active-text-color': '#2950BB',
  'powered-text-color': '#5A79CB',
  'error-text-color': 'rgb(236, 94, 133)',
  'warning-text-color': '#ffb448',
  'placeholder-text-color': '#5A79CB',
  'success-text-color': '#29BB64',
  'submit-button-text-color': '#2950BB',
  'secondary-button-border-color': '#2950BB',
  'secondary-button-background-color': '#fff',
  'media-primary-text-color': '#111D41',
  'default-state-color': '#BCBEC0',
  'media-secondary-text-color': 'rgba(90, 121, 203, 1)',

  'input-border': '#5A79CB',
  'input-outline': '#DCE3F5',
  'input-outline-secondary': 'rgb(220, 227, 245, 0.2)',

  'input-focus-border': '#2950BB',
  'input-focus-background': '#2950BB',
  'input-focus-background-reverse': '#2950BB',

  'scrollbar-background': '#DCE3F5',

  'disabled-input-background': '#F6F8FF',

  white: '#fff',
};

export const BFM_COLORS: THEME_COLORS = {
  'primary-text-color': '#111D41',
  'note-text-color': '#4f4f4f',
  'error-text-color': 'rgb(236, 94, 133)',
  'default-text-color': '#BCBEC0',
  'active-text-color': '#21252E',
  'powered-text-color': '#BCBEC0',
  'warning-text-color': '#ffb448',
  'placeholder-text-color': '#d6d5d7',
  'success-text-color': 'rgb(47, 196, 124)',
  'submit-button-text-color': 'rgb(162, 0, 255)',
  'secondary-button-border-color': '#EEF2FF',
  'secondary-button-background-color': '#EEF2FF',
  'default-state-color': '#BCBEC0',
  'media-primary-text-color':
    'linear-gradient(228.15deg, rgb(162, 0, 255) -1.99%, rgb(11, 63, 255) 70.55%)',
  'media-secondary-text-color': 'rgb(11, 63, 255)',

  'input-border': 'rgba(11, 63, 255, 0.8)',
  'input-outline': 'rgba(11, 63, 255, 0.6)',
  'input-outline-secondary': 'rgba(162, 0, 255, 0.2)',

  'input-focus-border': 'rgb(11, 63, 255)',
  'input-focus-background':
    'linear-gradient(228.15deg, rgb(162, 0, 255) -1.99%, rgb(11, 63, 255) 70.55%)',
  'input-focus-background-reverse':
    'linear-gradient(228.15deg, rgb(11, 63, 255) -1.99%, rgb(162, 0, 255) 70.55%)',

  'disabled-input-background': 'rgba(11, 63, 255, 0.05)',

  'scrollbar-background': '#EEF2FF',

  white: '#fff',
};

import { KeyboardEvent, useCallback, useLayoutEffect, useState } from 'react';
import { ICardInputOnChange } from '../types';
import { PatternsCard } from '../constants';

export function useCardInputController(
  value?: string,
  onChange?: ICardInputOnChange
) {
  const patternParse = useCallback((value: string, pattern: number[]) => {
    const tmpPattern = [...pattern];
    let returnedString = '';
    let tmpIndex = tmpPattern.shift();
    for (let i = 0, length = value.length; i < length; i++) {
      if (i + 1 === tmpIndex) {
        const nextLength = tmpPattern.shift();
        if (nextLength) {
          tmpIndex = tmpIndex + nextLength;
        }
        returnedString = `${returnedString}${value[i]}  `;
      } else {
        returnedString = returnedString + value[i];
      }
    }
    return returnedString;
  }, []);

  const parseCardNumber = useCallback(
    (value: string) => {
      let nextValue;
      if (value.length <= 13) {
        nextValue = patternParse(value, PatternsCard[13]);
      } else if (value.length <= 15) {
        nextValue = patternParse(value, PatternsCard[15]);
      } else if (value.length <= 16) {
        nextValue = patternParse(value, PatternsCard[16]);
      } else if (value.length <= 18) {
        nextValue = patternParse(value, PatternsCard[18]);
      } else {
        nextValue = patternParse(value, PatternsCard[19]);
      }
      return nextValue?.trim();
    },
    [patternParse]
  );

  const [fieldValue, setFieldValue] = useState<string | undefined>();

  useLayoutEffect(() => {
    setFieldValue(value ? parseCardNumber(value) : value);
  }, [value]);

  const onChangeFieldValue = useCallback(
    (value?: string) => {
      const withoutSpacesValue = (value || '').replace(/ /g, '');
      if (withoutSpacesValue.length <= 19) {
        onChange?.(withoutSpacesValue);
      }
    },
    [onChange]
  );

  const onKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (
      isNaN(Number(e.nativeEvent.key)) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete'
    ) {
      e.preventDefault();
      return;
    }
  }, []);

  return { fieldValue, onChangeFieldValue, onKeyPress };
}

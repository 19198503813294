export const TYPE_FILE_UPLOAD_CLAIM = {
  QR: 'qr',
  EMAIL: 'email',
};

export const FULL_UPLOADS = [
  TYPE_FILE_UPLOAD_CLAIM.QR,
  TYPE_FILE_UPLOAD_CLAIM.EMAIL,
];

export enum TYPE_FILE {
  IMG = 'image',
  VIDEO = 'video',
  DOC = 'document',
  IMG_MOBILE = 'image/mobile',
  VIDEO_MOBILE = 'video/mobile',
};

export const CAM_ERRORS_MESSAGES = {
  PERMISSION: 'Please grant access to your webcam',
  USED: 'Your device is already in use',
  LENGTH: "Sorry, but we didn't find any cameras connected to your device",
  SIZE: 'Your camera resolution is too low for this media to be accepted',
};

export const TYPE_ANOTHER_UPLOAD = {
  IMAGE: 'an image',
  VIDEO: 'a video',
  DOC: 'a document',
};

export const COMMENT_BY_TYPE = {
  QR: 'create QR code',
  EMAIL: 'send link to email',
};

export const DEFAULT_COMMENT =
  'To upload an image from another device, choose from one of the methods below and follow the instructions';

export const WARNING_MESSAGE =
  'Do not close this window until you upload the file. Closing this window will lead to termination of the session and you will have to start over.';

import {
  createContext,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CustomerContextType, CustomerProviderViewProps } from './types';
import { useApiContext, useApiHostContext, useSetHeaderContext } from '../Api';
import { GlobalLoader } from 'UIComponents';
import { ErrorWrong } from 'GlobalErrors';
import {
  ResponseMessageTypes,
  ResponseWorkerMessages,
  SessionWorker,
} from 'fe-auth-worker';
import { axiosCustomerWSClient } from 'Api';

export const CustomerContext = createContext<CustomerContextType>({});

export function useCustomerContext() {
  const context = useContext(CustomerContext);

  return context;
}

export function CustomerProvider({
  children,
  ...props
}: PropsWithChildren<CustomerProviderViewProps>) {
  const api = useApiContext();
  const { apiHost } = useApiHostContext();
  const setHeader = useSetHeaderContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const wssWorker = useRef<SessionWorker>();

  const getCredentials = useRef((isCustomer?: boolean) => {
    if (isCustomer) {
      return axiosCustomerWSClient.get('/auth_customer/ws_credentials');
    } else {
      return api.auth.ws_credentials.get();
    }
  });

  const ping = useRef((isCustomer?: boolean) => {
    if (isCustomer) {
      return api.auth_customer.ping.get();
    } else {
      return api.auth.ping.get();
    }
  });

  const initWorker = useRef(async (isCustomer?: boolean) => {
    const res: any = await getCredentials.current(isCustomer);
    if (res.data?.success && res.data?.data?.session_token) {
      wssWorker.current = new SessionWorker();
      wssWorker.current.onMessage = (msg: ResponseWorkerMessages) => {
        if (
          msg.source === 'session-worker' &&
          msg.type === ResponseMessageTypes.FINISH
        ) {
          let location;
          if (isCustomer) {
            location = `${apiHost}/auth_customer/login?redirect_uri=${window.location.href}`;
          } else {
            location = `${apiHost}/auth/login?redirect_uri=${window.location.href}`;
          }
          window.location.href = location;
        } else if (
          msg.source === 'session-worker' &&
          msg.type === ResponseMessageTypes.NEED_PING
        ) {
          ping.current(isCustomer);
        }
      };
      wssWorker.current.connect({
        session_token: res.data.data.session_token,
        path: `${process.env.REACT_APP_WSS_PATH}/ws`,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID || '',
      });
    }
  });

  const loadUser = useRef(async (props: CustomerProviderViewProps) => {
    try {
      setLoading(true);
      const res: any = await api.core.public.users.me.get(props);
      if (res?.data?.success) {
        if (res?.data?.data?.current_customer_id) {
          setHeader('x-customer-id', res.data.data.current_customer_id);
        }
        if (res?.data?.data?.current_account_id) {
          setHeader('x-account-id', res?.data?.data?.current_account_id);
        }
        if (res?.data?.data?.client_id) {
          setHeader('x-client-id', res?.data?.data?.client_id);
        }
        // initWorker.current(!res?.data?.data?.current_account_id);
      }
      setLoading(false);
    } catch (e: any) {
      const code = e?.response?.status;
      if (code === 302 || code === 301 || code === 303 || !code) {
        return;
      }
      setError(true);
    }
  });

  useLayoutEffect(() => {
    loadUser.current(props);

    return () => {
      if (wssWorker.current) {
        wssWorker.current.destroy();
      }
    };
  }, []);

  if (error) {
    return (
      <ErrorWrong
        title="Something went wrong"
        description="Please contact technical support."
      />
    );
  }

  if (loading) {
    return <GlobalLoader />;
  }

  return (
    <CustomerContext.Provider value={{}}>{children}</CustomerContext.Provider>
  );
}

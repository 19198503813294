import { THEME_IMAGES } from './types';
import BFM_BG from './static/bfm-bg.png';
import STANDARD_BG from './static/standard-bg.png';

export const ICONS_BFM: THEME_IMAGES = {
  background: `url(${BFM_BG})`,
};

export const ICONS_ARIVAL: THEME_IMAGES = {
  background: `linear-gradient(90deg, #ebebeb 0%, #fcfcfc 46.35%, #ebebeb 100%)`,
};

export const ICONS_STANDARD: THEME_IMAGES = {
  background: `url(${STANDARD_BG})`,
};

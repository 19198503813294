import { useLanguageContext } from 'Context';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SIGNATURE_MIME_TYPE } from './constants';
import SignaturePad from 'react-signature-pad-wrapper';
import { useDropFieldProps } from 'Hooks';
import { SignatureFieldViewProps } from '../types';

function checkMimeType(type: string) {
  if (/image\/.*/gm.test(type)) {
    return true;
  }
  return false;
}

export function useSignatureController({
  options,
  onChange,
}: Pick<SignatureFieldViewProps, 'options' | 'onChange' | 'value'>) {
  const sign = useRef<SignaturePad>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const [useSignPad, setUseSignPad] = useState(false);
  const language = useLanguageContext();

  const liveOnly = useMemo(() => options?.liveOnly, [options]);

  const uploadSign = useCallback(
    async (image) => {
      if (!image) {
        return false;
      } else {
        try {
          await onChange?.(image);
        } catch (e) {}
      }
    },
    [onChange]
  );

  const handlerConfirm = useCallback(async () => {
    if (sign.current) {
      const image64 = sign.current.toDataURL(SIGNATURE_MIME_TYPE);
      const image = await (await fetch(image64)).blob();
      uploadSign(image);
      setUseSignPad(false);
    }
  }, [sign, uploadSign]);

  const onDropAccepted = (files: File[]) => {
    uploadSign(files[0]);
  };

  const { onSetRejected, ...dropFieldProps } = useDropFieldProps(!!liveOnly, {
    accept: 'image/*',
    maxSize: 15728640,
    onDropAccepted,
  });

  const handlerChange = useCallback(
    (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      if (file) {
        if (!checkMimeType(file.type)) {
          if (inputFile.current) {
            inputFile.current.value = '';
          }
          return onSetRejected('file-invalid-type');
        }
        if (file?.size > 15728640) {
          if (inputFile.current) {
            inputFile.current.value = '';
          }
          return onSetRejected('file-too-large');
        }
        uploadSign(file);
      }
    },
    [uploadSign, onSetRejected]
  );

  const handlerButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      if (inputFile.current) {
        inputFile.current.click();
      }
    },
    [inputFile]
  );

  function clearValue() {
    sign.current?.clear();
    onChange?.(undefined);
    if (inputFile.current) {
      inputFile.current.value = '';
    }
  }

  const textOptions = useMemo(
    () => ({
      label: language?.getTranslate('ui.media.attach.label', {
        arg: 'signature',
      }),
      description: language?.getTranslate('ui.media.attach.description', {
        type: 'signature',
        int: 15,
      }),
      actionLabel: language?.getTranslate('ui.media.browse_files'),
      liveOnlyLabel: language?.getTranslate('ui.media.signature.draw'),
    }),
    [language]
  );

  const canvasProps = useMemo(() => ({ width: '388px', height: '282px' }), []);

  return {
    canvasProps,
    clearValue,
    handlerButtonClick,
    handlerChange,
    dropFieldProps,
    handlerConfirm,
    setUseSignPad,
    useSignPad,
    inputFile,
    liveOnly,
    sign,
    textOptions,
  };
}

import styled from '@emotion/styled';
import { getColor } from 'UIUtils';
import { Button, Text } from 'UIComponents';

export const IconContainer = styled.div`
  margin-top: 18px !important;
  opacity: 0.18;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  min-height: 257px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 24px;

  & > div {
    margin-top: 12px;
  }
`;

export const DescriptionText = styled(Text)`
  line-height: 20px;
`;

export const WarningText = styled(DescriptionText)`
  color: ${getColor('error-text-color')} !important;
`;

export const ActionUploadContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  & > button:not(:last-child) {
    margin-right: 20px !important;
  }
`;

export const ActionUploadItem = styled(Button)`
  background: ${getColor('input-focus-background')};
  color: ${getColor('white')};
  height: auto;
  max-height: 50px;
  font-size: 12px;
  margin: 0 !important;
  min-height: 40px;
`;

export const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  margin-top: 0;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & button {
    margin-top: 20px!important;
  }
`;

import { useCallback, useEffect, useRef } from 'react';
import { useContext } from 'react';
import { ConfirmModalContext } from './ConfirmModalContext';
import { UseConfirmModalOptions } from './types';

export function useConfirmModal({
  onConfirmCallback,
  onFinallyCallback,
  title,
  body,
  action,
}: UseConfirmModalOptions) {
  const context = useContext(ConfirmModalContext);
  const refUniqID = useRef<string>();

  const callback = useCallback(
    (...props) => onConfirmCallback?.(...props),
    [onConfirmCallback]
  );
  const finalCallback = useCallback(
    (...props) => onFinallyCallback?.(...props),
    [onFinallyCallback]
  );

  useEffect(() => {
    const id = context.update({ title, body, action }, refUniqID.current);
    if (id) {
      refUniqID.current = id;
    }
    return () => {
      if (refUniqID.current) {
        context.remove(refUniqID.current);
      }
    };
  }, [context, title, body, action]);

  const show = useCallback(
    async (...props) => {
      if (typeof props?.[0]?.preventDefault === 'function') {
        props?.[0]?.preventDefault();
      }
      try {
        await context.show(refUniqID.current);
        return callback(...props);
      } catch (e) {
      } finally {
        return finalCallback(...props);
      }
    },
    [context, callback, finalCallback]
  );

  return { show };
}

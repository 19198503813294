import { useFormContext } from 'Context';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import QRCode from 'react-qr-code';
import { TextInput } from 'UIComponents';
import { Icon } from '../Icon';
import {
  PlaceholderDescription,
  PlaceholderLabel,
} from '../MediaFields/components/Default/styles';
import {
  useAnotherUploadActionController,
  useEmailFormController,
} from './controller';
import { FULL_UPLOADS, TYPE_FILE_UPLOAD_CLAIM } from './controller/constants';
import { ClaimOptions } from './controller/types';
import {
  Container,
  ActionUploadContainer,
  ActionUploadItem,
  LoaderContainer,
  WarningText,
  IconContainer,
  ContentContainer,
} from './styles';
import {
  ActionUploadViewProps,
  AnotherUploadOptionsViewProps,
  AnotherUploadViewProps,
  RenderEmailCodeViewProps,
} from './types';

function RenderQrCode({ link }: ClaimOptions) {
  const { language } = useFormContext();
  return (
    <ContentContainer>
      <PlaceholderLabel
        text={language?.getTranslate('ui.media.another_device.qr.label')}
      />
      <WarningText
        text={language?.getTranslate('ui.media.another_device.warning')}
        size={11}
      />
      <div>
        <QRCode value={link || ''} size={134} />
      </div>
    </ContentContainer>
  );
}

function RenderEmailForm({
  onSendEmail,
}: Pick<RenderEmailCodeViewProps, 'onSendEmail'>) {
  const { error, value, onSubmit, onChangeEmail, text } =
    useEmailFormController(onSendEmail);

  return (
    <ContentContainer>
      <PlaceholderLabel text={text.label} />
      <TextInput error={!!error} value={value} onChange={onChangeEmail} />
      <ActionUploadItem onClick={onSubmit}>{text.action}</ActionUploadItem>
    </ContentContainer>
  );
}

function RenderEmailCode({ options, ...props }: RenderEmailCodeViewProps) {
  const { language } = useFormContext();
  if (!options) {
    return <RenderEmailForm {...props} />;
  }
  return (
    <ContentContainer>
      <PlaceholderLabel
        text={language?.getTranslate(
          'ui.media.another_device.link.description'
        )}
      />
      <WarningText
        text={language?.getTranslate('ui.media.another_device.warning')}
        size={11}
      />
    </ContentContainer>
  );
}

function AnotherUploadOptions({
  options,
  onSendEmail,
}: AnotherUploadOptionsViewProps) {
  switch (options.type) {
    case TYPE_FILE_UPLOAD_CLAIM.QR:
      return <RenderQrCode {...options} />;
    case TYPE_FILE_UPLOAD_CLAIM.EMAIL:
      return <RenderEmailCode {...options} onSendEmail={onSendEmail} />;
    default:
      return null;
  }
}

function ActionUpload({
  onChange,
  commentType,
  type,
  label,
  fullComment,
  children,
  typeUploads,
  titleComment,
  watermark,
}: PropsWithChildren<ActionUploadViewProps>) {
  const {
    options,
    loading,
    onClickQr,
    onClickEmail,
    onSendEmail,
    comment,
    actionsText,
  } = useAnotherUploadActionController(
    onChange,
    type,
    typeUploads,
    commentType,
    titleComment,
    fullComment,
    watermark
  );

  const COMPONENTS = useMemo(
    () => ({
      [TYPE_FILE_UPLOAD_CLAIM.QR]: (
        <ActionUploadItem key="qr" onClick={onClickQr}>
          {actionsText.qr}
        </ActionUploadItem>
      ),
      [TYPE_FILE_UPLOAD_CLAIM.EMAIL]: (
        <ActionUploadItem key="email" onClick={onClickEmail}>
          {actionsText.link}
        </ActionUploadItem>
      ),
    }),
    [onClickQr, onClickEmail, actionsText]
  );

  const renderAction = useCallback((key) => COMPONENTS[key], [COMPONENTS]);

  if (loading) {
    return (
      <LoaderContainer>
        <Icon glyph="spinner" />
      </LoaderContainer>
    );
  }

  if (options) {
    return <AnotherUploadOptions options={options} onSendEmail={onSendEmail} />;
  }

  return (
    <>
      {!children && (
        <IconContainer>
          <Icon glyph="monitoring" size={60} color="input-focus-border" />
        </IconContainer>
      )}
      <PlaceholderLabel text={label} />
      {children}
      <PlaceholderDescription text={comment} size={10} />
      <ActionUploadContainer>
        {typeUploads?.map(renderAction)}
      </ActionUploadContainer>
    </>
  );
}

export function AnotherUpload({
  label,
  commentType,
  titleComment,
  fullComment,
  onChange,
  type,
  children,
  typeUploads = FULL_UPLOADS,
  watermark,
}: PropsWithChildren<AnotherUploadViewProps>) {
  return (
    <Container>
      <ActionUpload
        onChange={onChange}
        label={label}
        commentType={commentType}
        titleComment={titleComment}
        fullComment={fullComment}
        type={type}
        typeUploads={typeUploads}
        watermark={watermark}
      >
        {children}
      </ActionUpload>
    </Container>
  );
}

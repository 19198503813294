import { useCallback, useEffect, useState } from 'react';
import { TYPE_FILE_UPLOAD_CLAIM } from './constants';
import { useMemo } from 'react';
import { useUploadService } from 'Services';
import { validateEmail } from './utils';
import { useSubscription } from 'Hooks';
import { ClaimOptions } from './types';
import { useApiContext, useClientContext, useFormContext } from 'Context';
import { FileValue } from 'aid-form-service/lib/State/StateControllers/FieldControllers/MediaFieldController/index.types';
import { AnotherUploadViewProps } from '../types';
import { Watermark } from 'aid-form-service';
import { Maybe } from 'aid-form-service/lib/utils';

export function useFileUploadClaimController(
  onUploadedDocument: (file?: FileValue) => void
) {
  const api = useApiContext();
  const [options, setOptions] = useState<ClaimOptions>();
  const [loading, setLoading] = useState(false);
  const { subscribe, clear } = useSubscription();
  const { getFile } = useUploadService();
  const { formClientId } = useClientContext();
  const { data: fromData } = useFormContext();

  const subscribeUploadedDocument = useCallback(
    (id) => {
      subscribe(async () => {
        const res: any = await api.core.public.file_upload_claims[id]();
        if (res?.data?.data?.uploaded_document_id) {
          const resDoc = await getFile(res?.data?.data?.uploaded_document_id);
          if (resDoc) {
            onUploadedDocument({
              ...resDoc,
              file_size: resDoc.file_size ? Number(resDoc.file_size) : 0,
              loading: false,
            });
          }
          clear();
        }
      });
    },
    [subscribe, clear, onUploadedDocument, getFile]
  );

  const createFileUploadClaim = useCallback(
    async (
      type: ClaimOptions['type'],
      email?: string,
      watermark?: Maybe<Watermark>
    ) => {
      setLoading(true);
      const jsonKind = JSON.stringify({
        type,
        client_id: formClientId,
        language: fromData?.options?.language || 'en',
        watermark,
      });
      const data = {
        file_upload_claim: {
          kind: jsonKind,
          email,
        },
      };
      const res: any = await api.core.public.file_upload_claims.post(
        {},
        {
          data,
        }
      );
      if (res?.data?.success && res?.data?.data) {
        const linkRes: any = await api.core.public.file_upload_claims[
          res.data.data.id
        ].url();
        setOptions({
          type: email
            ? TYPE_FILE_UPLOAD_CLAIM.EMAIL
            : TYPE_FILE_UPLOAD_CLAIM.QR,
          link: linkRes?.data?.data?.url,
          options: res?.data?.data,
        });
        subscribeUploadedDocument(res?.data?.data?.id);
      }
      setLoading(false);
    },
    [subscribeUploadedDocument, formClientId, fromData?.options?.language]
  );

  const deleteFileUploadClaim = useCallback(() => {
    if (options?.options?.id) {
      api.core.public.file_upload_claims[options.options.id].delete();
    }
  }, [options?.options?.id]);

  useEffect(() => {
    window.addEventListener('beforeunload', deleteFileUploadClaim);

    return () => {
      window.removeEventListener('beforeunload', deleteFileUploadClaim);
      deleteFileUploadClaim();
    };
  }, [deleteFileUploadClaim]);

  return { loading, createFileUploadClaim, options, setOptions };
}

export function useAnotherUploadActionController(
  onChange: ((file?: FileValue) => void) | undefined,
  type: string,
  typeUploads: string[],
  commentType?: 'image' | 'video' | 'file',
  titleComment?: string,
  fullComment?: string,
  watermark?: AnotherUploadViewProps['watermark']
) {
  const { language } = useFormContext();
  const onUploadedDocument = useCallback(
    (data?: FileValue) => onChange?.(data),
    [onChange]
  );
  const { loading, createFileUploadClaim, options, setOptions } =
    useFileUploadClaimController(onUploadedDocument);

  const onClickQr = useCallback(
    () => createFileUploadClaim(type, undefined, watermark),
    [createFileUploadClaim, type, watermark]
  );

  const onClickEmail = useCallback(
    () => setOptions({ type: TYPE_FILE_UPLOAD_CLAIM.EMAIL }),
    [setOptions]
  );

  const onSendEmail = useCallback(
    (email: string) => createFileUploadClaim(type, email, watermark),
    [createFileUploadClaim, type, watermark]
  );

  const isAnotherQr = useMemo(
    () =>
      typeUploads?.find((type: string) => type === TYPE_FILE_UPLOAD_CLAIM.QR),
    [typeUploads]
  );

  const isAnotherEmail = useMemo(
    () =>
      typeUploads?.find(
        (type: string) => type === TYPE_FILE_UPLOAD_CLAIM.EMAIL
      ),
    [typeUploads]
  );

  const commentTypeUpload = useMemo(() => {
    if (isAnotherQr && isAnotherEmail) {
      return 'qr_link';
    } else if (isAnotherQr) {
      return 'qr';
    } else if (isAnotherEmail) {
      return 'link';
    }
  }, [isAnotherQr, isAnotherEmail]);

  const comment = useMemo(() => {
    if (fullComment) {
      return fullComment;
    }
    if (titleComment && commentTypeUpload) {
      return `${titleComment}. ${language?.getTranslate(
        'ui.media.only_mobile.instruction',
        { arg: commentTypeUpload }
      )}`;
    }
    if (commentType && commentTypeUpload) {
      return language?.getTranslate('ui.media.another_device.description', {
        type: commentType || 'image',
        another_type: commentTypeUpload,
      });
    }
  }, [commentTypeUpload, commentType, titleComment, fullComment, language]);

  const actionsText = useMemo(
    () => ({
      qr: language?.getTranslate('ui.media.only_mobile.artions.qr'),
      link: language?.getTranslate('ui.media.only_mobile.artions.link'),
    }),
    [language]
  );

  return {
    options,
    loading,
    onClickQr,
    onClickEmail,
    onSendEmail,
    comment,
    actionsText,
  };
}

export function useEmailFormController(onSendEmail: (value: string) => void) {
  const [value, setValue] = useState<string>();
  const [error, setError] = useState<{ message: string }>();
  const { language } = useFormContext();

  const onSubmit = useCallback(() => {
    if (value && !error) {
      onSendEmail(value);
    }
  }, [value, error, onSendEmail]);

  const onChangeEmail = useCallback((value?: string) => {
    const error = validateEmail(value);
    setError(error);
    setValue(value);
  }, []);

  const text = useMemo(
    () => ({
      label: language?.getTranslate('ui.media.another_device.link.label'),
      action: language?.getTranslate('ui.media.another_device.link.action'),
    }),
    [language]
  );

  return { error, value, onSubmit, onChangeEmail, text };
}

import { useApiContext } from 'Context';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IRenderConfirmFieldComponent } from '../types';

export function useSendButtonController(
  phoneValue: IRenderConfirmFieldComponent['value'],
  type: IRenderConfirmFieldComponent['type']
) {
  const api = useApiContext();
  const [loading, setLoading] = useState(false);
  const [tick, setTick] = useState<number>();
  const timeOutRef = useRef<NodeJS.Timeout>();

  const startTimer = useCallback(() => {
    let finalTime = 60;
    setTick(60);
    setLoading(false);
    timeOutRef.current = setInterval(() => {
      let nextTime = finalTime - 1;
      if (nextTime === 0 && timeOutRef.current) {
        clearInterval(timeOutRef.current);
        setTick(0);
        return (timeOutRef.current = undefined);
      }
      setTick(nextTime);
      finalTime = nextTime;
    }, 1000);
  }, []);

  useEffect(() => {
    return () => {
      if (timeOutRef.current) {
        clearInterval(timeOutRef.current);
      }
    };
  }, []);

  const sendCode = useCallback(async () => {
    setLoading(true);
    try {
      const res: any = await api.core.public.validations.send_code.post(
        {},
        {
          data: {
            channel: type,
            to: phoneValue,
          },
        }
      );
      if (res?.data?.success) {
        return startTimer();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [phoneValue, startTimer, type]);

  const disabled = useMemo(() => !phoneValue || !!tick, [phoneValue, tick]);

  const label = useMemo(
    () =>
      typeof tick === 'undefined'
        ? 'Send'
        : tick
        ? `Resend (${tick})`
        : 'Resend',
    [tick]
  );

  return { disabled, label, sendCode, loading };
}

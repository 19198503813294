import {
  TYPE_FILE,
  TYPE_FILE_UPLOAD_CLAIM,
} from '../../../AnotherUpload/controller/constants';
import { useDocumentController } from './controller';
import { PropsWithChildren } from 'react';
import { Drop } from '../Drop';
import { Default } from '../Default';
import { Container } from './styles';
import { DropFieldAction, DropFieldActions } from '../../styles';
import { AnotherUpload } from '../../../AnotherUpload';
import { Icon } from '../../../Icon';
import { InputDecorator } from '../../../InputDecorator';
import {
  DocumentFieldViewProps,
  DocumentFieldWithDecoratorViewProps,
  FormDocumentFieldViewProps,
  RenderDocumentViewProps,
  RenderValueViewProps,
  UBTFormDocumentFieldViewProps,
  ValueViewProps,
} from './types';
import { FieldContainer } from '../../../FieldContainer';
import { useFormMediaFieldController } from 'Hooks';
import { MediaValue } from '../MediaValue';

function Value({ value, handlerRemove }: ValueViewProps) {
  return (
    <MediaValue type="Document" value={value} handleRemove={handlerRemove} />
  );
}

function RenderValue({ value, handlerRemove }: RenderValueViewProps) {
  return <Value value={value} handlerRemove={handlerRemove} />;
}

function RenderDocument({
  anotherUpload,
  onChangeDoc,
  handlerButtonClick,
  children,
  textOptions,
  watermark,
}: PropsWithChildren<RenderDocumentViewProps>) {
  return anotherUpload ? (
    <AnotherUpload
      label={textOptions.actionLabel}
      onChange={onChangeDoc}
      type={TYPE_FILE.DOC}
      typeUploads={[anotherUpload]}
      commentType="file"
      watermark={watermark}
    />
  ) : (
    <Default
      handlerButtonClick={handlerButtonClick}
      textOptions={textOptions}
      liveOnly={false}
    >
      {children}
    </Default>
  );
}

export function DocumentField({
  onChange,
  value,
  typeUploads,
  error,
  children,
  accept,
  watermark,
}: PropsWithChildren<DocumentFieldViewProps>) {
  const {
    anotherUpload,
    toggleAnotherUpload,
    dropFieldProps,
    inputFile,
    handleInputChange,
    handlerButtonClick,
    handlerRemove,
    isAnotherQR,
    isAnotherEmail,
    inputProps,
    textOptions,
    unionAccept,
  } = useDocumentController(value, onChange, typeUploads, accept);

  return (
    <Container>
      {!value && (
        <Drop
          {...dropFieldProps}
          rejected={dropFieldProps.rejected}
          errorType="file"
          error={!!error}
          errorAccept={unionAccept}
          liveOnly={false}
        >
          <input
            {...inputProps}
            ref={inputFile}
            type="file"
            onChange={handleInputChange}
            style={{ display: 'none' }}
          />
          <RenderDocument
            anotherUpload={anotherUpload}
            textOptions={textOptions}
            onChangeDoc={onChange}
            handlerButtonClick={handlerButtonClick}
            watermark={watermark}
          >
            {children}
          </RenderDocument>
          <DropFieldActions>
            <div></div>
            <div>
              {anotherUpload && (
                <DropFieldAction onClick={toggleAnotherUpload()}>
                  <Icon glyph="cross" />
                </DropFieldAction>
              )}
              {!anotherUpload && isAnotherEmail && (
                <DropFieldAction
                  onClick={toggleAnotherUpload(TYPE_FILE_UPLOAD_CLAIM.EMAIL)}
                >
                  <Icon glyph="mail" />
                </DropFieldAction>
              )}
              {!anotherUpload && isAnotherQR && (
                <DropFieldAction
                  onClick={toggleAnotherUpload(TYPE_FILE_UPLOAD_CLAIM.QR)}
                >
                  <Icon glyph="devices" />
                </DropFieldAction>
              )}
            </div>
          </DropFieldActions>
        </Drop>
      )}
      {value && <RenderValue value={value} handlerRemove={handlerRemove} />}
    </Container>
  );
}

export function DocumentFieldWithDecorator({
  label,
  error,
  children,
  ...props
}: PropsWithChildren<DocumentFieldWithDecoratorViewProps>) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <DocumentField {...props} error={!!error}>
        {children}
      </DocumentField>
    </InputDecorator>
  );
}

export function UBTFormDocumentField({
  field,
  value,
  error,
  isVisible,
}: UBTFormDocumentFieldViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <DocumentFieldWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        watermark={field.options?.watermark}
        loading={!!value?.loading}
        typeUploads={
          typeof (field.options as any)?.anotherUpload === 'undefined'
            ? [TYPE_FILE_UPLOAD_CLAIM.QR]
            : (field.options as any)?.anotherUpload
        }
      />
    </FieldContainer>
  );
}

export function FormDocumentField({ field }: FormDocumentFieldViewProps) {
  const { ref, value, onChange, error, label, loading, watermark } =
    useFormMediaFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <DocumentFieldWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        loading={loading}
        watermark={watermark}
        typeUploads={
          typeof (field.options as any)?.anotherUpload === 'undefined'
            ? [TYPE_FILE_UPLOAD_CLAIM.QR]
            : (field.options as any)?.anotherUpload
        }
      />
    </FieldContainer>
  );
}

import { Ref, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Camera } from 'src/UI/components/Camera';
import { TextOptions } from 'src/UI/components/MediaFields/types';
import { ERRORS_TYPES } from './constants';
import { WebCamImperativeRef } from './types';

export function useWebCamFieldController(ref: Ref<WebCamImperativeRef>) {
  const camRef = useRef<Camera>(null);

  useImperativeHandle(ref, () => ({
    getScreenshot() {
      return camRef?.current?.screenshot();
    },
    stopRecord() {
      return camRef?.current?.stopRecord();
    },
  }));

  return { camRef };
}

export function useWebCamController() {
  const [error, setError] = useState(false);
  const [mediaError, setMediaError] = useState<keyof TextOptions>();

  const onUserMediaError = useCallback((err) => {
    if (err) {
      if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
        setMediaError(ERRORS_TYPES.LENGTH as keyof TextOptions);
      } else if (
        err.name === 'NotReadableError' ||
        err.name === 'TrackStartError'
      ) {
        setMediaError(ERRORS_TYPES.USED as keyof TextOptions);
      } else if (
        err.name === 'OverconstrainedError' ||
        err.name === 'ConstraintNotSatisfiedError'
      ) {
        setMediaError(ERRORS_TYPES.SIZE as keyof TextOptions);
      } else if (
        err.name === 'NotAllowedError' ||
        err.name === 'PermissionDeniedError'
      ) {
        setMediaError(ERRORS_TYPES.PERMISSION as keyof TextOptions);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  }, []);

  const updatePermission = useCallback(() => {
    setMediaError((prev) => {
      if (prev === ERRORS_TYPES.PERMISSION) {
        return undefined;
      }
      return prev;
    });
  }, []);

  return { mediaError, onUserMediaError, error, updatePermission };
}

import { useCallback, useState, useMemo } from 'react';
import moment from 'moment';
import { YearsViewProps } from '../types';
import { ViewModes } from '../../../types';
import { STEP_SIZE } from './constants';
import { useFormContext } from 'Context';

export function useYearsController(
  date: YearsViewProps['date'],
  changeYear: YearsViewProps['changeYear'],
  setViewMode: YearsViewProps['setViewMode'],
  minDate: YearsViewProps['minDate'],
  maxDate: YearsViewProps['maxDate']
) {
  const [step, setStep] = useState(0);
  const { language } = useFormContext();

  const yearsByStep = useMemo(() => {
    const currentYear = moment().year();
    const selectedYear = moment(date).get('year');
    const firstYearOnStep = currentYear + step * STEP_SIZE;

    return Array.from({ length: STEP_SIZE }, (_, index) => {
      const year = firstYearOnStep + index;
      return {
        year: firstYearOnStep + index,
        isSelected: year === selectedYear,
        isDisabled:
          year < moment(minDate).year() || year > moment(maxDate).year(),
      };
    });
  }, [step, minDate, maxDate, date]);

  const prevButtonIsDisabled = useMemo(
    () => yearsByStep[0].year <= moment(minDate).year(),
    [yearsByStep, minDate]
  );

  const nextButtonIsDisabled = useMemo(
    () => yearsByStep[yearsByStep.length - 1].year >= moment(maxDate).year(),
    [yearsByStep, maxDate]
  );

  const handlerPrevStep = useCallback(() => setStep(step - 1), [setStep, step]);

  const handlerNextStep = useCallback(() => setStep(step + 1), [setStep, step]);

  const handleChangeYear = useCallback(
    (year: number) => {
      return () => {
        changeYear(year);
        setViewMode(ViewModes.DEFAULT);
      };
    },
    [changeYear, setViewMode]
  );

  const label = useMemo(
    () => language?.getTranslate('ui.date.choose', { arg: 'year' }),
    [language]
  );

  return {
    yearsByStep,
    handlerNextStep,
    handlerPrevStep,
    prevButtonIsDisabled,
    nextButtonIsDisabled,
    handleChangeYear,
    label,
  };
}

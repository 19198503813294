import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import 'recordrtc';
import 'videojs-record/dist/videojs.record.js';
import 'fe-auth-service';
import 'react-toastify/dist/ReactToastify.min.css';

const aRegEx = new RegExp(process.env.REACT_APP_AID_URL || '');
const cRegEx = new RegExp(process.env.REACT_APP_AID_URL || '');

if (typeof process.env.REACT_APP_SENTRY_CURRENT_ENV === 'string') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    environment: process.env.REACT_APP_SENTRY_CURRENT_ENV,
    tracesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb) {
      if (
        breadcrumb.data?.url &&
        (breadcrumb.data.url.test(aRegEx) || breadcrumb.data.url.test(cRegEx))
      ) {
        // Don't send user's email address
        return breadcrumb;
      }
      return null;
    },
  });
}

FEAuthService.init({
  url: process.env.REACT_APP_AUTHORIZATION_HOST || '',
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

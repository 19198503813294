import { forwardRef, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { Text } from '../../../../../Text';
import { useWebCamController, useWebCamFieldController } from './controller';
import { ERRORS_TYPES } from './controller/constants';
import { AnotherUpload } from '../../../../../AnotherUpload';
import { ActionUploadItem } from '../../../../../AnotherUpload/styles';
import { Camera } from '../../../../../Camera';
import {
  FieldViewProps,
  PortalViewProps,
  RenderCameraViewProps,
  RenderPermissionActionViewProps,
  RenderWebCamViewProps,
  WebCamAnotherUploadViewProps,
} from './types';
import { WebCamImperativeRef } from './controller/types';

function WebCamAnotherUpload({
  label,
  onChange,
  children,
  fullComment,
  typeUploads,
  anotherType,
  anotherCommentType,
}: PropsWithChildren<WebCamAnotherUploadViewProps>) {
  return (
    <AnotherUpload
      label={label}
      onChange={onChange}
      type={anotherType}
      commentType={anotherCommentType}
      fullComment={fullComment}
      typeUploads={typeUploads}
    >
      {children}
    </AnotherUpload>
  );
}

function Portal({ children, actionRef }: PropsWithChildren<PortalViewProps>) {
  if (actionRef.current) {
    return createPortal(children, actionRef.current);
  }
  return <>{children}</>;
}

function RenderWebCamView({
  camRef,
  onUserMediaError,
  actionButton,
  actionRef,
  cameraType,
  onComplete,
}: RenderCameraViewProps) {
  return (
    <>
      <Camera
        ref={camRef}
        isOnInitially={cameraType === 0}
        onError={onUserMediaError}
        onComplete={onComplete}
        type={cameraType}
      />
      <Portal actionRef={actionRef}>{actionButton}</Portal>
    </>
  );
}

function RenderWebCam({
  camRef,
  onChange,
  onComplete,
  typeUploads,
  actionButton,
  actionRef,
  cameraType,
  textOptions,
  anotherType,
  anotherCommentType,
}: RenderWebCamViewProps) {
  const { error, onUserMediaError, mediaError, updatePermission } =
    useWebCamController();

  if (mediaError) {
    if (mediaError === ERRORS_TYPES.PERMISSION) {
      return (
        <WebCamAnotherUpload
          label={textOptions[mediaError]}
          onChange={onChange}
          fullComment={textOptions.errorAnotherComment}
          typeUploads={typeUploads}
          anotherType={anotherType}
          anotherCommentType={anotherCommentType}
        >
          <RenderPermissionAction
            onClick={updatePermission}
            textOptions={textOptions}
          />
        </WebCamAnotherUpload>
      );
    }
    return (
      <WebCamAnotherUpload
        label={mediaError}
        onChange={onChange}
        typeUploads={typeUploads}
        anotherType={anotherType}
        anotherCommentType={anotherCommentType}
      />
    );
  }

  if (error) {
    return (
      <WebCamAnotherUpload
        label="Something went wrong"
        onChange={onChange}
        typeUploads={typeUploads}
        anotherType={anotherType}
        anotherCommentType={anotherCommentType}
      />
    );
  }

  return (
    <RenderWebCamView
      camRef={camRef}
      cameraType={cameraType}
      onUserMediaError={onUserMediaError}
      actionButton={actionButton}
      actionRef={actionRef}
      onComplete={onComplete}
    />
  );
}

function RenderPermissionAction({
  onClick,
  textOptions,
}: RenderPermissionActionViewProps) {
  return (
    <>
      <Text size={10} text={textOptions.errorPermissionsComment} />
      <div>
        <ActionUploadItem onClick={onClick}>
          {textOptions.errorPermissionAction}
        </ActionUploadItem>
      </div>
    </>
  );
}

export const Field = forwardRef<WebCamImperativeRef, FieldViewProps>(
  (
    {
      onChange,
      onComplete,
      typeUploads,
      actionButton,
      actionRef,
      cameraType,
      anotherType,
      textOptions,
      anotherCommentType,
    },
    ref
  ) => {
    const { camRef } = useWebCamFieldController(ref);

    return (
      <RenderWebCam
        camRef={camRef}
        onChange={onChange}
        onComplete={onComplete}
        typeUploads={typeUploads}
        actionButton={actionButton}
        actionRef={actionRef}
        anotherType={anotherType}
        cameraType={cameraType}
        anotherCommentType={anotherCommentType}
        textOptions={textOptions}
      />
    );
  }
);

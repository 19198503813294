import { DescriptionSize } from 'aid-form-service';

export const RedirectButtonStyles = {
  [DescriptionSize.SMALL]: {
    fontSize: 12,
    height: 30,
  },
  [DescriptionSize.MIDDLE]: {
    fontSize: 14,
    height: 40,
  },
  [DescriptionSize.BIGGER]: {
    fontSize: 20,
    height: 50,
  },
};

import { useCallback, useRef, useState } from 'react';
import { ApiGeonames } from 'Services';

export function useRegionProviderController() {
  const loadedRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<any[]>();

  const loadCountries = useCallback(async () => {
    if (!loadedRef.current) {
      loadedRef.current = true;
      setLoading(true);
      const countries = await ApiGeonames.countryInfo();

      const formattedCountries = countries.geonames.map((item: any) => ({
        label: item.countryName,
        value: item.countryCode,
      }));
      setCountries(formattedCountries);
      setLoading(false);
    }
  }, []);

  const getCountryNameByCode = useCallback(
    (countryCode: string) => {
      return (
        countries?.find(
          (country) => country.value === countryCode.toUpperCase()
        )?.label || ''
      );
    },
    [countries]
  );

  return { countries, loadCountries, loading, getCountryNameByCode };
}

import styled from '@emotion/styled';

export const Svg = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`;

import styled from '@emotion/styled';

export const Mask = styled.div`
  position: absolute;
  background: #C3C4C9;
  opacity: 0.4;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  mask-image: radial-gradient(30% 65%, transparent 50%, #C3C4C9 50%)
`;

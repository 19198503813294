import { CustomUI } from 'aid-form-service/lib/ApiClient/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { ThemeType, THEME_FONTS } from 'UIShared';
import {
  ARIVAL_FONTS,
  BFM_FONTS,
  EZZ_BANKING_FONTS,
  STANDARD_FONTS,
  SYSTEM_FONTS,
} from './constants';

function generateCustomTheme(
  settings: CustomUI['settings'],
  currentFonts: THEME_FONTS
) {
  return {
    primary: settings?.font || currentFonts.primary,
    secondary: currentFonts.secondary,
  };
}

function generateCurrentFonts(type: ThemeType) {
  switch (type) {
    case ThemeType.BFM:
    case ThemeType.ONBOARDING:
      return BFM_FONTS;
    case ThemeType.ARIVAL:
      return ARIVAL_FONTS;
    case ThemeType.EZ:
      return EZZ_BANKING_FONTS;
    case ThemeType.SYSTEM:
      return SYSTEM_FONTS;
    default:
      return STANDARD_FONTS;
  }
}

export function generateFonts(type: ThemeType, customUI: Maybe<CustomUI>) {
  const currentFonts = generateCurrentFonts(type);
  if (customUI?.enabled) {
    return generateCustomTheme(customUI?.settings, currentFonts);
  }
  return currentFonts;
}

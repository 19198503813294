import { useState, useEffect, useMemo, useCallback } from 'react';
import { Select } from '../Select';
import { SelectsWrapper } from './styles';
import dayjs from 'dayjs';

import { InputDecorator } from '../InputDecorator';
import {
  DateSelectsViewProps,
  DateSelectsWithDecoratorViewProps,
  FormDateSelectViewProps,
  UBTFormDateSelectViewProps,
} from './types';
import { useFormFieldController } from 'Hooks';
import { FieldContainer } from '../FieldContainer';
import moment from 'moment';
import { useFormContext } from 'Context';

export function DateSelects({
  value,
  options,
  halfWidth,
  onChange,
  error,
}: DateSelectsViewProps) {
  let [month, setMonth] = useState<string>();
  let [day, setDay] = useState<string>();
  let [year, setYear] = useState<string>();
  const { language } = useFormContext();

  const monthsOptions = useMemo(
    () =>
      moment.months().map((month, index) => ({
        label: month,
        value: `${index + 1}`,
      })),
    []
  );

  useEffect(() => {
    if (year && month && day) {
      const value = dayjs(`${year}/${month}/${day}`).format('YYYY-MM-DD');
      onChange?.(value);
    } else {
      if (value) {
        let date = dayjs(value);
        if (date.isValid()) {
          setYear(date.year().toString());
          setMonth((date.month() + 1).toString());
          setDay(date.date().toString());
        } else {
          onChange?.();
        }
      }
    }
  }, [month, day, year, value, onChange]);

  const endYear = useMemo(() => {
    return options?.dateAsSelect?.endYear
      ? Number(options?.dateAsSelect?.endYear)
      : dayjs().year();
  }, [options?.dateAsSelect?.endYear]);

  const range = useMemo(() => {
    return options?.dateAsSelect?.range || 200;
  }, [options?.dateAsSelect?.range]);

  const days = useMemo(() => {
    return Array.from(Array(31), (x, index) => index + 1).map((day) => ({
      value: day.toString(),
      label: day.toString(),
    }));
  }, []);

  const years = useMemo(() => {
    return Array.from(Array(Number(range)), (x, index) => endYear - index).map(
      (year) => ({
        value: year.toString(),
        label: year.toString(),
      })
    );
  }, [range, endYear]);

  const onChangeState = useCallback(
    (callback: (value?: string) => void) =>
      (value?: string | string[] | undefined) => {
        if (!value || typeof value === 'string') {
          callback(value);
        }
      },
    []
  );

  return (
    <SelectsWrapper halfWidth={halfWidth}>
      <Select
        placeholder={language?.getTranslate('ui.placeholders.date.month')}
        onChange={onChangeState(setMonth)}
        value={month}
        data={monthsOptions}
        halfWidth={halfWidth}
        error={error}
        options={options}
      />
      <Select
        placeholder={language?.getTranslate('ui.placeholders.date.day')}
        onChange={onChangeState(setDay)}
        value={day}
        data={days}
        halfWidth={halfWidth}
        error={error}
        options={options}
      />
      <Select
        placeholder={language?.getTranslate('ui.placeholders.date.year')}
        onChange={onChangeState(setYear)}
        value={year}
        data={years}
        halfWidth={halfWidth}
        error={error}
        options={options}
      />
    </SelectsWrapper>
  );
}

export function DateSelectsWithDecorator({
  label,
  error,
  ...props
}: DateSelectsWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props} requiredInHeader>
      <DateSelects error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormDateSelects({
  value,
  isVisible,
  field,
  error,
}: UBTFormDateSelectViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <DateSelectsWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
      />
    </FieldContainer>
  );
}

export function FormDateSelects({ field }: FormDateSelectViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <DateSelectsWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
      />
    </FieldContainer>
  );
}

import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { ViewModes } from '../../../types';
import { MonthViewProps } from '../types';
import { useFormContext } from 'Context';

export function useMonthsController(
  changeMonth: MonthViewProps['changeMonth'],
  setViewMode: MonthViewProps['setViewMode'],
  date: MonthViewProps['date'],
  minDate: MonthViewProps['minDate'],
  maxDate: MonthViewProps['maxDate']
) {
  const { language } = useFormContext();
  const months = useMemo(
    () =>
      moment.monthsShort().map((month, index) => {
        const selectedMonth = moment(date).month();
        const selectedYear = moment(date).year();
        return {
          month: month,
          isSelected: selectedMonth === index,
          isDisabled:
            (selectedYear === moment(minDate).year() &&
              moment(minDate).month() > index) ||
            (selectedYear === moment(maxDate).year() &&
              moment(maxDate).month() < index),
        };
      }),
    [date, minDate, maxDate]
  );

  const handleChangeMonth = useCallback(
    (index) => {
      return () => {
        changeMonth(index);
        setViewMode(ViewModes.DEFAULT);
      };
    },
    [changeMonth, setViewMode]
  );

  const label = useMemo(
    () => language?.getTranslate('ui.date.choose', { arg: 'month' }),
    [language]
  );

  return {
    months,
    handleChangeMonth,
    label,
  };
}

import { RegionProvider, FingerprintProvider } from "Context";
import { UISpriteProvider } from "UIShared";
import { Routes } from "./routes";
import { ConfirmModalProvider } from "./UI/components/ConfirmModal";
function App() {
  return (
    <>
      <UISpriteProvider>
        <RegionProvider>
          <FingerprintProvider>
            <ConfirmModalProvider>
              <Routes />
            </ConfirmModalProvider>
          </FingerprintProvider>
        </RegionProvider>
      </UISpriteProvider>
      <div id="places" />
    </>
  );
}

export default App;

import { FieldControllerDelegate } from 'aid-form-service/lib/State/StateControllers/FieldControllers/FieldController/index.delegate';
import { ReactChildren } from 'react';
import { InputDecoratorViewProps } from '../InputDecorator/types';
import { Field } from 'aid-form-service';
import { Maybe } from 'aid-form-service/lib/utils';
import { Error } from 'aid-form-service/lib/State/StateControllers/index.types';

export enum TypeConfirmedField {
  PHONE = 'sms',
  EMAIL = 'email',
}

export type IValue = string | undefined;

export interface IConfirmedValue {
  confirmed?: string[];
  value?: string;
}

export interface IFieldStyleProps {
  error: boolean;
}

export interface IInputProps extends IFieldStyleProps {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

interface IConfirmedInputAnyProps extends IFieldStyleProps {
  children?: ReactChildren;
  name?: string;
  type?: TypeConfirmedField;
}

export interface IConfirmedInputWithConfirmProps
  extends IConfirmedInputAnyProps {
  confirm: true;
  value?: IConfirmedValue;
  placeholder?: string;
  onChange?: (value: IConfirmedValue) => void;
  field?: FieldControllerDelegate;
}

export interface IConfirmedInputProps extends IConfirmedInputAnyProps {
  confirm: false;
  placeholder?: string;
  value?: IValue;
  onChange?: (value: IValue) => void;
  field?: FieldControllerDelegate;
}

export interface IRenderConfirmFieldComponent
  extends Pick<IConfirmedInputProps, 'value' | 'type'> {
  onChange?: (value: string) => void;
  field?: FieldControllerDelegate;
}

export type IConfirmedInputUnionType =
  | IConfirmedInputProps
  | IConfirmedInputWithConfirmProps;

export type IConfirmedInputWithDecoratorComponent = InputDecoratorViewProps &
  Omit<IConfirmedInputUnionType, 'error'>;

export interface UBTFormConfirmInputViewProps {
  value?: IValue;
  field: Field;
  isVisible: boolean;
  error: Maybe<Error>;
  type: TypeConfirmedField;
}

export interface FormConfirmInputViewProps {
  field: FieldControllerDelegate;
  type: TypeConfirmedField;
}

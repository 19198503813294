import { InputDecorator } from '../InputDecorator';
import { Select } from '../Select';
import { useRegionContext } from 'Context';
import { SelectWithDecoratorViewProps, SelectViewProps } from '../Select/types';
import { FormRegionsFieldViewProps, UBTFormRegionsFieldViewProps } from './types';
import { useFormFieldController } from 'Hooks';
import { FieldContainer } from '../FieldContainer';

export function RegionsField({ ...props }: Omit<SelectViewProps, 'data'>) {
  const { countries, loading } = useRegionContext();

  return (
    <Select {...props} isLoading={loading} searchable={true} data={countries} />
  );
}

export function RegionsFieldWithDecorator({
  label,
  error,
  ...props
}: Omit<SelectWithDecoratorViewProps, 'data'>) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <RegionsField error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormRegionsField({
  value,
  isVisible,
  field,
  error,
  multi,
}: UBTFormRegionsFieldViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <RegionsFieldWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || undefined}
        multi={multi}
      />
    </FieldContainer>
  );
}

export function FormRegionsField({ field, multi }: FormRegionsFieldViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <RegionsFieldWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        placeholder={field.options?.placeholder || ''}
        multi={multi}
      />
    </FieldContainer>
  );
}

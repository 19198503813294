import styled from '@emotion/styled';
import { getColor, getFont } from 'UIUtils';
import { EmotionDropContainerViewProps } from './types';

export const DropContainer = styled.div<EmotionDropContainerViewProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 280px;
  min-width: 220px;
  max-width: 100%;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ rejected }) =>
    rejected ? getColor('error-text-color') : getColor('input-border')};
  border-radius: 5px;
  margin: 0px auto 15px;
  position: relative;
  overflow: hidden;
  font-family: ${getFont('primary')};
  background-color: ${getColor('white')};
  position: relative;

  ${({ isDragActive }) =>
    isDragActive &&
    `
        background: ${getColor('disabled-input-background')};
        border-color: ${getColor('input-focus-border')};
        border-style: solid;
    `}

  ${({ error }) =>
    error &&
    `
      border-color: ${getColor('error-text-color')};
      box-shadow:  ${getColor('error-text-color')};
    `};

  div[class^='render-actions__ActionsWrapper'] {
    z-index: 10;
  }
  div[class^='timer'] {
    z-index: 10;
  }
  div[class^='countdown'] {
    z-index: 10;
  }

  img,
  svg,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;

    &:focus {
      outline: none;
    }
  }
`;

export const ErrorContainer = styled.div`
  position: absolute;
  z-index: 14;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
`;

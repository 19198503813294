import { DesktopSvg } from './DesktopSvg';
import {
  Container,
  DescriptionContainer,
  TitleContainer,
  FooterText,
} from './styles';
import { LogoSvg } from './LogoSvg';
import { ErrorTemplateViewProps } from './types';
import { PropsWithChildren, useMemo } from 'react';
import { Text } from '../Text';

export function ErrorTemplate({
  title,
  withoutLogo = false,
  color = 'input-focus-border',
  description,
  code,
  children,
  customIcon,
}: PropsWithChildren<ErrorTemplateViewProps>) {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  return (
    <Container>
      {!withoutLogo && <LogoSvg />}
      <DescriptionContainer>
        {customIcon}
        {!customIcon && <DesktopSvg code={code} color={color} />}
        <TitleContainer>
          <Text
            text={title}
            uppercase
            size={32}
            lineHeight="1.5"
            color={color}
            weight="Medium"
            align="center"
          />
          <Text
            text={description}
            size={20}
            weight="Light"
            color="primary-text-color"
          />
        </TitleContainer>
        {children}
      </DescriptionContainer>
      <FooterText
        size={12}
        color="input-focus-border"
        text={`© ${currentYear} A.ID Europe UAB. All rights reserved`}
      />
    </Container>
  );
}

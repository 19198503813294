import styled from '@emotion/styled/macro';
import { isMobile } from 'react-device-detect';
import { getColor } from 'UIUtils';
import { EmotionCameraContainerViewProps } from './types';

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  background-color: ${getColor('disabled-input-background')};

  & > div {
    width: 80px;
    height: 40px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const CameraContainer = styled.div<EmotionCameraContainerViewProps>`
  width: 100%;
  height: 100%;

  & button {
    display: none !important;
  }

  & .video-js {
    width: 100%;
    height: 100%;
    display: block;
    padding-top: 0;
    ${!isMobile ? 'transform: rotateY(180deg);' : ''}

    & > video {
      max-height: 100vh;
      object-fit: cover;
    }

    & > .vjs-record-indicator {
      z-index: 1;
      ${!isMobile
        ? `
          transform: rotateY(-180deg) scale(1.3) !important;
          left: 12px;
      `
        : ''}
    }
  }

  & > ${LoaderContainer} {
    display: ${({ loading }) => (loading ? 'flex' : 'none')};
  }
`;

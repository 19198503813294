import ReactPhoneInput from 'react-phone-input-2';
import { InputDecorator } from '../InputDecorator';
import {
  IConfirmedInputUnionType,
  IConfirmedInputWithDecoratorComponent,
  IConfirmedInputWithConfirmProps,
  IConfirmedInputProps,
  IRenderConfirmFieldComponent,
  TypeConfirmedField,
  IInputProps,
  FormConfirmInputViewProps,
  UBTFormConfirmInputViewProps,
} from './types';
import {
  PhoneInputContainer,
  ConfirmContainer,
  ActionContainer,
  SuccessIcon,
  ConfirmedContainer,
} from './styles';
import { TextInput } from '../TextInput';
import { Button } from '../Button';
import {
  useConfirmFieldController,
  useFieldWithConfirmController,
  useSendButtonController,
} from './controller';
import { Icon } from '../Icon';
import { useCallback } from 'react';
import { ButtonType } from '../Button/types';
import { useFormFieldController } from 'Hooks';
import { FieldContainer } from '../FieldContainer';

function isConfirmField(
  props: IConfirmedInputUnionType
): props is IConfirmedInputWithConfirmProps {
  return props.confirm;
}

function RenderSendButton({
  value,
  type,
}: Pick<IRenderConfirmFieldComponent, 'value' | 'type'>) {
  const { disabled, sendCode, label, loading } = useSendButtonController(
    value,
    type
  );

  return (
    <Button
      buttonType={ButtonType.OUTLINE}
      disabled={loading || disabled}
      onClick={sendCode}
    >
      {loading ? (
        <Icon glyph="spinner" size={14} color="input-border" />
      ) : (
        label
      )}
    </Button>
  );
}

function RenderConfirmField({
  onChange,
  value,
  type,
  field,
}: IRenderConfirmFieldComponent) {
  const {
    onChange: onChangeConfirmField,
    codeValue,
    disabled,
    onConfirmPhone,
    loading,
  } = useConfirmFieldController(value, onChange, type, field);

  return (
    <ConfirmContainer>
      <TextInput
        value={codeValue}
        onChange={onChangeConfirmField}
        error={false}
        mask="999999"
        alwaysShowMask
      />
      <ActionContainer>
        <RenderSendButton value={value} type={type} />
        <Button
          disabled={loading || !value || disabled}
          onClick={onConfirmPhone}
        >
          {loading ? (
            <Icon glyph="spinner" size={14} color="input-outline" />
          ) : (
            `Verify ${type === TypeConfirmedField.PHONE ? 'Phone' : 'Email'}`
          )}
        </Button>
      </ActionContainer>
    </ConfirmContainer>
  );
}

function RenderWithConfirmField({
  value,
  onChange,
  error,
  type,
  field,
  ...props
}: IConfirmedInputWithConfirmProps) {
  const { onChangeInput, onChangeConfirm, isConfirmed, currentValue } =
    useFieldWithConfirmController(value, onChange);
  return (
    <ConfirmedContainer>
      <RenderField
        {...props}
        type={type}
        confirm={false}
        error={error}
        onChange={onChangeInput}
        value={currentValue?.value}
      />
      {!isConfirmed && (
        <RenderConfirmField
          onChange={onChangeConfirm}
          field={field}
          value={currentValue?.value}
          type={type}
        />
      )}
    </ConfirmedContainer>
  );
}

function RenderPhone({ onChange, error, value, placeholder }: IInputProps) {
  const onChangePhone = useCallback(
    (value) => {
      onChange?.(value ? `+${value}` : value);
    },
    [onChange]
  );
  return (
    <PhoneInputContainer error={error}>
      <ReactPhoneInput
        country="us"
        value={value}
        onChange={onChangePhone}
        specialLabel=""
        placeholder={placeholder}
      />
      <SuccessIcon>
        <Icon glyph="checkmark" color="success-text-color" />
      </SuccessIcon>
    </PhoneInputContainer>
  );
}

function RenderEmail({ onChange, error, value, placeholder }: IInputProps) {
  const onChangeEmail = useCallback(
    (value) => {
      onChange?.(value);
    },
    [onChange]
  );
  return (
    <TextInput
      value={value}
      onChange={onChangeEmail}
      error={error}
      placeholder={placeholder}
    />
  );
}

function RenderField({
  value,
  onChange,
  error,
  type,
  placeholder,
}: IConfirmedInputProps) {
  switch (type) {
    case TypeConfirmedField.PHONE:
      return (
        <RenderPhone
          value={value}
          onChange={onChange}
          error={error}
          placeholder={placeholder}
        />
      );
    case TypeConfirmedField.EMAIL:
      return (
        <RenderEmail
          value={value}
          onChange={onChange}
          error={error}
          placeholder={placeholder}
        />
      );
    default:
      return null;
  }
}

export function ConfirmInput(props: IConfirmedInputUnionType) {
  if (isConfirmField(props)) {
    return <RenderWithConfirmField {...props} />;
  }
  return <RenderField {...props} />;
}

export function ConfirmInputWithDecorator({
  label,
  error,
  ...props
}: IConfirmedInputWithDecoratorComponent) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <ConfirmInput {...(props as any)} error={!!error} />
    </InputDecorator>
  );
}

export function UBTFormConfirmInput({
  value,
  isVisible,
  field,
  error,
  type,
}: UBTFormConfirmInputViewProps) {
  return (
    <FieldContainer hidden={!isVisible} halfWidth={!!field.options?.half_width}>
      <ConfirmInputWithDecorator
        label={field.name}
        value={value}
        error={error}
        options={field.options}
        confirm={!!field.options?.must_be_confirmed}
        placeholder={field.options?.placeholder || undefined}
        type={type}
      />
    </FieldContainer>
  );
}

export function FormConfirmInput({ field, type }: FormConfirmInputViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <ConfirmInputWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        options={field.options}
        confirm={!!field.options?.must_be_confirmed}
        field={field}
        placeholder={field.options?.placeholder || undefined}
        type={type}
      />
    </FieldContainer>
  );
}

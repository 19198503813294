import styled from '@emotion/styled'
import { getColor } from 'UIUtils'
import { CustomValueEmotionProps } from './types'

export const ChangeButton = styled.button`
  cursor: pointer;
  color: ${getColor('active-text-color')};
  font-size: 18px;
  border: none;
  background-color: transparent;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  padding: 0;
`

export const ChangeButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
`

export const CustomHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const CustomHeaderText = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: ${getColor('active-text-color')};

  & > span {
    cursor: pointer;
  }
`

export const CustomListWrapper = styled.div`
  box-sizing: border-box;
  box-shadow: 2px 2px 1px #f2f2f2;
  width: calc(100% + 2px);
  border: 1px solid var(--color-secondary-deep-5);
  border-top: none;
  display: grid;
  position: absolute;
  padding: 0 5px 10px;
  left: -1px;
  right: -1px;
  top: 100%;
  height: 219px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  background: ${getColor('white')};
  z-index: 1;
`

export const CustomValue = styled.div<CustomValueEmotionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  cursor: pointer;
  color: ${({ isSelected }) =>
    isSelected ? getColor('white') : getColor('active-text-color')};
  background: ${({ isSelected }) =>
    isSelected ? getColor('active-text-color') : getColor('white')};

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    color: ${getColor('note-text-color')};
    user-select: none;
    pointer-events: none;
  `}
`

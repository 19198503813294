import { ViewerViewProps } from '../types';
import { usePDFViewerModel } from '../model';
import { useCallback, useState } from 'react';

export function usePDFViewerController(
  document_id: ViewerViewProps['document_id']
) {
  const [show, setShow] = useState(false);
  const { document, loading } = usePDFViewerModel(document_id);

  const onToggleShow = useCallback(() => setShow((prev) => !prev), []);

  return { document, loading, show, onToggleShow };
}

import {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';

export enum ViewModes {
  'DEFAULT',
  'MONTHS',
  'YEARS',
}

export interface HeaderViewProps extends ReactDatePickerCustomHeaderProps {
  minDate: ReactDatePickerProps['minDate'];
  maxDate: ReactDatePickerProps['maxDate'];
}

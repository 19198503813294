import styled from '@emotion/styled';
import { getAppImages } from 'UIShared';
import { getAppColors } from '../colors';
import { getAppFonts } from '../fonts';
import { UIThemeProviderRenderViewProps } from './types';

export const ThemeProvider = styled.div<UIThemeProviderRenderViewProps>`
  ${({ type, customUI }) => getAppFonts(type, customUI)}
  ${({ type, customUI }) => getAppColors(type, customUI)}
  ${({ type, customUI, apiHost }) => getAppImages(type, customUI, apiHost)}

  height: inherit;
  width: inherit;
`;

import { StyledToastContainer } from './index.style';

export default function ToastifyContainer({ children }) {
  return (
    <>
      {children}
      <StyledToastContainer />
    </>
  );
}

import styled from '@emotion/styled';
import { getColor } from 'UIUtils';
import { Icon } from '../../../Icon';
import { Text } from '../../../Text';

export const Container = styled.div`
  & > div {
    margin-bottom: 20px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const ValuesContainer = styled.div`
  background: ${getColor('disabled-input-background')};
  border-width: 1px;
  border-color: ${getColor('input-border')};
  border-style: solid;
  border-radius: 4px;
  padding: 12px 18px;
  width: inherit;

  & > div:first-of-type {
    padding-top: 0;
  }

  & > div {
    padding: 12px 0;
    border-bottom: 1px dashed ${getColor('input-border')};
  }

  & > div:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const ValueContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
`;

export const DescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  overflow-wrap: anywhere;
  align-items: center;

  & > span {
    margin-right: 8px;
    text-align: left;
    overflow-wrap: anywhere;
  }

  & > div {
    min-width: 16px;
    margin-right: 8px;
  }
`;

export let StyledText = styled(Text)`
  text-align: center;
  line-height: 20px;
  background: ${getColor('input-focus-background')};
  color: transparent;
  background-clip: text;
  word-break: break-all;
`;

export const ActionContainer = styled.div`
  display: flex;
  min-width: fit-content;
  text-transform: uppercase;
  align-items: center;

  & > span {
    margin-right: 8px;
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${getColor('input-border')};
`;

export const IconButton = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const ErrorIcon = styled(Icon)`
  color: ${getColor('error-text-color')};
`;

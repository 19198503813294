export function cutFileName(fileName: string) {
  if (!fileName) {
    return;
  }
  const utf8EncodeText = new TextEncoder();
  const utf8DecodeText = new TextDecoder('utf-8');

  const maxSize = 235;

  const unit8ArrayName = utf8EncodeText.encode(fileName);

  if (unit8ArrayName.length <= maxSize) {
    return fileName;
  }

  const leftSide = unit8ArrayName.slice(0, maxSize / 2);
  const rightSide = unit8ArrayName.slice(
    unit8ArrayName.length - 1 - maxSize / 2
  );

  const leftSideString = utf8DecodeText.decode(leftSide).replace(/\uFFFD/g, '');
  const rightSideString = utf8DecodeText
    .decode(rightSide)
    .replace(/\uFFFD/g, '');

  return `${leftSideString}...${rightSideString}`;
}
